import React, { useState, useEffect } from 'react';
import JrClasses from './jrClasses';
import { find, insert, update, remove } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';

export default function DataJrClasses() {
    const [jrClasses, setJrClasses] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        find(DataTables.JrClasses(), {}).then((jrClasses = []) => {
            setJrClasses(jrClasses);
            setIsLoaded(true);
        });
    }, []);

    const onAdd = (newSuper) => {
        const save = async () => {
            const jrClass = await insert(DataTables.JrClasses(), newSuper);
            setJrClasses(jrClasses.concat([jrClass]));
        }

        save();
    }

    const onUpdate = (jrClass) => {
        const save = async () => {
            const updatedClass = await update(DataTables.JrClasses(), { _id: jrClass._id }, jrClass);
            let index = jrClasses.findIndex(({ _id }) => _id === updatedClass._id);
            jrClasses[index] = jrClass;
            setJrClasses(jrClasses);
        }

        save();
    }

    const onDelete = ({ _id }) => {
        const removeRecord = async () => {
            await remove(DataTables.JrClasses(), { _id });
            setJrClasses(jrClasses.filter((jrClass) => jrClass._id !== _id));
        }

        removeRecord();
    }

    jrClasses.sort((a, b) => {
        let aName = (a.name || 'zzzzzzzz').toLowerCase();
        let bName = (b.name || 'zzzzzzzz').toLowerCase();

        if (aName < bName) {
            return -1;
        }

        if (aName > bName) {
            return 1;
        }

        return 0;
    });

    return (
        <IsLoading isLoaded={isLoaded}>
            <JrClasses jrClasses={jrClasses} onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} />
        </IsLoading>
    )
}