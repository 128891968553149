import React, { useState, useEffect } from 'react';
import ObedienceClasses from './obedienceClasses';
import { find, insert, update, remove } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';

export default function DataObedienceClasses() {
    const [obedienceClasses, setObedienceClasses] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        find(DataTables.ObedienceClasses(), {}).then((obedienceClasses = []) => {
            setObedienceClasses(obedienceClasses);
            setIsLoaded(true);
        });
    }, []);

    const onAdd = (newSuper) => {
        const save = async () => {
            const obedienceClass = await insert(DataTables.ObedienceClasses(), newSuper);
            setObedienceClasses(obedienceClasses.concat([obedienceClass]));
        }

        save();
    }

    const onUpdate = (obedienceClass) => {
        const save = async () => {
            const updatedClass = await update(DataTables.ObedienceClasses(), { _id: obedienceClass._id }, obedienceClass);
            let index = obedienceClasses.findIndex(({ _id }) => _id === updatedClass._id);
            obedienceClasses[index] = obedienceClass;
            setObedienceClasses(obedienceClasses);
        }

        save();
    }

    const onDelete = ({ _id }) => {
        const removeRecord = async () => {
            await remove(DataTables.ObedienceClasses(), { _id });
            setObedienceClasses(obedienceClasses.filter((obedienceClass) => obedienceClass._id !== _id));
        }

        removeRecord();
    }

    obedienceClasses.sort((a, b) => {
        let aName = (a.name || 'zzzzzzzz').toLowerCase();
        let bName = (b.name || 'zzzzzzzz').toLowerCase();

        if (aName < bName) {
            return -1;
        }

        if (aName > bName) {
            return 1;
        }

        return 0;
    });

    return (
        <IsLoading isLoaded={isLoaded}>
            <ObedienceClasses obedienceClasses={obedienceClasses} onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} />
        </IsLoading>
    )
}