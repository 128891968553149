import React from 'react';
import PropTypes from 'prop-types';
import FormTable from '../../components/common/FormTable';

const schemas = [{
  sortOrder: 0,
  columnName: 'Name',
  propName: 'name',
  type: 'text',
  isAddOk: true,
  style: { width: '20%' }
}, {
  sortOrder: 1,
  columnName: 'Email',
  propName: 'email',
  type: 'text',
  isAddOk: true,
  style: { width: '25%' }
}, {
  sortOrder: 2,
  columnName: 'Phone',
  propName: 'phone',
  type: 'text',
  isAddOk: true,
  style: { width: '8%' }
}, {
  sortOrder: 3,
  columnName: 'Address 1',
  propName: 'address1',
  type: 'text',
  isAddOk: true,
  style: { width: '11%' }
}, {
  sortOrder: 4,
  columnName: 'Address 2',
  propName: 'address2',
  type: 'text',
  isAddOk: true,
  style: { width: '8%' }
}, {
  sortOrder: 5,
  columnName: 'City',
  propName: 'city',
  type: 'text',
  isAddOk: true,
  style: { width: '8%' }
}, {
  sortOrder: 6,
  columnName: 'State',
  propName: 'state',
  type: 'text',
  isAddOk: true,
  style: { width: '5%' }
}, {
  sortOrder: 7,
  columnName: 'Zip',
  propName: 'zip',
  type: 'text',
  isAddOk: true,
  style: { width: '5%' }
}, {
  sortOrder: 8,
  columnName: 'isArchived',
  propName: 'isArchived',
  type: 'checkbox',
  isAddOk: false,
  style: { width: '10%' }
}];

export default function Superintendents({ superintendents, onAdd, onUpdate, onDelete }) {
  const defaultSuperintendent = {
    name: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: ''
  };

  const containerStyle = {
    minWidth: '1300px',
    maxWidth: '1800px',
    margin: 'auto'
  };

  return (
    <FormTable keyPropName='_id'
      tableTitle='Superintendents'
      defaultValueObj={defaultSuperintendent}
      values={superintendents}
      schemas={schemas}
      onAdd={onAdd}
      onUpdate={onUpdate}
      onDelete={onDelete}
      containerStyle={containerStyle}
      maxDisplayCount={100} />
  );
}

Superintendents.propTpes = {
  superintendents: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}