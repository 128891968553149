import React from 'react';
import PropTypes from 'prop-types';
import ClubEntryReportCheckBox from './clubEntryReportCheckBox';

export default function ClubEntryReportPlaceOfBirth({ isUsa, isCanada, isForeign }) {
  const checkboxLabelStyle = { fontSize: '8px', marginLeft: '4px', marginRight: '12px' };

  return (
    <div style={{ borderLeft: 'solid 1px black' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '2px' }}>
        <span style={{ fontSize: '7px' }}>PLACE OF BIRTH</span>
        <span style={{ fontSize: '12px' }}></span>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', height: '22px', padding: '0 2px' }}>
        <ClubEntryReportCheckBox label='USA' isChecked={isUsa} labelStyle={checkboxLabelStyle} />
        <ClubEntryReportCheckBox label='CANADA' isChecked={isCanada} labelStyle={checkboxLabelStyle} />
        <ClubEntryReportCheckBox label='FOREIGN' isChecked={isForeign} labelStyle={checkboxLabelStyle} />
      </div>
    </div>
  );
}

ClubEntryReportPlaceOfBirth.propTypes = {
  isUsa: PropTypes.bool,
  isCanada: PropTypes.bool,
  isForeign: PropTypes.bool
}