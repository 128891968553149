import React from 'react';

export default function CheckBoxField({onChange, onBlur, fieldName, fieldProperty, defaultValue=false, style}) {
    return (
        <div className='container input-container checkbox-container'>
            <input 
                style={style} 
                id={fieldProperty} 
                type='checkbox' 
                checked={defaultValue} 
                onChange={(e) => onChange && onChange(fieldProperty, e.target.checked) }
                onBlur={() => onBlur && onBlur()} />
            <label htmlFor={fieldProperty}>{fieldName}</label>
        </div>
    )
}