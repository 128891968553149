import React from 'react';
import { Redirect } from 'react-router';

export default function NotLoggedIn({currentUser}) {
    if (currentUser) {
        return (<Redirect to="/" />);
    }

    return (
        <div>
            <h2>Please login to DogShowHelper</h2>
            <h3>Click the login link in the upper right hand corner.</h3>
        </div>
    )
}