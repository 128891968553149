import React from 'react';
import FormTitleContainer from './formTitleContainer';
import TextField from './fields/textField';
import DropDownField from './fields/dropDownField';
import CheckBoxField from './fields/checkBoxField';
import CheckBoxGrouping from './fields/checkBoxGrouping';

export default function ShowForm({ onShowObjChange, showObj, classes, obedienceClasses, jrClasses, regularFee, nonRegularFee, onBlur, disabled }) {
    const onChange = (fieldProperty, value) => {
        const propertyName = fieldProperty.split('-')[0];
        showObj[propertyName] = value;
        onShowObjChange(showObj);
    }

    const classLabelValues = [{ label: 'Choose a Class', value: null }].concat(classes.map(({ name, isRegular }) => {
        return {
            label: `${name} ($${isRegular ? regularFee : nonRegularFee})`,
            value: name
        };
    }));
    const obedienceClassLabelValues = [{ label: 'Choose an Obedience Class', value: null }].concat(obedienceClasses.map(name => ({ label: `${name} ($${nonRegularFee || 0})`, value: name })));
    const rallyClassLabelValues = [{ label: 'Choose an Rally Class', value: null }].concat(obedienceClasses.map(name => ({ label: `${name} ($${nonRegularFee || 0})`, value: name })));
    const jrClassLabelValues = [{ label: 'Choose a Jr Class', value: null }].concat(jrClasses.map(name => ({ label: `${name} ($${nonRegularFee || 0})`, value: name })));

    return (
        <FormTitleContainer title='Show Information'>
            <form onSubmit={(e) => e.preventDefault()}>
                <fieldset disabled={disabled}>
                    <DropDownField
                        onChange={onChange}
                        fieldName='Dog Show Class'
                        fieldProperty='showClass-show'
                        labelValueObjs={classLabelValues}
                        defaultValue={showObj.showClass}
                        onBlur={onBlur} />
                    <DropDownField
                        onChange={onChange}
                        fieldName='Additional Classes'
                        fieldProperty='additionalClasses-show'
                        labelValueObjs={classLabelValues}
                        defaultValue={showObj.additionalClasses}
                        onBlur={onBlur} />
                    <DropDownField
                        onChange={onChange}
                        fieldName='Obedience Trial Class'
                        fieldProperty='obedienceTrialClass-show'
                        labelValueObjs={obedienceClassLabelValues}
                        defaultValue={showObj.obedienceTrialClass}
                        onBlur={onBlur} />
                    <TextField
                        style={{ width: '200px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Obedience Jump Height'
                        fieldProperty='obedienceJumpHeight-show'
                        defaultValue={showObj.obedienceJumpHeight} />
                    <DropDownField
                        onChange={onChange}
                        fieldName='Rally Trial Class'
                        fieldProperty='rallyTrialClass-show'
                        labelValueObjs={rallyClassLabelValues}
                        defaultValue={showObj.rallyTrialClass}
                        onBlur={onBlur} />
                    <TextField
                        style={{ width: '200px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Rally Jump Height'
                        fieldProperty='rallyJumpHeight-show'
                        defaultValue={showObj.rallyJumpHeight} />
                    <DropDownField
                        onChange={onChange}
                        fieldName='JR Showman Class'
                        fieldProperty='jrShowmanClass-show'
                        labelValueObjs={jrClassLabelValues}
                        defaultValue={showObj.jrShowmanClass}
                        onBlur={onBlur} />
                    <TextField
                        style={{ width: '350px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Note'
                        fieldProperty='note-show'
                        defaultValue={showObj.note} />
                    <CheckBoxGrouping groupingName='Owner Handler'>
                        <CheckBoxField
                            onChange={onChange}
                            onBlur={onBlur}
                            fieldName='Is Eligible?'
                            fieldProperty='isOwnerHandlerEligible-show'
                            defaultValue={showObj.isOwnerHandlerEligible} />
                    </CheckBoxGrouping>
                </fieldset>
            </form>
        </FormTitleContainer>
    );
}
