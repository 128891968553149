import React from 'react';
import PropTypes from 'prop-types';
import ClubEntryReportLeft from './clubEntryReportLeft';
import ClubEntryReportRight from './clubEntryReportRight';

export default function ClubEntryReport({entryForm, events, handler, pageStyle = {}, defaultClassFee}) {
  const style = {
    backgroundColor: 'white',
    width: '11in',
    height: '8in',
    display: 'flex',
    margin: '0.5in 0.25in 0 0.25in',
    pageBreakAfter: 'always',
    ...pageStyle
  }
  return (
    <div className='print' style={style}>
        <div style={{width: '5.2in', marginRight: '0.25in'}}>
            <ClubEntryReportLeft entryForm={entryForm} events={events} handler={handler} defaultClassFee={defaultClassFee} />
        </div>
        <div style={{width: '5.1in'}}>
          <ClubEntryReportRight juniorObj={entryForm.juniorObj} />
        </div>
    </div>
  );
}

ClubEntryReport.propTypes = {
  entryForm: PropTypes.object,
  events: PropTypes.array
}