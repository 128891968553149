import DateHelper from "../../../lib/common/dateHelper";

const filterRows = (schemas, rows, filterText) => rows.filter(row => {
    return schemas.reduce(((acc, schema) => {
        if (filterText.length === 0) {
            acc = true;
        } else  {
            let value = row[schema.propName];
            if (value) {
                if (schema.type === 'date' || schema.type === 'text') {
                    value = schema.type === 'date' ? DateHelper.numberToFormattedDate(value).toLowerCase() : value.toLowerCase();
                    if (value.indexOf(filterText) > -1) {
                        acc = true;
                    }
                }
            }
        }

        return acc;
    }), false);
});

export {filterRows}