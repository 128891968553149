import React, { useState, useEffect } from 'react';
import Clients from './clients';
import { find, insert, update } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';
import { Helper } from '../../lib/common/helper';

export default function DataClients({ onSelectClient }) {
    const [clients, setClients] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const load = async () => {
            const clients = await find(DataTables.TelephoneEntries(), {});
            setClients(clients || []);
            setIsLoaded(true);
        }
        load();
    }, []);

    const onAdd = (newClient) => {
        const save = async () => {
            const client = await insert(DataTables.TelephoneEntries(), newClient);
            setClients(clients.concat([client]));
        }

        save();
    }

    const onUpdate = (modifiedClient) => {
        const save = async () => {
            const updateClient = await update(DataTables.TelephoneEntries(), { _id: modifiedClient._id }, modifiedClient);
            let index = clients.findIndex(({ _id }) => _id === updateClient._id);
            clients[index] = updateClient;
            setClients(clients);
        }

        save();
    }

    const onSelect = (clientIndex) => {
        onSelectClient(clients[clientIndex]);
    }

    Helper.sortObjectsBy(clients, 'companyName');

    return (
        <IsLoading isLoaded={isLoaded}>
            <Clients clients={clients} onAdd={onAdd} onUpdate={onUpdate} onSelect={onSelect} />
        </IsLoading>
    );
}