import React from 'react';

const DateInput = ({ style, fieldProperty, convertedDefaultValue, onChange, onBlur }) => {
    return (
        <input
            style={style}
            id={fieldProperty}
            type='date'
            defaultValue={convertedDefaultValue}
            onChange={(e) => {
                if (onChange) {
                    let yyyymmdd = parseInt(e.target.value.split('-').join(''));
                    onChange(fieldProperty, yyyymmdd)
                }

            }}
            onBlur={(e) => {
                if (onBlur) {
                    let yyyymmdd = parseInt(e.target.value.split('-').join(''));
                    onBlur(fieldProperty, yyyymmdd);
                }
            }} />
    );
}

export default function DateField({ onChange, onBlur, fieldName, fieldProperty, defaultValue, style }) {
    let convertedDefaultValue = !!defaultValue ? defaultValue.toString() : '';
    convertedDefaultValue = convertedDefaultValue.length === 8 ? `${convertedDefaultValue.substr(0, 4)}-${convertedDefaultValue.substr(4, 2)}-${convertedDefaultValue.substr(6, 2)}` : convertedDefaultValue;

    if (fieldName) {
        return (
            <div className='container input-container'>
                <label htmlFor={fieldProperty}>{fieldName}</label>
                <DateInput
                    style={style}
                    fieldProperty={fieldProperty}
                    convertedDefaultValue={convertedDefaultValue}
                    onChange={onChange}
                    onBlur={onBlur} />
            </div>
        );
    }

    return (
        <DateInput
            style={style}
            fieldProperty={fieldProperty}
            convertedDefaultValue={convertedDefaultValue}
            onChange={onChange}
            onBlur={onBlur} />
    );
}