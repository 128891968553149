import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";

export default function IfAdmin({ userObj, children }) {
  if (userObj && userObj.isAdmin) {
    return (children);
  }

  if (userObj) {
    return (<Redirect to="/" />);
  }
  
  return (<Redirect to="/notloggedin" />);
}

IfAdmin.propTypes = {
  userObj: PropTypes.object
}