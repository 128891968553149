import React, { useState } from "react";
import UploadField from "../../components/forms/fields/uploadField";
import Papa from "papaparse";
import { find, importData } from "../../api/data";
import { DataTables } from "../../api/dataTables";

const getCountry = (PlaceofBirth, RegistrationForeignCoID, countries) => {
    switch (PlaceofBirth) {
        case '1':
            return 'usa';
        case '2':
            return 'canada';
        default:
            if (RegistrationForeignCoID > 0) {
                return countries.find(({ oldId }) => oldId === RegistrationForeignCoID).country;
            }

            return 'usa';
    }
}

const getRegistrationType = ({ RegistrationType }) => {
    switch (RegistrationType) {
        case '1':
            return 'akc';
        case '2':
            return 'akcLitter';
        case '3':
            return 'ilp';
        case '4':
            return 'foreign';
        default:
            return 'akc';
    }
}

export default function Import() {
    const [isDisabled, setIsDisabled] = useState(false);

    const onChange = (tableName, fileInput) => {
        Papa.parse(fileInput.files[0], {
            header: true,
            complete: async (results) => {
                let rows = [];
                setIsDisabled(true);

                let deleteFirst = false;
                switch (tableName) {
                    case DataTables.Breeds():
                        const breedByName = results.data.reduce(((acc, resultObj) => {
                            if (resultObj.Breed !== undefined && resultObj.Breed.trim().length > 0) {
                                let breed = resultObj.Breed.trim();
                                if (!acc[breed]) {
                                    acc[breed] = { breed, oldIds: [] };
                                }
                                acc[breed].oldIds.push(resultObj.BreedID);
                            }

                            return acc;
                        }), {});
                        rows = Object.keys(breedByName).map(breed => ({ breed, oldIds: breedByName[breed].oldIds }));
                        break;
                    case DataTables.Classes():
                        rows = results.data.filter(row => row.Class !== undefined && row.Class.trim().length > 0).map(row => ({ name: row.Class }));
                        break;
                    case DataTables.Countries():
                        rows = results.data.filter(row => row.Country !== undefined && row.Country.trim().length > 0).map(row => ({ country: row.Country, oldId: row.CountryID }));
                        break;
                    case DataTables.Customers():
                        const getEmailAddress = (value) => {
                            const parts = value.split(' ');
                            return parts.find(part => part.indexOf('@') > 0) || '';
                        };
                        rows = results.data.filter(row => !!row.FirstName)
                            .map(row => {
                                let email = getEmailAddress(row.Handler);
                                return {
                                    pin: row.PIN,
                                    firstName: row.FirstName,
                                    lastName: row.LastName,
                                    address: row.Address,
                                    city: row.City,
                                    state: row.State,
                                    zip: row.Zip,
                                    country: row.Country || 'United States',
                                    work: row.WorkPhone,
                                    home: row.HomePhone,
                                    cell: row.Mobile,
                                    fax: row.Fax,
                                    email,
                                    note: row.Note,
                                    handler: row.Handler.replace(email, ''),
                                    oldId: row.ClientID
                                };
                            });

                            deleteFirst = true;
                        break;
                    case DataTables.Dogs():
                        const breeds = await find(DataTables.Breeds());
                        const customerIds = await find(DataTables.Customers(), {}, { _id: 1, oldId: 1 });
                        const countries = await find(DataTables.Countries());

                        const dogObj = results.data.reduce(((acc, row) => {
                            if (row.RegistrationNumber) {
                                let clientIdObj = customerIds.find(({ oldId }) => oldId === row.ClientID);
                                if (!clientIdObj) {
                                    return acc;
                                }

                                let regNumber = row.RegistrationNumber.split(' ').join('');
                                if (acc[regNumber]) {
                                    acc[regNumber].customerIds.push(clientIdObj._id);
                                } else {
                                    let breedObj = breeds.find(({ oldIds }) => oldIds.findIndex(oldId => oldId === row.BreedID) > -1);

                                    acc[regNumber] = {
                                        registrationNumber: regNumber,
                                        customerIds: [clientIdObj._id],
                                        breed: breedObj ? breedObj.breed : undefined,
                                        dogName: row.RegisteredName,
                                        callName: row.CallName.split(' ')[0],
                                        whelpDate: row.DateofBirth.split(' ')[0].split('-').join(''),
                                        placeOfBirth: getCountry(row.PlaceofBirth, row.RegistrationForeignCoID, countries),
                                        breeders: row.BreederName,
                                        sire: row.Sire,
                                        dam: row.Dam,
                                        registrationType: getRegistrationType(row),
                                        sex: row.Sex === 1 ? 'male' : 'female',
                                        variety: row.Variety,
                                        classDivision: row.ClassDivision,
                                        owner: {
                                            name: row.OwnerName,
                                            address: row.OwnerAdrs,
                                            city: row.OwnerCity,
                                            state: row.OwnerST,
                                            zip: row.OwnerZIP,
                                            phone1: row.ContactPhone,
                                            phone2: row.ContactWorkPhone,
                                            email: row.Email,
                                            comments: row.Comments,
                                            agentName: row.AgentName
                                        }
                                    };
                                }
                            }

                            return acc;
                        }), {});

                        rows = Object.keys(dogObj).map(regNumber => (dogObj[regNumber]));
                        deleteFirst = true;
                        break;
                    case DataTables.JrClasses():
                        rows = results.data.filter(row => row.JrClass !== undefined && row.JrClass.trim().length > 0).map(row => ({ name: row.JrClass }));
                        break;
                    case DataTables.ObedienceClasses():
                        rows = results.data.filter(row => row.Obedience !== undefined && row.Obedience.trim().length > 0).map(row => ({ name: row.Obedience }));
                        break;
                    case DataTables.States():
                        rows = results.data.filter(row => row.State !== undefined && row.StateAbbr !== 'New England').map(row => ({ state: row.State, abbr: row.StateAbbr }));
                        break;
                    case DataTables.Superintendents():
                        rows = results.data.filter(row => row.SuperName !== undefined).map(row => ({ name: row.SuperName }));
                        break;
                    default:
                        break;
                }

                const response = await importData(tableName, rows, deleteFirst);
                alert(`Successfully imported ${response.insertRowCount} ${tableName} rows`);
                setIsDisabled(false);
            }
        });
    }

    return (
        <div>
            <h3>Import</h3>
            <div className='button-grouping-container'>
                <UploadField buttonText='Import Breeds' fieldProperty={DataTables.Breeds()} onChange={onChange} isDisabled={isDisabled} />
                <UploadField buttonText='Import Classes' fieldProperty={DataTables.Classes()} onChange={onChange} isDisabled={isDisabled} />
                <UploadField buttonText='Import Countries' fieldProperty={DataTables.Countries()} onChange={onChange} isDisabled={isDisabled} />
                <UploadField buttonText='Import Customers' fieldProperty={DataTables.Customers()} onChange={onChange} isDisabled={isDisabled} />
                <UploadField buttonText='Import Dogs' fieldProperty={DataTables.Dogs()} onChange={onChange} isDisabled={isDisabled} />
                <UploadField buttonText='Import Jr Classes' fieldProperty={DataTables.JrClasses()} onChange={onChange} isDisabled={isDisabled} />
                <UploadField buttonText='Import Obedience Classes' fieldProperty={DataTables.ObedienceClasses()} onChange={onChange} isDisabled={isDisabled} />
                <UploadField buttonText='Import States' fieldProperty={DataTables.States()} onChange={onChange} isDisabled={isDisabled} />
                <UploadField buttonText='Import Superintendents' fieldProperty={DataTables.Superintendents()} onChange={onChange} isDisabled={isDisabled} />
            </div>
        </div>
    );
}