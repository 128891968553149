import React, { useState, useEffect } from 'react';
import ShowLocations from './showLocations';
import { find, insert, remove, update } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';

export default function DataShowLocations() {
    const [showLocations, setShowLocations] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        find(DataTables.ShowLocations(), {}).then((showLocations = []) => {
            setShowLocations(showLocations);
            setIsLoaded(true);
        });
    }, []);

    const onAdd = (newSuper) => {
        const save = async () => {
            const showLocation = await insert(DataTables.ShowLocations(), newSuper);
            setShowLocations(showLocations.concat([showLocation]));
        }

        save();
    }

    const onUpdate = (showLocation) => {
        const save = async () => {
            const updatedCountry = await update(DataTables.ShowLocations(), { _id: showLocation._id }, showLocation);
            let index = showLocations.findIndex(({ _id }) => _id === updatedCountry._id);
            showLocations[index] = showLocation;
            setShowLocations(showLocations);
        }

        save();
    }
    
    const onDelete = ({ _id }) => {
        const removeRecord = async () => {
            await remove(DataTables.ShowLocations(), { _id });
            setShowLocations(showLocations.filter((showLocation) => showLocation._id !== _id));
        }

        removeRecord();
    }

    showLocations.sort((a, b) => (a.name.toLowerCase().localeCompare(b.name.toLowerCase())));

    return (
        <IsLoading isLoaded={isLoaded}>
            <ShowLocations showLocations={showLocations} onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} />
        </IsLoading>
    )
}