import React, { useState, useEffect } from 'react';
import { loadEntriesData } from '../../api/dataHelper';
import IsLoading from '../../components/common/isLoading';
import EntriesReport from './entriesReport';

export default function Entries({ superintendentId, showName, isComplete }) {
    const [entryForms, setEntryForms] = useState([]);
    const [events, setEvents] = useState([]);
    const [handlers, setHandlers] = useState([]);
    const [telephoneEntry, setTelephoneEntry] = useState({});
    const [superintendent, setSuperintendent] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const load = async () => {
            const {events, entryForms, handlers, telephoneEntry, superintendent} = await loadEntriesData(superintendentId, showName, isComplete);

            setEvents(events);
            setEntryForms(entryForms);
            setHandlers(handlers);
            setTelephoneEntry(telephoneEntry);
            setSuperintendent(superintendent);

            setIsLoaded(true);
        }
        load();
    }, [superintendentId, showName, isComplete]);

    return (
        <IsLoading isLoaded={isLoaded}>
            <EntriesReport
                events={events}
                entryForms={entryForms}
                handlers={handlers}
                telephoneEntry={telephoneEntry}
                superintendent={superintendent} />
        </IsLoading>
    );
}