import React from 'react';
import TextField from '../../forms/fields/textField';
import RadioField from '../../forms/fields/radioField';

export default function ReadOnlyFilterWithOptionsHeader({title, optionTitle, defaultOption, labelValueObjs, filterTextOnChange, optionOnChange}) {

    return (
        <div style={{ marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
            <h1>{title}</h1>
            <div>
                <TextField
                    onChange={(_, value) => filterTextOnChange(value.toLowerCase())}
                    fieldName='Filter:'
                    fieldProperty='filter'
                    defaultValue='' />

                <RadioField
                    onChange={(_, value) => optionOnChange(value)}
                    name={title}
                    fieldName={optionTitle}
                    fieldProperty='isComplete'
                    labelValueObjs={labelValueObjs}
                    defaultValue={defaultOption} />
            </div>

        </div>
    );
}