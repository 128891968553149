import React from 'react';
import PropTypes from 'prop-types';

export default function ClubEntryReportRight({ juniorObj = {} }) {
    let isJuniorChecked = juniorObj && juniorObj.akcNumber && juniorObj.relationship && juniorObj.relationship.trim().length > 0;
    let akcNumberArray = juniorObj && juniorObj.akcNumber ? juniorObj.akcNumber.split('') : [];
    let dobArray = juniorObj && juniorObj.dateOfBirth ? juniorObj.dateOfBirth.toString().split('') : [];

    let headerStyle = {
        fontSize: '12px',
        fontWeight: '800',
        textAlign: 'center',
        marginBottom: '4px'
    };
    let styleBottomBorder = {
        borderBottom: '2px black solid',
        paddingBottom: '4px'
    };
    let styleBlockAkcs = Array(11).fill().map((_, index) => {
        return {
            padding: '4px',
            margin: '0 2px',
            border: '2px black solid',
            color: index < akcNumberArray.length ? 'black' : 'transparent'
        };
    }); 
    let styleBlockDob = {
        padding: dobArray.length > 0 ? '4px' : '8px',
        margin: '0 2px',
        border: '2px black solid'
    };
    let labelStyle = {
        fontSize: '10px',
        fontWeight: '800',
    };
    let fillInStyle = {
        borderBottom: 'solid 1px black', 
        paddingLeft: '8px',
        fontSize: '12px'
    }

  return (
    <div style={{fontSize: '8px'}}>
        <div style={styleBottomBorder}>
            AKC Rules, Regulations, Policies and Guidelines are available on the American Kennel Club Web site, www.akc.org
        </div>
        <div style={headerStyle}>
            AGREEMENT
        </div>
        <div>
            I (we) agree that the club holding this event has the right to refuse this entry for cause which the club shall deem sufficient. In consideration of the acceptance of this entry and of the holding of this event and of the opportunity to have the dog judged and to win prizes, ribbons, or trophies, I (we) agree to hold the AKC, the event-giving club, their members, directors, govenors, officers, agents, superintendents or event secretary and the owner and/or lessor of the premises and any provider of services that are necesssary to hold this event and any employees or volunteers of the aforementioned parties, and any AKC approved judge, judging at this event, harmless from any claim for loss or injury which may be alleged to have been caused directly or indirectly to any person or thing by the act of this dog while in or about the event premises or grounds or near any entrance thereto, and I (we) personnally assume all responsibility and liability for any such claim; and I (we) further agree to hold the aforementioned parties harmless from an claim for loss or injury or damage to this dog.
        </div>
        <div style={styleBottomBorder}>
            Additionally, I (we) hereby assume the sole responsibility for and agree to indemnify, defend and save the aforementioned parties harmless from any and all loss and expense (including legal fees) by reason of the liability imposed by law upon and of the aforementioned parties for damage because of bodily injuries, including death at any time resulting therefrom, sustained by any person or persons, including myself (ourselves) or on account of damage to property, arising out of or in consequence of my (our) participation in this event, however such injuries, death or property damage may be caused, and whether or not the same may have been caused or may be allged to have been caused by negligence of the afroementioned parties or any of their employees, agents, or any other persons.
            <span style={{fontWeight: '800'}}>
                I (WE) AGREE THAT ANY CAUSE OF ACTION, CONTROVERSY OR CLAIM ARISING OUT OF OR RELATED TO THE ENTRY, EXHIBITION OR ATTENDANCE AT THE EVENT BEWEEN THE AKC AND THE EVENT-GIVING CLUB (UNLESS OTHERWISE STATED IN ITS PREMIUM LIST) MYSELF (OURSELVES) OR AS TO THE CONSTRUCTION, INTERPRETATION AND EFFECT OF THIS AGREEMENT SHALL BE SETTLED BY ARBITRATION PURSANT TO THE APPLICABLE RULES OF THE AMERICAN ARBITRATION ASSOCIATION. HOWEVER, PRIOR TO ARBITRATION ALL APPLICABLE AKC BYLAWS, RULES, REGULATIONS AND PROCEDURES MUST FIRST BE FOLLOWED AS SET FORTH IN THE AKC CHARTER AND BYLAWS, RULES, REGULATIONS, PUBLISHED POLICIES AND GUIDELINES.
            </span>
        </div>
        <div style={headerStyle}>
            INSTRUCTIONS
        </div>
        <div style={styleBottomBorder}>
            <ol style={{paddingLeft: '8px'}}>
                <li style={{marginBottom: '8px'}}>
                    (Variety) if you are entering a dog of breed in which there are varieties for show purposes, please designate the particular vari- ety you are entering i.e., Cocker Spaniel (solid color black, ASCOB, parti-color). Beagles (not exceeding 13 in. over 13 in. but not exceeding 15 in.). Dachshunds (longhaired, smooth, wirehaired), Bull Terriers (colored, white), Manchester Terriers (standard toy), Chihuahuas (smooth coat, long coat), English Toy Spaniels (King Charles and Ruby, Blenheim and Prince Charles), Poodles (toy, miniature, standard), Collies (rough, smooth),
                </li>
                <li style={{marginBottom: '8px'}}>
                    The following categories of dogs may be entered and shown in Best of Breed competition; Dogs that are Champions of Record and dogs which, according to their owner's records, have completed the requirements for a championship, but whose champi- onships are unconfirmed. The showing of unconfirmed Champions in Best of Breed competition is limited to a period of 90 days from the date of the show where the dog completed the requirements for a championship.
                </li>
                <li style={{marginBottom: '8px'}}>
                    (Event Class) Consult the classification in this premium list. If the event class in which you are entering your dog is divided, then, in addition to designating the class, specify the particular division of the class in which you are entering your dog, i.e., age divsion, color division, weight division.
                </li>
                <li style={{marginBottom: '8px'}}>
                    A dog must be entered in the name of the person who actually owned it at the time entries for an event closed. If a registered dog has been acquired by a new owner, it must be entered in the name of its new owner in any event for which entries closed after the date of acquirement, regardless of whether the new owner has received the registration certificate indicating that the dog is recorded in his name. State on entry form whether transfer application has been mailed to A.K.C. (For complete rules refer to Chapter 11, Section 3).
                </li>
            </ol>
        </div>
        <div style={headerStyle}>
            JUNIOR SHOWMANSHIP. <span style={{fontSize: '10px', fontWeight: 'normal'}}>If this entry is for Jr Showmanship, please give the following information.</span>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-around', marginBottom: '8px'}}>
            <div>
                <div style={headerStyle}>
                    AKC JR HANDLER #
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={styleBlockAkcs[0]}>{akcNumberArray.length > 0 ? akcNumberArray[0] : '_'}</div>
                    <div style={styleBlockAkcs[1]}>{akcNumberArray.length > 1 ? akcNumberArray[1] : '_'}</div>
                    <div style={styleBlockAkcs[2]}>{akcNumberArray.length > 2 ? akcNumberArray[2] : '_'}</div>
                    <div style={styleBlockAkcs[3]}>{akcNumberArray.length > 3 ? akcNumberArray[3] : '_'}</div>
                    <div style={styleBlockAkcs[4]}>{akcNumberArray.length > 4 ? akcNumberArray[4] : '_'}</div>
                    <div style={styleBlockAkcs[5]}>{akcNumberArray.length > 5 ? akcNumberArray[5] : '_'}</div>
                    <div style={styleBlockAkcs[6]}>{akcNumberArray.length > 6 ? akcNumberArray[6] : '_'}</div>
                    <div style={styleBlockAkcs[7]}>{akcNumberArray.length > 7 ? akcNumberArray[7] : '_'}</div>
                    <div style={styleBlockAkcs[8]}>{akcNumberArray.length > 8 ? akcNumberArray[8] : '_'}</div>
                    <div style={styleBlockAkcs[9]}>{akcNumberArray.length > 9 ? akcNumberArray[9] : '_'}</div>
                    <div style={styleBlockAkcs[10]}>{akcNumberArray.length > 10 ? akcNumberArray[10] : '_'}</div>
                </div>
            </div>
            <div>
                <div style={headerStyle}>
                    JR.'S DATE OF BIRTH
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={styleBlockDob}>{dobArray.length > 3 ? dobArray[4] : ' '}</div>
                    <div style={{...styleBlockDob, marginRight: '8px'}}>{dobArray.length > 4 ? dobArray[5] : ' '}</div>
                    <div style={styleBlockDob}>{dobArray.length > 5 ? dobArray[6] : ' '}</div>
                    <div style={{...styleBlockDob, marginRight: '8px'}}>{dobArray.length > 6 ? dobArray[7] : ' '}</div>
                    <div style={styleBlockDob}>{dobArray.length > 1 ? dobArray[2] : ' '}</div>
                    <div style={styleBlockDob}>{dobArray.length > 2 ? dobArray[3] : ' '}</div>
                </div>
            </div>
        </div>
        <div>
            The above number MUST be included. Should you not have your Junior Handler number, this may be obtained from the American Kennel Club. Phone: (919) 816-3814.
        </div>
        <div style={{marginBottom: '8px'}}>
            By signing the entry forum I (we) certify that the Junior Showman does not now, and will not at any time, act as a agent/handler for pay while continuing to compete in Junior Showmanship.
        </div>

        <div style={{display: 'flex', marginBottom: '8px'}}>
            <div style={labelStyle}>
                ADDRESS
            </div>
            <div style={{...fillInStyle, width: '100%'}}>{juniorObj.address}</div>
        </div>
        <div style={{display: 'flex', marginBottom: '8px'}}>
            <div style={labelStyle}>
                CITY
            </div>
            <div style={{...fillInStyle, width: '50%'}}>{juniorObj.city}</div>
            <div style={labelStyle}>
                STATE
            </div>
            <div style={{...fillInStyle, width: '25%'}}>{juniorObj.state}</div>
            <div style={labelStyle}>
                ZIP+4
            </div>
            <div style={{...fillInStyle, width: '25%'}}>{juniorObj.zip}</div>
        </div>
        <div style={{...styleBottomBorder, display: 'flex', paddingBottom: '4px'}}>
            <input key={isJuniorChecked} style={{marginRight: '4px'}} type='checkbox' checked={isJuniorChecked} readOnly={true} />
            <div style={{width: '80%'}}>
                If Jr. Handler is not the owner of the dog identified on the face of this form, what is the relationship of the Jr. Handler to the owner?
            </div>
            <div style={{...fillInStyle, width: '100%', display: 'flex'}}>
                <div style={{alignSelf: 'flex-end'}}>{juniorObj.relationship}</div>
            </div>
        </div>
    </div>
  );
}

ClubEntryReportRight.propTypes = {
    juniorObj: PropTypes.object,
}