import React from 'react';
import PropTypes from 'prop-types';
import FormTable from '../../components/common/FormTable';
import DateHelper from '../../lib/common/dateHelper';

const schemas = [{
  sortOrder: 0,
  columnName: 'Show Date',
  propName: 'showDate',
  type: 'date',
  isAddOk: true,
  style: {width: '15%'}
}, {
  sortOrder: 5,
  columnName: 'Closing Date',
  propName: 'closingDate',
  type: 'date',
  isAddOk: true,
  style: {width: '15%'}
}, {
  sortOrder: 10,
  columnName: 'Show Name',
  propName: 'showName',
  type: 'typeahead',
  options: [],
  onAdd: () => {},
  isAddOk: true,
  style: {width: '20%'}
}, {
  sortOrder: 20,
  columnName: 'Superintendent',
  propName: 'superintendentId',
  labelValueObjs: [],
  type: 'dropdown',
  isAddOk: true,
  style: {width: '20%'}
}, {
  sortOrder: 30,
  columnName: 'Regular Fee',
  propName: 'regularFee',
  type: 'number',
  isAddOk: true,
  style: {width: '10%'}
}, {
  sortOrder: 40,
  columnName: 'Non Regular Fee',
  propName: 'nonRegularFee',
  type: 'number',
  isAddOk: true,
  style: {width: '10%'}
}, {
  sortOrder: 50,
  columnName: '',
  propName: 'Copy',
  type: 'action',
  isAddOk: false,
  style: {width: '10%'},
  onClick: () => {}
}];

export default function DefaultEvents({defaultEvents, superLabelValueObjs, showNames, onAdd, onUpdate, onDelete, onAddShowName, onCopyAndAdd}) {
  const defaultState = {
    date: DateHelper.yyyymmddToday(),
    showName: '',
    superintendentId: '',
    amount: 0
  };

  const containerStyle = {
    minWidth: '800px',
    maxWidth: '1600px',
    margin: 'auto'
  };

  schemas.find(({propName}) => propName === 'superintendentId').labelValueObjs = superLabelValueObjs;
  const showNameSchema = schemas.find(({propName}) => propName === 'showName');
  showNameSchema.options = showNames;
  showNameSchema.onAdd = onAddShowName;

  const copySchema = schemas.find(({propName}) => propName === 'Copy');
  copySchema.onClick = onCopyAndAdd;
  
  return ( 
      <FormTable keyPropName='_id'
        tableTitle='Shows'
        defaultValueObj={defaultState}
        values={defaultEvents}
        schemas={schemas}
        onAdd={onAdd}
        onUpdate={onUpdate}
        onSelect={onDelete}
        buttonSelectText='Delete'
        containerStyle={containerStyle}
        maxDisplayCount={300} />
  );
}

DefaultEvents.propTpes = {
  defaultEvents: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCopyAndAdd: PropTypes.func.isRequired
}