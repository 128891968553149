import React from 'react';
import PropTypes from 'prop-types';
import DateHelper from '../../../lib/common/dateHelper';

/*
schemas: [
    {
        sortOrder: int,
        columnName: string,
        propName: string,
        type: text | checkbox | number
    }
]
*/


export default function ReadOnlyTable({ schemas, rows }) {
    schemas.sort((a, b) => a.sortOrder - b.sortOrder);

    const ReadOnlyColumns = ({ row }) => {
        return schemas.map(({ propName, type, render, action, getCellStyle }) => {
            let key = `${propName}-${row._id}`;

            let value = row[propName];
            let cellStyle = {
                ...row.style,
            };

            if (getCellStyle) {
                let newCellStyle = getCellStyle(row);

                cellStyle = {
                    ...cellStyle,
                    ...newCellStyle
                };
            }

            if (type === 'checkbox') {
                return (
                    <td key={key} style={cellStyle}><input type='checkbox' disabled defaultChecked={value} /></td>
                );
            } else if (type === 'number') {
                return (
                    <td key={key} style={{ ...cellStyle, textAlign: 'right' }}>{value}</td>
                );
            } else if (type === 'date') {
                return (
                    <td key={key} style={cellStyle}>{DateHelper.numberToFormattedDate(value)}</td>
                );
            }  else if (type === 'currency') {
                return (
                    <td key={key} style={{ ...cellStyle, textAlign: 'right' }}>${(value || 0).toFixed(2).toLocaleString()}</td>
                );
            } else if (type === 'array') {
                if (render) {
                    return (
                        <td key={key} style={cellStyle}>
                            {
                                value.map((row, index) => <p key={index}>{render(row)}</p>)
                            }
                        </td>
                    );
                } else {
                    return (
                        <td key={key} style={cellStyle}>{value.join(',')}</td>
                    );
                }
            } else if (type === 'action') {
                return (
                    <td key={key} style={{ ...cellStyle, textAlign: 'center' }}><button onClick={(e) => action(row, e)}>{render(row)}</button></td>
                )
            } else if (render) {
                return (
                    <td key={key} style={cellStyle}>{render(value)}</td>
                );
            } else {
                return (
                    <td key={key} style={cellStyle}>{value}</td>
                );
            }
        });
    }

    const ReadOnlyRow = ({ row }) => {
        return (
            <tr>
                <ReadOnlyColumns row={row} />
            </tr>
        );

    }

    const ReadOnlyRows = ({ rows }) => {
        return rows.map((row, index) => <ReadOnlyRow key={index} row={row} />)
    }

    const getHeaderStyle = (type, style) => {
        switch (type) {
            case 'action':
                return { ...style, textAlign: 'center' };
            case 'number':
            case 'currency':
                return { ...style, textAlign: 'right' };
            default:
                return style;
        }
    }

    const HeaderColumns = ({ typeColumnNames, styles }) => {
        return typeColumnNames.map((typeColumnName, index) => (<th key={`header-${typeColumnName.columnName}`} style={getHeaderStyle(typeColumnName.type, styles[index])}>{typeColumnName.columnName}</th>));
    }

    const HeaderRow = ({ typeColumnNames, styles }) => {
        return (
            <tr>
                <HeaderColumns typeColumnNames={typeColumnNames} styles={styles} />
            </tr>
        );
    }

    return (
        <table className='readyonly-table'>
            <thead>
                <HeaderRow typeColumnNames={schemas.map(({ type, columnName }) => ({ type, columnName }))} styles={schemas.map(({ style }) => style)} />
            </thead>
            <tbody>
                <ReadOnlyRows rows={rows} />
            </tbody>
        </table>
    );
}

ReadOnlyTable.propTpes = {
    rows: PropTypes.array.isRequired,
    schemas: PropTypes.array.isRequired
}
