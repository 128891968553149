import React from 'react';
import FormTitleContainer from './formTitleContainer';
import TextField from './fields/textField';
import DateField from './fields/dateField';

export default function JuniorForm({ onJuniorObjChange, juniorObj, onBlur, disabled }) {
    const onChange = (fieldProperty, value) => {
        const propertyName = fieldProperty.split('-')[0];
        juniorObj[propertyName] = value;
        onJuniorObjChange(juniorObj);
    }

    return (
        <FormTitleContainer title='Junior Information'>
            <form onSubmit={(e) => e.preventDefault()}>
                <fieldset disabled={disabled}>
                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Junior Akc Number'
                        fieldProperty='akcNumber-junior'
                        maxLength='11'
                        defaultValue={juniorObj.akcNumber} />

                    <DateField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Date of Birth'
                        fieldProperty='dateOfBirth-junior'
                        defaultValue={juniorObj.dateOfBirth} />

                    <TextField
                        style={{ width: '350px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Address'
                        fieldProperty='address-junior'
                        defaultValue={juniorObj.address} />

                    <TextField
                        style={{ width: '350px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='City'
                        fieldProperty='city-junior'
                        defaultValue={juniorObj.city} />

                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='State'
                        fieldProperty='state-junior'
                        defaultValue={juniorObj.state} />

                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Zip+4'
                        fieldProperty='zip-junior'
                        defaultValue={juniorObj.zip} />

                    <TextField
                        style={{ width: '350px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Relationship'
                        fieldProperty='relationship-junior'
                        defaultValue={juniorObj.relationship} />
                </fieldset>
            </form>
        </FormTitleContainer>
    );
}

