import React, { useState } from 'react';
import { insert, update } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import TextField from './fields/textField';
import NumberField from './fields/numberField';
import DateHelper from '../../lib/common/dateHelper';

// const acceptableProperties = [
//     'callback',
//     'paymentSelector',
//     'paymentType',
//     'theme',
//     'primaryColor',
//     'secondaryColor',
//     'buttonText',
//     'fields',
//     'tokenizationKey',
//     'variant',
//     'styleSniffer',
//     'snifferClass',
//     'customCss',
//     'invalidCss',
//     'validCss',
//     'placeholderCss',
//     'token',
//     'validationCallback',
//     'timeoutDuration',
//     'timeoutCallback',
//     'focusCss',
//     'googleFont',
//     'fieldsAvailableCallback',
//     'instructionText',
//     'country',
//     'price',
//     'currency',
//     'collectShippingInfo',
//     'collectBillingInfo',
//   ];

export default function Pay({ customer, entryFormId, amount, hasPaid, onHasPaidChange, onAmountChange, onCustomerChange }) {
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = (event) => {
        setIsProcessing(true);
        event.preventDefault();
        window.CollectJS.startPaymentRequest();
    }

    const handleNoPayment = async (event) => {
        setIsProcessing(true);
        event.preventDefault();
        await insert(DataTables.Payments(), { token: 'No Payment', customerId: customer._id, entryFormId, paymentDate: DateHelper.yyyymmddToday(), amount: 0 });
        await update(DataTables.EntryForms(), { _id: entryFormId }, { $set: { hasPaid: true } });
        onHasPaidChange(true);
        setIsProcessing(false);
    }

    const finishSubmit = (response) => {
        if (response.token) {
            const { token } = response;
            const updateDb = async () => {
                await insert(DataTables.Payments(), { token, customerId: customer._id, entryFormId, paymentDate: DateHelper.yyyymmddToday(), amount: Number(sessionStorage.amount) });
                await update(DataTables.EntryForms(), { _id: entryFormId }, { $set: { hasPaid: true } });
                onHasPaidChange(true);
                setIsProcessing(false);
            }

            updateDb();
        }
    }

    if (window.CollectJS) {
        window.CollectJS.configure({
            callback: (token) => finishSubmit(token),
            validationCallback: (response) => console.log(response),
            fields: {
                ccnumber: {
                    placeholder: '',
                    selector: '#ccnumber'
                },
                ccexp: {
                    placeholder: '',
                    selector: '#ccexp'
                },
                cvv: {
                    placeholder: '',
                    selector: '#cvv'
                }
            }
        });
    } else {
        console.warn('Note Credit Card payment setup would require you to log out and then log back in.');
    }

    sessionStorage.amount = amount;

    return (
        <form onSubmit={handleSubmit}>
            <fieldset disabled={hasPaid}>
                <TextField
                    style={{ width: '200px' }}
                    onChange={onCustomerChange}
                    name='firstName'
                    fieldName='First Name'
                    fieldProperty='firstName'
                    defaultValue={customer.firstName} />
                <TextField
                    style={{ width: '200px' }}
                    onChange={onCustomerChange}
                    name='lastName'
                    fieldName='Last Name'
                    fieldProperty='lastName'
                    defaultValue={customer.lastName} />
                <TextField
                    style={{ width: '350px' }}
                    onChange={onCustomerChange}
                    name='email'
                    fieldName='Email'
                    fieldProperty='email'
                    defaultValue={customer.email} />
                <NumberField
                    onChange={(_, value) => onAmountChange(value)}
                    name='amount'
                    fieldName='Entry Form Amount'
                    fieldProperty='amount'
                    value={amount} />
            </fieldset>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <button id='payButton' type='submit' disabled={isProcessing || hasPaid || !window.CollectJS}>Pay</button>
                <button id='noPayButton' onClick={(event) => handleNoPayment(event)} disabled={isProcessing || hasPaid}>No Payment</button>
            </div>
        </form>
    );
}