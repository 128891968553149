import React, { useState, useEffect } from 'react';
import { find } from '../api/data';
import { DataTables } from '../api/dataTables';
import Tabs from '../components/common/tabs';
import Submission from '../reports/submission/submission';
import HaveNotPaid from '../reports/haveNotPaid';
import PreviouslySubmitted from '../reports/previouslySubmitted';
import Counts from '../reports/counts';

const defaultTabs = [{
    name: 'Submissions',
    key: 'submissions'
}, {
    name: 'Counts',
    key: 'counts'
}, {
    name: 'Have Not Paid (0)',
    key: 'havenotpaid'
}, {
    name: 'Previously Submitted',
    key: 'previouslysubmitted'
}];

export default function Reports(props) {
    const { history } = props;
    const getActiveTab = () => {
        const tabIndex = tabs.findIndex(tab => history.location.pathname.indexOf(tab.key) > -1);
        return tabIndex === -1 ? 'submissions' : tabs[tabIndex].key;
    };

    const [tabs, setActiveTabs] = useState(defaultTabs);
    const [activeTab, setActiveTab] = useState(getActiveTab());
    const [entryForms, setEntryForms] = useState([]);

    useEffect(() => {
        const load = async () => {
            const entryForms = await find(DataTables.EntryForms(), {hasPaid: {$exists: false}}) || [];
            setEntryForms(entryForms);
            setActiveTabs(defaultTabs.map(tab => {
                return {
                    ...tab,
                    name: tab.key === 'havenotpaid' ? `Have Not Paid (${entryForms.length})` : tab.name
                };
            }));
        };

        load();
    }, []);

    const tabOnChange = (key) => {
        setActiveTab(key);
        history.push(`/reports/${key}`);
    }

    const renderPage = () => {
        if (activeTab === 'submissions') {
            return <Submission />
        } else if (activeTab === 'counts') {
            return <Counts />
        } else if (activeTab === 'havenotpaid') {
            return <HaveNotPaid entryForms={entryForms} />
        } else if (activeTab === 'previouslysubmitted') {
            return <PreviouslySubmitted />
        }
    }

    return (
        <div>
            <Tabs tabs={tabs} activeTab={activeTab} onChange={tabOnChange} />
            {renderPage()}
        </div>

    );
}