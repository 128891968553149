import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../lib/common/theme';

export default function Tabs({ tabs, activeTab, onChange }) {
    const tabContainerStyle = {
        display: 'flex',
        borderBottom: `solid 1px ${theme.primaryBackground}`,
        marginBottom: '16px'
    };
    const tabStyle = {
        padding: `${theme.xsmall} ${theme.large}`,
        color: theme.secondaryText,
        cursor: 'pointer'
    };
    const tabActiveStyle = {
        ...tabStyle,
        color: theme.secondaryTextHighlight,
        borderBottom: `solid 3px ${theme.secondaryTextHighlight}`
    }

    const renderTabs = (tabs) => {
        return tabs.map(tab => {
            return (
                <div key={tab.key} 
                    style={tab.key === activeTab ? tabActiveStyle : tabStyle}
                    onClick={() => onChange(tab.key)}>
                    {tab.name}
                </div>
            );
        });
    }

    return (
        <div style={tabContainerStyle}>
            {renderTabs(tabs)}
        </div>
    )
}

Tabs.propTypes = {
    tabs: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    activeTab: PropTypes.string
}