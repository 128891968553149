import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterHeader from './FilterHeader';
import DropDownField from '../forms/fields/dropDownField';
import TypeAheadField from '../forms/fields/typeAheadField';
import DateField from '../forms/fields/dateField';
import DateHelper from '../../lib/common/dateHelper';

/*
schemas: [
    {
        sortOrder: int,
        columnName: string,
        propName: string,
        type: text | checkbox | number
        isAddOk: boolean
    }
]
*/
const getNewKey = (propName) => `new-col-${propName}`;
const getEditKey = (propName, rowIndex) => `edit-col-${propName}-${rowIndex}`;

const getValue = (type, element) => {
    switch (type) {
        case 'checkbox':
            return element.checked;
        case 'date':
            return parseInt(element.value.split('-').join(''));
        case 'number':
            return Number(element.value);
        default:
            return element.value;
    }
}

export default function FormTable({ tableTitle, defaultValueObj, schemas, values = [], onAdd, onUpdate, onSelect, onDelete, buttonSelectText = 'Select', containerStyle, maxDisplayCount = 20 }) {
    const [displayValues, setDisplayValues] = useState([]);
    const [filterText, setFilterText] = useState('');

    schemas.sort((a, b) => a.sortOrder - b.sortOrder);

    useEffect(() => {
        let filteredValues = [];

        if (filterText.length === 0) {
            filteredValues = values.filter((_, index) => index < maxDisplayCount)
        } else {
            for (let index = 0; index < values.length; index++) {
                let valueObj = values[index];
                const matchFilter = schemas.reduce(((acc, schema) => {
                    if (schema.type === 'dropdown') {
                        let { label } = schema.labelValueObjs.find(({ value }) => value === valueObj[schema.propName]) || { label: '' };
                        label = (label || '').toString().toLowerCase();
                        if (label.indexOf(filterText) > -1) {
                            acc = true;
                        }
                    } else {
                        let value = (valueObj[schema.propName] || '').toString().toLowerCase();
                        if (schema.type === 'date') {
                            if (DateHelper.numberToFormattedDate(value).indexOf(filterText) > -1) {
                                acc = true;
                            }
                        } else if (value.indexOf(filterText) > -1) {
                            acc = true;
                        }
                    }

                    return acc;
                }), false);

                if (matchFilter) {
                    filteredValues.push(valueObj);
                }

                if (filteredValues.length === maxDisplayCount) {
                    break;
                }
            }
        }

        setDisplayValues(JSON.parse(JSON.stringify(filteredValues)));
    }, [filterText, JSON.stringify(values)]); // eslint-disable-line react-hooks/exhaustive-deps

    const onNewValueAdd = () => {
        let newValue = schemas.reduce(((acc, schema) => {
            let element = document.getElementById(getNewKey(schema.propName));
            if (element) {
                acc[schema.propName] = getValue(schema.type, element);
            }

            return acc;
        }), {});

        onAdd(newValue);
    }

    const onEditUpdate = (rowIndex) => {
        let updateValue = schemas.reduce(((acc, schema) => {
            let element = document.getElementById(getEditKey(schema.propName, rowIndex));
            if (element) {
                acc[schema.propName] = getValue(schema.type, element);
            }

            return acc;
        }), displayValues[rowIndex]);

        onUpdate(updateValue);
    }

    const RowColumns = ({ rowIndex }) => {
        return schemas.map(({ propName, type, labelValueObjs, options, onAdd, onClick }, index) => {
            let key = getEditKey(propName, rowIndex);
            let defaultValue = displayValues[rowIndex][propName];
            let inputStyle = { width: '100%' };
            let cellStyle = { padding: '0 8px' };

            switch (type) {
                case 'typeahead':
                    return (
                        <td key={key} style={cellStyle}>
                            <TypeAheadField
                                style={{ width: '100%' }}
                                onAdd={onAdd}
                                fieldProperty={key}
                                defaultValue={defaultValue}
                                nextInputElementId={getNewKey(schemas[index + 1].propName)}
                                onBlur={() => onEditUpdate(rowIndex)}
                                options={options} />
                        </td>
                    );
                case 'dropdown':
                    return (
                        <td key={key} style={cellStyle}>
                            <DropDownField
                                fieldProperty={key}
                                labelValueObjs={labelValueObjs}
                                defaultValue={defaultValue}
                                onChange={() => onEditUpdate(rowIndex)} />
                        </td>
                    );
                case 'checkbox':
                    return (
                        <td key={key} style={cellStyle}>
                            <input id={key} type={type} style={inputStyle} defaultChecked={defaultValue}
                                onBlur={() => onEditUpdate(rowIndex)} />
                        </td>
                    );
                case 'date':
                    return (
                        <td key={key} style={cellStyle}>
                            <DateField
                                style={inputStyle}
                                onBlur={() => onEditUpdate(rowIndex)}
                                fieldProperty={key}
                                defaultValue={defaultValue} />
                        </td>
                    );
                case 'action':
                    return (
                        <td key={key} style={cellStyle}>
                            <button onClick={() => onClick(rowIndex)}>{propName}</button>
                        </td>
                    );
                default:
                    return (
                        <td key={key} style={cellStyle}>
                            <input id={key} type={type} style={inputStyle} defaultValue={defaultValue}
                                onBlur={() => onEditUpdate(rowIndex)} />
                        </td>
                    );
            }
        });
    }

    const NewRowColumns = () => {
        return schemas.map(({ propName, type, isAddOk, getAddValue, labelValueObjs, options, onAdd }, index) => {
            let key = getNewKey(propName);

            if (isAddOk) {
                let defaultValue = defaultValueObj[propName];
                let inputStyle = { width: '100%' };
                let cellStyle = { padding: '0 8px' };

                if (getAddValue && typeof getAddValue === 'function') {
                    defaultValue = getAddValue();
                }

                switch (type) {
                    case 'typeahead':
                        return (
                            <td key={key} style={cellStyle}>
                                <TypeAheadField
                                    style={{ width: '100%' }}
                                    onAdd={onAdd}
                                    fieldProperty={key}
                                    defaultValue=''
                                    nextInputElementId={getNewKey(schemas[index + 1].propName)}
                                    options={options} />
                            </td>
                        );
                    case 'dropdown':
                        return (
                            <td key={key} style={cellStyle}>
                                <DropDownField
                                    fieldProperty={key}
                                    labelValueObjs={labelValueObjs} />
                            </td>
                        );
                    case 'checkbox':
                        return (
                            <td key={key} style={cellStyle}>
                                <input id={key} type={type} style={inputStyle} defaultChecked={defaultValue} />
                            </td>
                        );
                    case 'date':
                        return (
                            <td key={key} style={cellStyle}>
                                <DateField
                                    style={inputStyle}
                                    fieldProperty={key}
                                    defaultValue={defaultValue} />
                            </td>
                        );
                    default:
                        return (
                            <td key={key} style={cellStyle}>
                                <input id={key} type={type} style={inputStyle} defaultValue={defaultValue} />
                            </td>
                        );
                }

            }

            return (<td key={key}></td>);
        });
    }

    const Row = ({rowIndex}) => {
        return (
            <tr style={{ lineHeight: '38px' }}>
                <td style={{ textAlign: 'center', padding: '0 8px' }}>
                    {!!onSelect ? <button onClick={() => onSelect(rowIndex)}>{buttonSelectText}</button> : ''}
                    {!!onDelete ? <button onClick={() => onDelete(displayValues[rowIndex])}>Delete</button> : ''}
                </td>
                <RowColumns rowIndex={rowIndex} />
            </tr>
        );

    }

    const NewRow = () => {
        return (
            <tr style={{ lineHeight: '38px' }}>
                <td style={{ textAlign: 'center' }}><button onClick={() => onNewValueAdd()}>Add</button></td>
                <NewRowColumns />
            </tr>
        );

    }

    const Rows = () => {
        return displayValues.map((_, index) => <Row key={index} rowIndex={index} />);
    }

    const HeaderColumns = () => {
        return schemas.map(({ columnName, style }) => (<th key={`header-${columnName}`} style={style}>{columnName}</th>));
    }

    const HeaderRow = () => {
        return (
            <tr>
                <th style={{ width: '5%' }}>Actions</th>
                <HeaderColumns />
            </tr>
        );
    }

    return (
        <div style={containerStyle}>
            <FilterHeader
                title={tableTitle}
                setFilterText={setFilterText}
                numerator={displayValues.length}
                denominator={values.length} />

            <table className='input-grouping-container'>
                <thead>
                    <HeaderRow />
                </thead>
                <tbody>
                    <NewRow />
                    <Rows />
                </tbody>
            </table>
        </div>
    );
}

FormTable.propTpes = {
    tableTitle: PropTypes.string.isRequired,
    defaultValueObj: PropTypes.object.isRequired,
    schemas: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    onAdd: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    containerStyle: PropTypes.object.isRequired,
    maxDisplayCount: PropTypes.number,
    onSelect: PropTypes.func,
    onDelete: PropTypes.func
}