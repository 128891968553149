import React from 'react';
import PropTypes from 'prop-types';

export default function ClubEntryReportNote({note, callName, total = 0}) {

  return (
    <div style={{fontSize: '12px', display: 'flex', justifyContent: 'space-between'}}>
      <span>{callName}</span>
      <span>{note}</span>
      <span>${total.toFixed(2).toLocaleString()}</span>
    </div>
  );
}

ClubEntryReportNote.propTypes = {
  note: PropTypes.string,
  callName: PropTypes.string,
  total: PropTypes.number
}