import React, { useEffect, useState } from 'react';
import { find } from '../api/data';
import { DataTables } from '../api/dataTables';
import IsLoading from '../components/common/isLoading';
import ReadOnlyTable from '../components/common/readOnlyTable/readOnlyTable';
import ReadOnlyFilterHeader from '../components/common/readOnlyTable/readOnlyFilterHeader';
import { filterRows } from '../components/common/readOnlyTable/readOnlyFilterHelper';
import { Helper } from '../lib/common/helper';

const schemas = [{
    sortOrder: 10,
    columnName: 'Show Date',
    propName: 'showDate',
    type: 'date',
    style: { width: '10%' }
}, {
    sortOrder: 20,
    columnName: 'Show',
    propName: 'showName',
    type: 'text',
    style: { width: '30%' }
}, {
    sortOrder: 30,
    columnName: 'Superintendent',
    propName: 'superName',
    type: 'text',
    style: { width: '30%' }
}, {
    sortOrder: 40,
    columnName: 'Number of Entries',
    propName: 'count',
    type: 'number',
    style: { width: '15%' }
}, {
    sortOrder: 50,
    columnName: 'Total',
    propName: 'amount',
    type: 'currency',
    style: { width: '15%' }
}];

const containerStyle = {
    minWidth: '800px',
    maxWidth: '1200px',
    margin: '0 auto 16px auto',
};

const getKey = (showName, showDate) => Helper.getHashCode(`${showName}-${showDate}`);

export default function Counts() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const load = async () => {
            const supers = await find(DataTables.Superintendents(), {}, { _id: 1, name: 1 });

            const superMap = supers.reduce(((acc, superObj) => {
                acc[superObj._id] = superObj.name;
                return acc;
            }), {});

            const events = await find(DataTables.Events(), { isComplete: true });
            const amountMap = events.reduce(((acc, event) => {
                const key = getKey(event.name, event.showDate);
                if (!acc[key]) {
                    acc[key] = {
                        superName: superMap[event.superintendentId],
                        showName: event.name,
                        showDate: event.showDate,
                        count: 0,
                        amount: 0
                    }
                }

                acc[key].count += 1;
                acc[key].amount += event.amount;
                return acc;
            }), {});

            const rows = Object.keys(amountMap).reduce(((acc, key) => {
                acc.push({ ...amountMap[key] });
                return acc;
            }), []);

            rows.sort((a, b) => {
                if (a.showDate !== b.showDate) {
                    return b.showDate - a.showDate
                }

                if (a.showName !== b.showName) {
                    return (a.showName > b.showName) - (a.showName < b.showName);
                }

                return (a.superName > b.superName) - (a.superName < b.superName);
            });

            setRows(rows);
            setIsLoaded(true);
        }

        load();
    }, []);

    const filteredRows = filterRows(schemas, rows, filterText);
    const filteredStats = filteredRows.reduce(((acc, row) => {
        acc.count += row.count;
        acc.amount += row.amount;
        return acc;
    }), {amount: 0, count: 0});

    return (
        <IsLoading isLoaded={isLoaded}>
            <div style={containerStyle}>
                <ReadOnlyFilterHeader
                    title={`Entry Counts (${filteredStats.count}, $${filteredStats.amount.toFixed(2).toLocaleString()})`}
                    schemas={schemas}
                    filterTextOnChange={setFilterText} />
                <ReadOnlyTable
                    rows={filteredRows}
                    schemas={schemas} />
            </div>
        </IsLoading>
    );
}