import React, { useState, useEffect } from 'react';
import { find, insert } from '../../api/data';
import DateField from './fields/dateField';
import TypeAheadField from './fields/typeAheadField';
import FormTitleContainer from './formTitleContainer';
import DropDownField from './fields/dropDownField';
import { DataTables } from '../../api/dataTables';
import { Helper } from '../../lib/common/helper';
import NumberField from './fields/numberField';
import TextField from './fields/textField';
import IsLoading from '../common/isLoading';

const RvFields = ({ index, event, onChange, onBlur }) => {
    if (event.rvFee !== undefined && event.rvFee > 0) {
        return (
            <>
                <TextField
                    onChange={onChange}
                    fieldName='RV Make'
                    fieldProperty={`${index}-rvMake`}
                    defaultValue={event.rvMake}
                    onBlur={() => onBlur(event, index)} />
                <TextField
                    onChange={onChange}
                    fieldName='RV Model'
                    fieldProperty={`${index}-rvModel`}
                    defaultValue={event.rvModel}
                    onBlur={() => onBlur(event, index)} />
                <TextField
                    onChange={onChange}
                    fieldName='RV Length'
                    fieldProperty={`${index}-rvLength`}
                    defaultValue={event.rvLength}
                    onBlur={() => onBlur(event, index)} />
                <TextField
                    onChange={onChange}
                    fieldName='RV Width'
                    fieldProperty={`${index}-rvWidth`}
                    defaultValue={event.rvWidth}
                    onBlur={() => onBlur(event, index)} />
                <TextField
                    onChange={onChange}
                    fieldName='RV Number'
                    fieldProperty={`${index}-rvNumber`}
                    defaultValue={event.rvNumber}
                    onBlur={() => onBlur(event, index)} />
                <TextField
                    onChange={onChange}
                    fieldName='RV Slide Out Side'
                    fieldProperty={`${index}-rvSlideOutSide`}
                    defaultValue={event.rvSlideOutSide}
                    onBlur={() => onBlur(event, index)} />
                <TextField
                    onChange={onChange}
                    fieldName='RV License Number'
                    fieldProperty={`${index}-rvLicenseNumber`}
                    defaultValue={event.rvLicenseNumber}
                    onBlur={() => onBlur(event, index)} />
                <TextField
                    style={{ width: '500px' }}
                    onChange={onChange}
                    fieldName='RV Notes'
                    fieldProperty={`${index}-rvNotes`}
                    defaultValue={event.rvNotes}
                    onBlur={() => onBlur(event, index)} />
            </>
        );
    }

    return null;
}

const EventButtonRow = ({ index, defaultClassFee, event, onDelete, onUseCalculatedFees }) => {
    if (!!event._id) {
        return (
            <div className='row-seperator' style={{ display: 'flex', justifyContent: 'space-around' }}>
                <button disabled={!event.entryFees || event.entryFees === defaultClassFee} onClick={() => onUseCalculatedFees(event, index, defaultClassFee)}>Use Calculated Fees: ${defaultClassFee}</button>
                <button onClick={() => onDelete(event._id)}>Delete</button>
            </div>
        );
    }

    return null;
}

const getFilteredEvents = (events) => {
    return events.filter(({ location }) => location.length > 0)
        .map(({ _id, location, name, showDate, superintendentId, amount, entryFees, regularFee, nonRegularFee, feeAmount, rvFee, groomingFee, trackingFee, rvMake, rvModel, rvLength, rvWidth, rvNumber, rvSlideOutSide, rvLicenseNumber, rvNotes }) => ({ _id, location, name, showDate, superintendentId, amount, entryFees, regularFee, nonRegularFee, feeAmount, rvFee, groomingFee, trackingFee, rvMake, rvModel, rvLength, rvWidth, rvNumber, rvSlideOutSide, rvLicenseNumber, rvNotes }));
}

const EventRow = ({ onEventsChange, events, event, defaultClassFee, index, supers, onBlur, onDelete, onAddShowLocation, onAddShow, showLocations, showNames, defaultFee }) => {
    const [blurCount, setBlurCount] = useState(0);

    const onShowLocationChange = (fieldProperty, value) => {
        const index = fieldProperty.split('-')[0];
        events[index].location = value;
        onEventsChange(getFilteredEvents(events));
    }
    const onNameChange = (fieldProperty, value) => {
        const index = fieldProperty.split('-')[0];
        events[index].name = value;
        onEventsChange(getFilteredEvents(events));
    }
    const onDateChange = (fieldProperty, value) => {
        const index = fieldProperty.split('-')[0];
        events[index].showDate = value;
        onEventsChange(getFilteredEvents(events));
    }
    const onSuperChange = (fieldProperty, value) => {
        const index = fieldProperty.split('-')[0];
        events[index].superintendentId = value;
        onEventsChange(getFilteredEvents(events));
    }
    const onNumberChange = (fieldProperty, value) => {
        const [index, property] = fieldProperty.split('-');
        events[index][property] = Number(value);
        onEventsChange(getFilteredEvents(events));
    }

    const onUseCalculatedFees = (event, index, value) => {
        onNumberChange(`${index}-entryFees`, value);
        onBlur(event, index);
        setBlurCount(blurCount + 1);
    }

    const onRvChange = (fieldProperty, value) => {
        const [index, property] = fieldProperty.split('-');
        events[index][property] = value;
        onEventsChange(getFilteredEvents(events));
    }

    useEffect(() => {
        setBlurCount(count => count + 1);
    }, [defaultClassFee]);

    let style = {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: '8px'
    }

    event.feeAmount = event.feeAmount || defaultFee;
    if (typeof event.feeAmount !== 'number') {
        event.feeAmount = Number(event.feeAmount || 0);
    }

    return (
        <div style={{ display: 'block' }}>
            <div style={style}>
                <TypeAheadField
                    style={{ width: '350px' }}
                    onChange={onShowLocationChange}
                    onAdd={onAddShowLocation}
                    fieldName='Show Location (City, State)'
                    fieldProperty={`${index}-EventLocation`}
                    defaultValue={event.location}
                    nextInputElementId={`${index}-EventShowDate`}
                    options={showLocations}
                    onBlur={() => onBlur(event, index)} />
                <DateField
                    onChange={onDateChange}
                    fieldName='Show Date'
                    fieldProperty={`${index}-EventShowDate`}
                    defaultValue={event.showDate}
                    onBlur={() => onBlur(event, index)} />
                <TypeAheadField
                    style={{ width: '350px' }}
                    onChange={onNameChange}
                    onAdd={onAddShow}
                    fieldName='Show Name'
                    fieldProperty={`${index}-EventName`}
                    defaultValue={event.name}
                    nextInputElementId={`${index}-EventSuper`}
                    options={showNames}
                    onBlur={() => onBlur(event, index)} />
                <DropDownField
                    onChange={onSuperChange}
                    fieldName='Superintendent'
                    fieldProperty={`${index}-EventSuper`}
                    labelValueObjs={supers}
                    defaultValue={event.superintendentId}
                    onBlur={() => onBlur(event, index)} />
                <NumberField
                    key={`${event._id}-${index}-${blurCount}`}
                    onChange={onNumberChange}
                    fieldName='Entry Fees'
                    fieldProperty={`${index}-entryFees`}
                    defaultValue={event.entryFees || defaultClassFee}
                    onBlur={() => onBlur(event, index)} />
                <NumberField
                    onChange={onNumberChange}
                    fieldName='RV Fee'
                    fieldProperty={`${index}-rvFee`}
                    defaultValue={event.rvFee}
                    onBlur={() => onBlur(event, index)} />
                <NumberField
                    onChange={onNumberChange}
                    fieldName='Grooming Fee'
                    fieldProperty={`${index}-groomingFee`}
                    defaultValue={event.groomingFee}
                    onBlur={() => onBlur(event, index)} />
                <NumberField
                    onChange={onNumberChange}
                    fieldName='Tracking Fee'
                    fieldProperty={`${index}-trackingFee`}
                    defaultValue={event.trackingFee}
                    onBlur={() => onBlur(event, index)} />
                <NumberField
                    onChange={onNumberChange}
                    fieldName='Fee Amount'
                    fieldProperty={`${index}-feeAmount`}
                    defaultValue={event.feeAmount}
                    onBlur={() => onBlur(event, index)} />
                <RvFields index={index} event={event} onChange={onRvChange} onBlur={onBlur} />
            </div>
            <EventButtonRow index={index} event={event} defaultClassFee={defaultClassFee} onDelete={onDelete} onUseCalculatedFees={onUseCalculatedFees} />
        </div>
    );
}

export default function EventsForm({ events = [], defaultClassFee, onEventsChange, onDeleteEvent, superintendents, onBlur, disabled }) {
    const [showNames, setShowNames] = useState([]);
    const [showLocations, setShowLocations] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const supers = [{ value: 'empty', label: 'Choose a Superintendent' }].concat(superintendents.map(({ _id, name }) => ({ value: _id, label: name })));
    const onAddShow = (showName) => {
        insert(DataTables.ShowNames(), { name: showName });
        const newShowNames = showNames.concat([showName]);
        Helper.sortObjectsBy(newShowNames, 'name');
        setShowNames(showNames.concat([showName]));
    }

    const onAddShowLocation = (showLocation) => {
        insert(DataTables.ShowLocations(), { name: showLocation });
        const newShowNames = showLocations.concat([showLocation]);
        Helper.sortObjectsBy(newShowNames, 'name');
        setShowLocations(showLocations.concat([showLocation]));
    }

    useEffect(() => {
        const load = async () => {
            const showNameObjs = await find(DataTables.ShowNames(), {}) || [];
            const showLocationObjs = await find(DataTables.ShowLocations(), {}) || [];

            if (showNameObjs.length > 0) {
                const showNames = showNameObjs.map(({ name }) => name);
                showNames.sort();
                setShowNames(showNames);
            }

            if (showLocationObjs.length > 0) {
                const showLocations = showLocationObjs.map(({ name }) => name);
                showLocations.sort();
                setShowLocations(showLocations);
            }

            setIsLoaded(true);
        }
        load();
    }, []);

    return (
        <FormTitleContainer title='Events'>
            <IsLoading isLoaded={isLoaded}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <fieldset disabled={disabled}>
                        {events.length === 0 ? <h4>No Events Selected</h4> : 
                            events.map((event, index) => <EventRow key={event._id || index}
                            onEventsChange={onEventsChange}
                            events={events}
                            defaultClassFee={defaultClassFee}
                            event={event}
                            index={index}
                            supers={supers}
                            onBlur={onBlur}
                            onDelete={onDeleteEvent}
                            onAddShowLocation={onAddShowLocation}
                            onAddShow={onAddShow}
                            showLocations={showLocations}
                            showNames={showNames}
                            defaultFee={sessionStorage.defaultFee} />)}
                    </fieldset>
                </form>
            </IsLoading>
        </FormTitleContainer>
    );
}