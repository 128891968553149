import React, { useState, useEffect } from 'react';
import { find, insert, update } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';
import TelephoneEntryForm from '../../components/forms/telephoneEntryForm';

export default function DataTelephoneEntry() {
    const [telephoneEntry, setTelephoneEntry] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const load = async () => {
            const telephoneEntries = await find(DataTables.TelephoneEntries(), {});
            if (telephoneEntries && telephoneEntries.length > 0) {
                setTelephoneEntry(telephoneEntries[0]);
            }
            
            setIsLoaded(true);
        }

        load();
    }, []);

    const onSave = () => {
        const save = async () => {
            if (telephoneEntry._id) {
                update(DataTables.TelephoneEntries(), { _id: telephoneEntry._id }, telephoneEntry);
            } else {
                const updateObj = await insert(DataTables.TelephoneEntries(), telephoneEntry);
                setTelephoneEntry(updateObj);
            }
        }

        save();
    };

    const onChange = (telephoneEntryObj) => {
        setTelephoneEntry(telephoneEntryObj);
    };

    return (
        <IsLoading isLoaded={isLoaded}>
            <TelephoneEntryForm onTelephoneEntryObjChange={onChange} telephoneEntryObj={telephoneEntry} onBlur={onSave} />
        </IsLoading>
    );
}