import React from 'react';

export default function TextField({onChange, onBlur, fieldName, fieldProperty, name, defaultValue, style, maxLength=500}) {
    return (
        <div className='container input-container'>
            <label htmlFor={fieldProperty}>{fieldName}</label>
            <input 
                style={style} 
                id={fieldProperty} 
                type='text' 
                name={name}
                defaultValue={defaultValue} 
                maxLength={maxLength}
                onBlur={() => {
                    if (onBlur) {
                        onBlur();
                    }
                }}
                onChange={(e) => onChange(fieldProperty, e.target.value) }/>
        </div>
    )
}