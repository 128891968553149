import { useEffect } from 'react';

const useScript = (src, attributes, paymentToken) => {
  useEffect(() => {
    if (paymentToken && !window.CollectJS) {
      let script = document.createElement('script');
      script.src = src;
      script.async = true;
      Object.keys(attributes).forEach(key => script.setAttribute(key, attributes[key]));

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }
  }, [src, attributes, paymentToken]);
};

export default useScript;