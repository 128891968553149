import React, { useEffect, useState } from "react";
import { find, insert } from "../../api/data";
import { DataTables } from "../../api/dataTables";
import DataDefaultEvents from "./dataDefaultEvents";
import IsLoading from "../../components/common/isLoading";
import TypeAheadField from "../../components/forms/fields/typeAheadField";
import CheckBoxField from "../../components/forms/fields/checkBoxField";

const DefaultEventRows = ({ showLocationId, superLabelValueObjs, showNames, onAddShowName, showOldDefaultEvents }) => {
    if (showLocationId) {
        return <DataDefaultEvents
            showLocationId={showLocationId}
            superLabelValueObjs={superLabelValueObjs}
            showNames={showNames}
            onAddShowName={onAddShowName}
            showOldDefaultEvents={showOldDefaultEvents} />
    }

    return null;
}

export default function DataShows() {
    const [showLocations, setShowLocations] = useState([]);
    const [showLocationObjs, setShowLocationObjs] = useState([]);
    const [currentShowLocation, setCurrentShowLocation] = useState('');
    const [showLocationId, setShowLocationId] = useState('');
    const [superLabelValueObjs, setSuperLabelValueObjs] = useState([]);
    const [showNames, setShowNames] = useState([]);
    const [showOldDefaultEvents, setShowOldDefaultEvents] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const load = async () => {
            const showLocationObjs = (await find(DataTables.ShowLocations()));
            const supers = (await find(DataTables.Superintendents()));
            const showNames = (await find(DataTables.ShowNames()));

            supers.sort((a, b) => (a.name.toLowerCase().localeCompare(b.name.toLowerCase())));

            setShowLocationObjs(showLocationObjs);
            setShowLocations(showLocationObjs.map(({ name }) => (name)));
            setShowNames(showNames.map(({ name }) => (name)));
            setSuperLabelValueObjs([{ value: '', label: 'Choose a Superintendent' }].concat(supers.map(({ _id, name }) => ({ value: _id, label: name }))));
            setIsLoaded(true);
        }

        load();
    }, [showLocationId]);

    const loadShowInfo = (currentShowLocation) => {
        const showLocationObj = showLocationObjs.find(({ name }) => name === currentShowLocation);
        setCurrentShowLocation(currentShowLocation);

        if (showLocationObj) {
            setShowLocationId(showLocationObj._id);
        }
    }

    const onAdd = async (showLocation) => {
        const showLocationObj = await insert(DataTables.ShowLocations(), { name: showLocation });
        setCurrentShowLocation(showLocation);
        setShowLocationId(showLocationObj._id);
    }

    const onAddShowName = (showName) => {
        insert(DataTables.ShowNames(), { name: showName });
        setShowNames(showNames.concat([showName]));
    }

    const styleWidth = { width: '350px' };

    return (
        <IsLoading isLoaded={isLoaded}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ ...styleWidth }}>
                        <TypeAheadField
                            style={styleWidth}
                            onBlur={loadShowInfo}
                            onChange={() => { }}
                            onAdd={onAdd}
                            nextInputElementId='loadShows'
                            fieldName='Show Location (City, State)'
                            fieldProperty='location'
                            options={showLocations} />
                    </div>
                    <button
                        id='loadShows'
                        style={{ marginLeft: '16px' }}
                        onClick={() => loadShowInfo(currentShowLocation)}>
                        Load Shows
                    </button>
                </div>


                <div style={{ paddingTop: '18px' }}>
                    <CheckBoxField
                        onChange={(_, isChecked) => setShowOldDefaultEvents(isChecked)}
                        fieldName='Show Previous Shows'
                        fieldProperty='showOld'
                        defaultValue={showOldDefaultEvents} />
                </div>
            </div>

            <DefaultEventRows
                showLocationId={showLocationId}
                superLabelValueObjs={superLabelValueObjs}
                showNames={showNames}
                onAddShowName={onAddShowName}
                showOldDefaultEvents={showOldDefaultEvents} />
        </IsLoading>
    );
}