import React, { useState, useEffect } from "react";
import { find, insert, remove, update } from '../api/data';
import { DataTables } from "../api/dataTables";
import { useHistory } from "react-router-dom";
import { hasSessionStorage, loadSessionStorage } from "../api/dataHelper";
import IsLoading from '../components/common/isLoading';
import CustomerForm from "../components/forms/customerForm";
import DogForm from '../components/forms/dogForm';
import OwnerForm from "../components/forms/ownerForm";
import DropDownField from "../components/forms/fields/dropDownField";

const NewDogObj = JSON.stringify({
    registrationNumber: '',
    breed: '',
    dogName: '',
    callName: '',
    whelpDate: '',
    placeOfBirth: '',
    breeders: '',
    sire: '',
    dam: '',
    registrationType: '',
    sex: '',
    variety: '',
    owner: {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone1: '',
        phone2: '',
        email: '',
        comments: '',
        agentName: ''
    }
});

const DogFormParent = ({ dogObj, countries, states, breeds, onDogObjChange, onDogBlur, onDogCopy, onDogPaste, dogPasteDisabled, onOwnerCopy, onOwnerPaste, ownerPasteDisabled, onDeleteDog }) => {
    const onOwnerObjChange = (ownerObj) => {
        onDogObjChange({
            ...dogObj,
            owner: ownerObj
        })
    };

    if (dogObj) {
        return (
            <div key={dogObj._id} style={{ marginBottom: '16px' }}>
                <h3 style={{ margin: '0', textAlign: 'center' }}>{dogObj.callName || 'New Dog'}</h3>
                <div className='dog-owner-grouping'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button onClick={() => onDeleteDog(dogObj)} disabled={!dogObj._id}>Delete Dog</button>
                        <div style={{ display: 'flex', justifyContent: 'right' }}>
                            <button onClick={() => onDogCopy(dogObj)} disabled={!dogObj._id}>Copy Dog Information</button>
                            <button onClick={() => onDogPaste(dogObj._id)} disabled={dogPasteDisabled}>Paste Dog Information</button>
                        </div>
                    </div>

                    <DogForm
                        dogObj={dogObj}
                        countries={countries}
                        breeds={breeds}
                        onDogObjChange={onDogObjChange}
                        onBlur={() => onDogBlur && onDogBlur(dogObj._id)}
                        disabled={false} />

                    <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '-20px' }}>
                        <button onClick={() => onOwnerCopy(dogObj.owner)} disabled={!dogObj._id}>Copy Owner Information</button>
                        <button onClick={() => onOwnerPaste(dogObj._id)} disabled={ownerPasteDisabled}>Paste Owner Information</button>
                    </div>
                    <OwnerForm
                        onOwnerObjChange={onOwnerObjChange}
                        ownerObj={dogObj.owner}
                        countries={countries}
                        states={states}
                        onBlur={() => onDogBlur && onDogBlur(dogObj._id)}
                        disabled={false} />
                </div>
            </div>

        );
    }

    return null;
}

export default function Customer(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [customerObj, setCustomerObj] = useState({});
    const [filterDogId, setFilterDogId] = useState('');
    const [dogObjs, setDogObjs] = useState([]);
    const [newDogObj, setNewDogObj] = useState(JSON.parse(NewDogObj));
    const [copyOwnerObj, setCopyOwnerObj] = useState(undefined);
    const [copyDogObj, setCopyDogObj] = useState(undefined);
    const history = useHistory();
    const { customerid } = props.match.params;

    useEffect(() => {
        const load = async () => {
            const customer = (await find(DataTables.Customers(), { _id: customerid }))[0];

            if (customer.dogIds && customer.dogIds.length > 0) {
                const dogs = await find(DataTables.Dogs(), { _id: { $in: customer.dogIds.filter(dogId => !!dogId) } });
                setDogObjs(dogs);
            }

            setCustomerObj(customer);
            setIsLoaded(true);
        }

        if (customerid === 'new') {
            setIsLoaded(true);
        } else {
            load();
        }
        
    }, [customerid]);

    useEffect(() => {
        const load = async () => await loadSessionStorage();

        if (!hasSessionStorage()) {
            load();
        }
    }, []);

    const onCustomerChange = (customerObj) => setCustomerObj(customerObj);

    const onCustomerBlur = () => update(DataTables.Customers(), { _id: customerObj._id }, customerObj);

    const onDogObjChange = (dogObj) => {
        setDogObjs(dogObjs.map(dog => dog._id === dogObj._id ? dogObj : dog));
    }

    const onNewDogObjChange = (dogObj) => setNewDogObj(dogObj);

    const onDogBlur = (dogId) => {
        const save = async () => {
            const dogObj = dogObjs.find(({ _id }) => _id === dogId);

            if (dogObj._id === '') {
                delete dogObj._id;
                const newDogObj = await insert(DataTables.Dogs(), dogObj);
                customerObj.dogIds.push(newDogObj._id);
                update(DataTables.Customers(), { _id: customerObj._id }, customerObj);

                setDogObjs(dogObjs.reduce(((acc, dog) => {
                    if (!dog._id) {
                        acc.push(newDogObj);
                        acc.push(JSON.parse(NewDogObj));
                    } else {
                        acc.push(dog);
                    }
                    return acc;
                }), []));

            } else {
                update(DataTables.Dogs(), { _id: dogId }, dogObj);
            }
        }

        save();
    }

    const onAddNewDog = () => {
        const save = async () => {
            const dogObj = await insert(DataTables.Dogs(), newDogObj);

            if (!customerObj.dogIds) {
                customerObj.dogIds = [];
            }

            customerObj.dogIds.push(dogObj._id);
            update(DataTables.Customers(), { _id: customerObj._id }, customerObj);

            setNewDogObj(undefined);
            setNewDogObj(JSON.parse(NewDogObj));
            setDogObjs(dogObjs.concat(dogObj));
        }

        save();
    }

    const onOwnerCopy = (ownerObj) => setCopyOwnerObj(JSON.parse(JSON.stringify(ownerObj)));
    const onOwnerPaste = (dogId) => {
        if (dogId) {
            const dogObj = dogObjs.find(({ _id }) => _id === dogId);
            dogObj.owner = copyOwnerObj;
            update(DataTables.Dogs(), { _id: dogId }, dogObj);
            setDogObjs(dogObjs);
        } else {
            setNewDogObj({
                ...newDogObj,
                owner: copyOwnerObj
            });
        }

        setCopyOwnerObj(undefined);
    }

    const onDogCopy = (dogObj) => setCopyDogObj(JSON.parse(JSON.stringify(dogObj)));
    const onDogPaste = (dogId) => {
        if (dogId) {
            const index = dogObjs.findIndex(({ _id }) => _id === dogId);
            copyDogObj._id = dogId;
            dogObjs[index] = copyDogObj;
            update(DataTables.Dogs(), { _id: dogId }, dogObjs[index]);
            setDogObjs(dogObjs);
        } else {
            delete copyDogObj._id;
            setNewDogObj(copyDogObj)
        }

        setCopyDogObj(undefined);
    }

    const onDeleteDog = async ({ _id }) => {
        await remove(DataTables.Dogs(), { _id });
        customerObj.dogIds = customerObj.dogIds.filter(dogId => dogId !== _id);
        await update(DataTables.Customers(), { _id: customerObj._id }, customerObj);

        setDogObjs(dogObjs.filter((dog) => dog._id !== _id));
    }

    const disableNewDogButton = () => {
        return !newDogObj || !(newDogObj.registrationNumber &&
            newDogObj.breed &&
            newDogObj.dogName &&
            newDogObj.callName &&
            newDogObj.whelpDate);
    }

    const filterDogObj = dogObjs.find(({ _id }) => !!_id && _id === filterDogId);

    const dogLabelValues = [{label: ' Pick a Dog to Edit', value: ''}].concat(dogObjs.filter(({ _id }) => _id !== '').map(dog => ({ label: dog.callName, value: dog._id })));
    dogLabelValues.sort((a, b) => (a.label.toLowerCase().localeCompare(b.label.toLowerCase())));

    return (
        <IsLoading isLoaded={isLoaded && hasSessionStorage()}>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
                <button onClick={() => history.push(`/customer/entries/${customerid}`)}>Entries Report</button>
                <button onClick={() => history.push(`/entryforms/${customerid}`)}>Entry Forms</button>
            </div>

            <CustomerForm customerObj={customerObj}
                onCustomerChange={onCustomerChange}
                onCustomerBlur={onCustomerBlur} />

            <DogFormParent id='newDogForm' dogObj={newDogObj}
                countries={JSON.parse(sessionStorage.countries)}
                states={JSON.parse(sessionStorage.states)}
                breeds={JSON.parse(sessionStorage.breeds)}
                onDogObjChange={onNewDogObjChange}
                onDogCopy={onDogCopy}
                onDogPaste={onDogPaste}
                dogPasteDisabled={copyDogObj === undefined}
                onOwnerCopy={onOwnerCopy}
                onOwnerPaste={onOwnerPaste}
                ownerPasteDisabled={copyOwnerObj === undefined}
                onDeleteDog={onDeleteDog} />

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <button onClick={onAddNewDog} disabled={disableNewDogButton()}>Add New Dog</button>
            </div>

            {dogObjs && dogObjs.length > 0 && (
                <DropDownField
                    fieldName='Choose a Dog'
                    fieldProperty='dogPicker'
                    labelValueObjs={dogLabelValues}
                    defaultValue={filterDogId}
                    onChange={(_, dogId) => setFilterDogId(dogId)} />
            )}

            <DogFormParent id='dogForm' dogObj={filterDogObj}
                countries={JSON.parse(sessionStorage.countries)}
                states={JSON.parse(sessionStorage.states)}
                breeds={JSON.parse(sessionStorage.breeds)}
                onDogObjChange={onDogObjChange}
                onDogBlur={onDogBlur}
                onDogCopy={onDogCopy}
                onDogPaste={onDogPaste}
                dogPasteDisabled={copyDogObj === undefined}
                onOwnerCopy={onOwnerCopy}
                onOwnerPaste={onOwnerPaste}
                ownerPasteDisabled={copyOwnerObj === undefined}
                onDeleteDog={onDeleteDog} />

        </IsLoading>
    )
}