import React from 'react';
//Retrievers (Nova Scotia Duck Tolling)
export default function DropDownField({ onChange, onBlur, fieldName, fieldProperty, labelValueObjs, defaultValue, disabled }) {

    if (fieldName) {
        return (
            <div className='container input-container'>
                <label htmlFor={fieldProperty}>{fieldName}</label>
                <div>
                    <select 
                        id={fieldProperty}
                        value={defaultValue}
                        disabled={disabled}
                        onChange={(e) => onChange && onChange(fieldProperty, e.target.value)}
                        onBlur={() => onBlur && onBlur()}>
                        {labelValueObjs.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
                    </select>
                </div>
            </div>
        );
    }

    return (
        <select 
            style={{width: '100%'}} 
            id={fieldProperty}
            value={defaultValue}
            disabled={disabled}
            onChange={(e) => onChange && onChange(fieldProperty, e.target.value)}
            onBlur={() => onBlur && onBlur()}>
            {labelValueObjs.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
        </select>
    );
}