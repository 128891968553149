import React from "react";
import Filter from "./Filter";

export default function FilterHeader({ title, setFilterText, numerator, denominator }) {

    return (
        <div style={{ marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
            <Filter setFilterText={setFilterText} />
            <h3 style={{ marginTop: '16px' }}>{title} ({numerator} of {denominator})</h3>
            <div />
        </div>
    );
}