import React from 'react';
import PropTypes from 'prop-types';
import ClubEntryReportEvents from './clubEntryReportEvents';
import ClubEntryReportNote from './clubEntryReportNote';
import ClubEntryReportTextBox from './clubEntryReportTextBox';
import ClubEntryReportHorizontalLabelTextBox from './clubEntryReportHorizontalLabelTextBox';
import ClubEntryReportDogId from './clubEntryReportDogId';
import ClubEntryReportPlaceOfBirth from './clubEntryReportPlaceOfBirth';
import ClubEntryReportChangeSection from './clubEntryReportChangeSection';
import ClubEntryReportCheckBox from './clubEntryReportCheckBox';
import DateHelper from '../../lib/common/dateHelper';
import SignaturePicker from './signatures/SignaturePicker';
import { Helper } from '../../lib/common/helper';

const getJumpHeightText = (classTitle, jumpHeight) => {
  if (jumpHeight) {
    return `${classTitle || ''} (${jumpHeight})`;
  }

  return classTitle || '';
}

const getState = (ownerObj) => {
  if (!ownerObj.country || ownerObj.country === 'United States') {
    return ownerObj.state;
  }

  if (ownerObj.foreignState) {
    return ownerObj.country ? `${ownerObj.foreignState} (${ownerObj.country})` : ownerObj.foreignState;
  }

  return ownerObj.country;
}

export default function ClubEntryReportLeft({ entryForm, events, handler, defaultClassFee = 0 }) {
  const { dogObj = {}, showObj = {}, jrName = '', ownerObj = {} } = entryForm;
  const borderStyle = 'solid black 1px';
  const rowStyle = { borderLeft: borderStyle, borderRight: borderStyle, borderBottom: borderStyle, display: 'flex' };

  return (
    <div>
      <div style={{ textAlign: 'center', borderBottom: 'solid 1px black', fontSize: '12px', fontWeight: '800' }}>
        OFFICIAL AMERICAN KENNEL CLUB ENTRY FORM
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', height: '80px' }}>
        <ClubEntryReportEvents events={events} />
      </div>
      <ClubEntryReportNote note={showObj.note} callName={dogObj.callName} total={Helper.getEntryAmount(events, defaultClassFee)} />
      <div style={{ border: borderStyle, display: 'flex' }}>
        <ClubEntryReportTextBox label='BREED' value={dogObj.breed} width='60%' borderStyle={{ borderRight: borderStyle }} />
        <ClubEntryReportTextBox label='VARIETY (1)' value={dogObj.variety} width='25%' borderStyle={{ borderRight: borderStyle }} />
        <ClubEntryReportTextBox label='SEX' value={dogObj.sex} width='15%' labelWidth='20px' />
      </div>
      <div style={rowStyle}>
        <ClubEntryReportTextBox label='DOG (2) (3) SHOW CLASS' value={showObj.showClass} width='55%' borderStyle={{ borderRight: borderStyle }} />
        <ClubEntryReportTextBox label='CLASS (3) DIVISION Weight, Color, etc.' value={dogObj.classDivision} width='45%' labelWidth='65px' />
      </div>
      <div style={{ ...rowStyle, borderBottom: 'none' }}>
        <ClubEntryReportTextBox label='ADDITIONAL CLASSES' value={showObj.additionalClasses} width='28%' borderStyle={{ borderRight: borderStyle }} />
        <ClubEntryReportHorizontalLabelTextBox label='OBEDIENCE CLASS (Jump Height)' value={getJumpHeightText(showObj.obedienceTrialClass, showObj.obedienceJumpHeight)} width='30%' borderStyle={{ borderRight: borderStyle }} />
        <ClubEntryReportHorizontalLabelTextBox label='RALLY CLASS (Jump Height)' value={getJumpHeightText(showObj.rallyTrialClass, showObj.rallyJumpHeight)} width='25%' borderStyle={{ borderRight: borderStyle }} />
        <ClubEntryReportHorizontalLabelTextBox label='JR SHOWMAN CLASS' value={showObj.jrShowmanClass} width='17%' labelWidth='55px' />
      </div>
      <div style={{ border: borderStyle }}>
        <ClubEntryReportTextBox label='NAME OF JUNIOR HANDLER' value={jrName} width='100%' />
      </div>
      <div style={rowStyle}>
        <ClubEntryReportTextBox label='FULL NAME OF DOG' value={dogObj.dogName} width='100%' />
      </div>
      <div style={rowStyle}>
        <ClubEntryReportDogId dogObj={dogObj} />

        <div style={{ width: '50%' }}>
          <div style={{ borderBottom: borderStyle }}>
            <ClubEntryReportTextBox label='DATE OF BIRTH' value={DateHelper.numberToFormattedDate(dogObj.whelpDate)} width='100%' borderStyle={{ borderLeft: borderStyle }} />
          </div>
          <ClubEntryReportPlaceOfBirth isUsa={dogObj.placeOfBirth === 'usa'} isCanada={dogObj.placeOfBirth === 'canada'} isForeign={dogObj.placeOfBirth === 'foreign'} />
        </div>
      </div>
      <div style={rowStyle}>
        <ClubEntryReportTextBox label='BREEDER' value={dogObj.breeders} width='100%' />
      </div>
      <div style={rowStyle}>
        <ClubEntryReportTextBox label='SIRE' value={dogObj.sire} width='100%' />
      </div>
      <div style={rowStyle}>
        <ClubEntryReportTextBox label='DAM' value={dogObj.dam} width='100%' />
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '80%' }}>
          <div style={rowStyle}>
            <ClubEntryReportTextBox label='ACTUAL (4) OWNERS' value={ownerObj.name} width='100%' />
          </div>
          <div style={{ ...rowStyle, borderBottom: '' }}>
            <ClubEntryReportTextBox label='OWNERS ADDRESS' value={ownerObj.address} width='100%' />
          </div>
        </div>
        <div style={{ width: '20%', padding: '2px 4px 0 4px' }}>
          <ClubEntryReportChangeSection isOwnerChange={ownerObj.isOwnerChange} isAddressChange={ownerObj.isAddressChange} />
        </div>
      </div>
      <div style={{ border: borderStyle, display: 'flex' }}>
        <ClubEntryReportTextBox label='CITY' value={ownerObj.city} width='40%' />
        <ClubEntryReportTextBox label='STATE' value={getState(ownerObj)} width='35%' />
        <ClubEntryReportTextBox label='ZIP' value={ownerObj.zip} width='25%' />
      </div>
      <div style={rowStyle}>
        <ClubEntryReportTextBox label="NAME OF OWNER'S AGENT (IF ANY) AT THE SHOW" value={handler} labelWidth='117px' width='100%' valueFontSize='7px' />
      </div>
      <div style={{ fontSize: '6px' }}>
        I CERTIFY that I am the actual owner of the dog, or that I am the duly authorized agent of the actual owner whose name I have entered above. In
        consideration of the acceptance of the entry, I (we) agree to abide by the rules and regulations of The American Kennel Club in effect at the time of
        this show or obedience trial, and by any additional rules and regulations appearing in the premium list for this show or obedience trial or both, and
        futher agree to be bound by the Agreement printed on the reverse side of this entry form, I (we) certify and represent that the dog entered is not a
        hazard to persons or other dogs. This entry is submitted for acceptance on the foregoing representation and agreement.
      </div>
      <div style={{ display: 'flex', margin: '4px 0' }}>
        <div style={{ width: '32%', fontSize: '10px', margin: '0 4%' }}>
          SIGNATURE of owner or his agent duly authorized to make this entry
        </div>
        <div style={{ width: '60%', borderBottom: borderStyle }}>
          <SignaturePicker clientId={sessionStorage.clientId} />
        </div>
      </div>
      <div style={{ border: borderStyle, display: 'flex' }}>
        <ClubEntryReportTextBox label='TELEPHONE #1' value={ownerObj.phone1} width='50%' labelWidth='80px' borderStyle={{ borderRight: borderStyle }} />
        <ClubEntryReportTextBox label='TELEPHONE #2' value={ownerObj.phone2} width='50%' labelWidth='80px' />
      </div>
      <div style={rowStyle}>
        <ClubEntryReportTextBox label='E-MAIL ADDRESS' value={ownerObj.email} width='100%' labelWidth='100px' />
      </div>
      <div style={{ marginTop: '4px', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end' }}>
          <ClubEntryReportCheckBox label='Owner/HandlerEligible' isChecked={showObj.isOwnerHandlerEligible} labelStyle={{ fontSize: '12px' }} />
        </div>
        <div style={{ fontSize: '10px' }}>#4214</div>
      </div>
    </div>
  );
}

ClubEntryReportLeft.propTypes = {
  entryForm: PropTypes.object,
  events: PropTypes.array
}