import React, { useState } from 'react';
import DogForm from './dogForm';
import OwnerForm from './ownerForm';
import EventsForm from './eventsForm';
import ShowForm from './showForm';
import JuniorForm from './juniorForm';
import DropDownField from './fields/dropDownField';
import DefaultEventPicker from '../defaultEventPicker';

export default function ClubEntryForm({ availableDogObjs, entryForm, events, defaultClassFee, onChangeEntryForm, onEventsChange, onDeleteEvent, superintendents, breeds, classes, obedienceClasses, jrClasses, countries, states, onBlur, onBlurEvents, onAddDefaultEvents, hasPaid }) {
    const [defaultEventPickerDisabled, setDefaultEventPickerDisabled] = useState(false);

    const onDogObjChange = (dogObj, dogId) => {
        if (dogId) {
            dogObj = availableDogObjs.find(({ _id }) => _id === dogId);
        }

        if (dogObj) {
            const newEntryForm = {
                ...entryForm,
                dogObj,
                ownerObj: dogObj.owner
            };

            onChangeEntryForm(newEntryForm);
            onBlur(newEntryForm)
        }
    };

    const onOwnerObjChange = (ownerObj) => {
        onChangeEntryForm({
            ...entryForm,
            ownerObj
        });
    };

    const onShowObjChange = (showObj) => {
        onChangeEntryForm({
            ...entryForm,
            showObj
        });
    };

    const onJuniorObjChange = (juniorObj) => {
        onChangeEntryForm({
            ...entryForm,
            juniorObj
        });
    };

    const onDefaultEventsSelected = async (defaultEvents) => {
        const newEvents = defaultEvents.map(({ showDate, location, superintendentId, showName, regularFee, nonRegularFee }) => ({
            location,
            name: showName,
            showDate,
            superintendentId,
            regularFee,
            nonRegularFee,
            feeAmount: sessionStorage.defaultFee,
            show: true
        }));

        setDefaultEventPickerDisabled(true);
        await onAddDefaultEvents(newEvents);
        setDefaultEventPickerDisabled(false);
    };

    const getFees = () => {
        if (!events || events.length === 0) {
            return { regularFee: 0, nonRegularFee: 0 };
        }

        return events[0];
    }

    const availableDogLabelValueObjs = availableDogObjs.map(({ _id, callName, dogName }) => ({ value: _id, label: `${callName || 'No Registration Number'} - ${dogName || 'No Dog Name'}` }));
    availableDogLabelValueObjs.sort((a, b) => (a.label.toLowerCase().localeCompare(b.label.toLowerCase())));

    const labelValueObjs = [{ value: 0, label: 'Choose a Dog' }].concat(availableDogLabelValueObjs);
    const { regularFee, nonRegularFee } = getFees();

    return (
        <div className='container no-print'>
            <div>
                <DefaultEventPicker
                    onDefaultEventsSelected={onDefaultEventsSelected}
                    disabled={defaultEventPickerDisabled} />

                <EventsForm
                    onEventsChange={onEventsChange}
                    events={events}
                    defaultClassFee={defaultClassFee}
                    superintendents={superintendents}
                    onBlur={onBlurEvents}
                    onDeleteEvent={onDeleteEvent}
                    disabled={hasPaid} />

                <ShowForm
                    onShowObjChange={onShowObjChange}
                    showObj={entryForm.showObj}
                    regularFee={regularFee}
                    nonRegularFee={nonRegularFee}
                    classes={classes}
                    obedienceClasses={obedienceClasses}
                    jrClasses={jrClasses}
                    onBlur={onBlur}
                    disabled={hasPaid} />

                <DropDownField
                    onChange={onDogObjChange}
                    fieldName='Available Dogs'
                    fieldProperty='availableDogId'
                    labelValueObjs={labelValueObjs}
                    disabled={hasPaid} />

                <DogForm
                    key={`dog-${entryForm.dogObj.registrationNumber}`}
                    onDogObjChange={onDogObjChange}
                    dogObj={entryForm.dogObj}
                    breeds={breeds}
                    countries={countries}
                    onBlur={onBlur}
                    disabled={hasPaid} />

                <OwnerForm
                    key={`owner-${entryForm.dogObj.registrationNumber}`}
                    onOwnerObjChange={onOwnerObjChange}
                    ownerObj={entryForm.ownerObj}
                    countries={countries}
                    states={states}
                    onBlur={onBlur}
                    disabled={hasPaid} />

                <JuniorForm
                    onJuniorObjChange={onJuniorObjChange}
                    juniorObj={entryForm.juniorObj}
                    onBlur={onBlur}
                    disabled={hasPaid} />
            </div>
        </div>
    );
}