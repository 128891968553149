import React from 'react';

const Address2Row = ({ styleObj, address2 }) => {
    if (address2) {
        <div style={styleObj}>{address2}</div>
    }

    return null;
};

const ClassCell = ({ showObj }) => {
    const classes = [];
    if (showObj.showClass) {
        classes.push(showObj.showClass);
    }
    if (showObj.additionalClasses) {
        classes.push(showObj.additionalClasses);
    }
    if (showObj.obedienceTrialClass) {
        classes.push(showObj.obedienceTrialClass);
    }
    if (showObj.rallyTrialClass) {
        classes.push(showObj.rallyTrialClass);
    }
    if (showObj.jrShowmanClass) {
        classes.push(showObj.jrShowmanClass);
    }

    return (
        <td>
            {classes.join(', ')}
        </td>
    );
}

const EventRows = ({ events, entryForms }) => {
    return events.map((eventObj, index) => {
            const { dogObj, showObj } = entryForms.find(({ _id }) => _id === eventObj.entryFormId);

            if (dogObj && showObj) {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{dogObj.registrationNumber}</td>
                        <td>{dogObj.breed}</td>
                        <ClassCell showObj={showObj} />
                        <td>{eventObj.name}</td>
                        <td style={{ textAlign: 'right' }}>${eventObj.amount.toFixed(2).toLocaleString()}</td>
                    </tr>
                );
            }

            return null;
        });
};

const TotalRow = ({ events, defaultClassFee }) => {
    const totalAmount = events.reduce(((acc, event) => {
        acc += event.amount || defaultClassFee;
        return acc;
    }), 0);

    return (
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: 'right', fontWeight: '800' }}>Total:</td>
            <td style={{ textAlign: 'right', fontWeight: '800' }}>${totalAmount.toFixed(2).toLocaleString()}</td>
        </tr>
    );
};

export default function Summary({ telephoneEntry, superintendent, events, defaultClassFee = 0, entryForms }) {
    const pageStyle = { margin: '0.5in', width: '10in', pageBreakAfter: 'always' };
    const leftStyle = { textAlign: 'left' };
    const centerStyle = { textAlign: 'center' };
    const rightStyle = { textAlign: 'right' };
    const titleStyle = { fontSize: '24px', marginBottom: '16px' };
    const subTitleStyle = { fontSize: '18px', marginBottom: '4px' };
    const containerStyle = { marginBottom: '16px' };
    const superintendentContainerStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: '8px' };
    const thStyle = { color: 'black', backgroundColor: 'lightgray', border: '1px solid darkgray' };

    return (
        <div style={pageStyle}>
            <div style={containerStyle}>
                <div style={{ ...centerStyle, ...titleStyle }}>{telephoneEntry.companyName}</div>
                <div style={{ ...centerStyle, ...subTitleStyle }}>{telephoneEntry.address1}</div>
                <Address2Row styleObj={{ ...centerStyle, ...subTitleStyle }} address2={telephoneEntry.address2} />
                <div style={{ ...centerStyle, ...subTitleStyle }}>{telephoneEntry.city}, {telephoneEntry.state} {telephoneEntry.zip}</div>
            </div>
            <div style={superintendentContainerStyle}>
                <div>
                    <div>Superintendent</div>
                    <div>{superintendent.name}</div>
                    <div>{superintendent.phone}</div>
                    <div>{superintendent.email}</div>
                </div>
                <div>
                    <div>Superintendent Address</div>
                    <div>{superintendent.address1}</div>
                    <Address2Row address2={superintendent.address2} />
                    <div>{superintendent.city}, {superintendent.state} {superintendent.zip}</div>
                </div>
            </div>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ ...thStyle, ...leftStyle }}>No.</th>
                        <th style={{ ...thStyle, ...leftStyle }}>AKC #</th>
                        <th style={{ ...thStyle, ...leftStyle }}>Breed</th>
                        <th style={{ ...thStyle, ...leftStyle }}>Class</th>
                        <th style={{ ...thStyle, ...leftStyle }}>Show</th>
                        <th style={{ ...thStyle, ...rightStyle }}>Price</th>
                    </tr>
                </thead>
                <tbody>
                    <EventRows events={events} entryForms={entryForms} />
                    <TotalRow events={events} defaultClassFee={defaultClassFee} />
                </tbody>
            </table>
        </div>
    );
}