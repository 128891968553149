import React, { useState, useEffect } from 'react';
import { find } from '../api/data';
import { DataTables } from '../api/dataTables';
import CustomerSelector from '../components/customerSelector';
import { useHistory } from "react-router-dom";
import IsLoading from '../components/common/isLoading';
import { newEntryForm } from "../api/dataHelper";

export default function Home() {
  const [customers, setCustomers] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getCustomers = async () => {
      const customers = await find(DataTables.Customers(), {}, {_id: 1, firstName: 1, lastName: 1});

      customers.sort((a, b) => {
        if (a.firstName !== b.firstName) {
          return (a.firstName > b.firstName) - (a.firstName < b.firstName);
        }
    
        if (a.lastName !== b.lastName) {
          return (a.lastName > b.lastName) - (a.lastName < b.lastName);
        }
    
        return (a.email > b.email) - (a.email < b.email);
      });

      setCustomers(customers);
      setIsLoaded(true);
    }
    getCustomers();
  }, []);

  const onAdd = () => {
    history.push('/customer/new');
  }

  const onSelect = (customerId) => {
    history.push(`/customer/${customerId}`);
  }

  const onEntryForm = (customerId) => {
    history.push(`/entryforms/${customerId}`);
  }

  const onNewEntryForm = (customerId) => {
    newEntryForm(customerId, history);
  }

  return (
    <IsLoading isLoaded={isLoaded}>
      <CustomerSelector customers={customers} onAdd={onAdd} onSelect={onSelect} onEntryForm={onEntryForm} onNewEntryForm={onNewEntryForm} />
    </IsLoading>
  );
}