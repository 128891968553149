import React from 'react';
import PropTypes from 'prop-types';

export default function ClubEntryReportTextBox({label, value, width, labelWidth, borderStyle, valueFontSize}) {
  let height = '26px';
  let style = {
    padding: '2px',
    height: height, 
    display: 'flex', 
    marginRight: '4px',
    width: width,
    ...borderStyle
  }

  return (
    <div style={style}>
      <div style={{width: labelWidth || '50px', height: height, fontSize: '7px', verticalAlign: 'sub'}}>
        {label}
      </div>
      <div style={{height: height, display: 'flex'}}>
        <span style={{fontSize: valueFontSize || '12px', alignSelf: 'flex-end'}}>{value}</span> 
      </div>
    </div>
  );
}

ClubEntryReportTextBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  labelWidth: PropTypes.string,
  borderStyle: PropTypes.object,
  valueFontSize: PropTypes.string
}