import React, { useState } from 'react';

const FilteredRow = ({ isActive, text, filterText }) => {
    if (filterText === '') {
        return <li className={isActive ? 'active' : ''}>{text}</li>
    }
    const startIndex = text.toLowerCase().indexOf(filterText.toLowerCase());
    const endIndex = startIndex + filterText.length;

    if (startIndex === 0) {
        return (
            <li className={isActive ? 'active' : ''}><strong>{text.substring(0, filterText.length)}</strong>{text.substring(filterText.length)}</li>
        );
    } else if (endIndex === text.length) {
        return (
            <li className={isActive ? 'active' : ''}>{text.substring(0, startIndex)}<strong>{text.substring(startIndex)}</strong></li>
        );
    }

    return (
        <li className={isActive ? 'active' : ''}>{text.substring(0, startIndex)}<strong>{text.substring(startIndex, endIndex)}</strong>{text.substring(endIndex)}</li>
    );
}

const FilteredRows = ({ filteredOptions, filterText, activeIndex, canAdd }) => {
    if (filteredOptions.length === 0) {
        return <FilteredRow key='new' isActive={true} text={canAdd ? 'Press Return to add' : 'No Matches'} filterText='' />
    }

    return filteredOptions.map((option, index) => <FilteredRow key={index} isActive={index === activeIndex} text={option} filterText={filterText} />)
}

const TypeAheadInput = ({ style, fieldProperty, defaultValue, maxLength, onAdd, onChange, onBlur, filterText, nextInputElementId, filteredOptions, setHasFocus, hasFocus, setFilterText }) => {
    const [forceRender, setForceRender] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const ulStyle = {
        position: 'absolute',
        width: '100%',
        zIndex: '99',
        maxHeight: '176px',
        overflowY: 'scroll'
    };

    if (!hasFocus) {
        ulStyle.display = 'none';
    }

    return (
        <div style={{ position: 'relative' }}>
            <input
                style={style}
                id={fieldProperty}
                type='text'
                defaultValue={defaultValue}
                maxLength={maxLength}
                autoComplete='off'
                onKeyDown={(e) => {
                    const keyStroke = e.key.toLowerCase();

                    if (keyStroke === 'enter' || keyStroke === 'tab') {
                        if (filteredOptions.length > 0) {
                            document.getElementById(fieldProperty).value = filteredOptions[activeIndex];
                            onChange && onChange(fieldProperty, filteredOptions[activeIndex]);
                        } else {
                            onAdd && onAdd(filterText);
                        }

                        if (nextInputElementId && keyStroke === 'enter') {
                            document.getElementById(nextInputElementId).focus();
                        }
                    } else if (keyStroke === 'arrowdown') {
                        setActiveIndex(activeIndex + 1 < filteredOptions.length ? activeIndex + 1 : activeIndex);
                    } else if (keyStroke === 'arrowup') {
                        setActiveIndex(activeIndex - 1 > -1 ? activeIndex - 1 : activeIndex);
                    } else if (keyStroke === 'escape') {
                        setForceRender(!forceRender);
                        setHasFocus(false);
                        document.activeElement.blur();
                    } else {
                        setActiveIndex(0);
                    }
                }}
                onFocus={() => {
                    setHasFocus(true);
                }}
                onBlur={(e) => {
                    setForceRender(!forceRender);
                    setHasFocus(false);
                    onBlur && onBlur(e.target.value);
                }}
                onChange={(e) => {
                    setFilterText(e.target.value);
                    setHasFocus(true);
                    onChange && onChange(fieldProperty, e.target.value);
                }} />
            <ul style={ulStyle}>
                <FilteredRows filteredOptions={filteredOptions} filterText={filterText} activeIndex={activeIndex} canAdd={!!onAdd} />
            </ul>
        </div>
    );
}

export default function TypeAheadField({ onChange, onBlur, onAdd, fieldName, fieldProperty, defaultValue, style, options, nextInputElementId, maxOptions = 10, maxLength = 500 }) {
    const [filterText, setFilterText] = useState('');
    const [hasFocus, setHasFocus] = useState(document.activeElement.id === fieldProperty);
    const filteredOptions = options.filter(option => filterText === '' || option.toLowerCase().indexOf(filterText.toLowerCase()) > -1);

    filteredOptions.sort((a, b) => (a.toLowerCase().localeCompare(b.toLowerCase())));

    if (fieldName) {
        return (
            <div className='container input-container'>
                <label htmlFor={fieldProperty}>{fieldName} <i>{hasFocus ? `(Press Return to ${filteredOptions.length === 0 ? 'Add' : 'Accept'})` : ''}</i></label>
                <TypeAheadInput
                    style={style}
                    fieldProperty={fieldProperty}
                    defaultValue={defaultValue}
                    maxLength={maxLength}
                    onAdd={onAdd}
                    onChange={onChange}
                    onBlur={onBlur}
                    filterText={filterText}
                    nextInputElementId={nextInputElementId}
                    filteredOptions={filteredOptions}
                    setHasFocus={setHasFocus}
                    hasFocus={hasFocus}
                    setFilterText={setFilterText} />
            </div>
        );
    }

    return (
        <TypeAheadInput
            style={style}
            fieldProperty={fieldProperty}
            defaultValue={defaultValue}
            maxLength={maxLength}
            onAdd={onAdd}
            onChange={onChange}
            onBlur={onBlur}
            filterText={filterText}
            nextInputElementId={nextInputElementId}
            filteredOptions={filteredOptions}
            setHasFocus={setHasFocus}
            hasFocus={hasFocus}
            setFilterText={setFilterText} />
    );
}