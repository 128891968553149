import React, { useState, useEffect } from 'react';
import { find, insert } from '../api/data';
import { DataTables } from '../api/dataTables';
import ReadOnlyTable from '../components/common/readOnlyTable/readOnlyTable';
import ReadOnlyFilterWithOptionsHeader from '../components/common/readOnlyTable/readOnlyFilterWithOptionsHeader';
import { filterRows } from '../components/common/readOnlyTable/readOnlyFilterHelper';
import { useHistory } from 'react-router-dom';
import DateHelper from '../lib/common/dateHelper';
import IsLoading from '../components/common/isLoading';
import {newEntryForm} from '../api/dataHelper';

const schemas = [{
    sortOrder: 10,
    columnName: 'Call Name',
    propName: 'callName',
    type: 'text',
    style: { width: '10%' },
    getCellStyle: ({ isComplete }) => ({ opacity: isComplete ? '0.3' : '1' })
}, {
    sortOrder: 20,
    columnName: 'Dog Name',
    propName: 'dogName',
    type: 'text',
    style: { width: '30%' },
    getCellStyle: ({ isComplete }) => ({ opacity: isComplete ? '0.3' : '1' })
}, {
    sortOrder: 30,
    columnName: 'Breed',
    propName: 'breed',
    type: 'text',
    style: { width: '20%' },
    getCellStyle: ({ isComplete }) => ({ opacity: isComplete ? '0.3' : '1' })
}, {
    sortOrder: 40,
    columnName: 'Events',
    propName: 'events',
    type: 'array',
    render: ({ name, date, superintendent, entryFees, isComplete }) => {
        const style = { opacity: isComplete ? '0.3' : '1' };
        return <span style={style}>{`${name} - ${DateHelper.numberToFormattedDate(date)} - ${superintendent} - $${(entryFees || 0).toFixed(2).toLocaleString()}`}</span>
    },
    style: { width: '40%' }
}, {
    sortOrder: 50,
    columnName: 'Select',
    propName: 'select',
    type: 'action',
    render: () => 'Select'
}, {
    sortOrder: 60,
    columnName: 'Copy',
    propName: 'copy',
    type: 'action',
    render: () => 'Copy'
}];

const submittedFields = [
    { label: 'Not Submitted Only', value: 'notSubmitted' },
    { label: 'Submitted Only', value: 'submitted' }
];

const load = async (customerId, setEntryForms, setEvents, setCustomer, setSuperintendents, setIsLoaded) => {
    const customers = await find(DataTables.Customers(), { _id: customerId }) || [{}];

    if (customers[0]._id) {
        const entryForms = await find(DataTables.EntryForms(), { customerId: customers[0]._id });
        const entryFormIds = entryForms.map(({ _id }) => _id);
        const events = await find(DataTables.Events(), { entryFormId: { $in: entryFormIds } });
        setEntryForms(entryForms);
        setEvents(events);
    }

    const superintendents = await find(DataTables.Superintendents(), {}) || [];

    setCustomer(customers[0]);
    setSuperintendents(superintendents);
    setIsLoaded(true);
}

const entryFormContainerStyle = {
    minWidth: '800px',
    maxWidth: '1400px',
    margin: '0 auto 16px auto',
};

export default function EntryForms(props) {
    const [customer, setCustomer] = useState({});
    const [entryForms, setEntryForms] = useState([]);
    const [events, setEvents] = useState([]);
    const [superintendents, setSuperintendents] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [submitted, setSubmitted] = useState('notSubmitted');
    const [isLoaded, setIsLoaded] = useState(false);
    const history = useHistory();
    const customerId = props.match.params.customerid;

    useEffect(() => {
        load(customerId, setEntryForms, setEvents, setCustomer, setSuperintendents, setIsLoaded);
    }, [customerId]);

    const onSelect = ({ _id }) => {
        history.push(`/entryforms/${customerId}/${_id}`);
    };

    const onCopy = ({ _id }) => {
        const copy = async () => {
            const entryForms = await find(DataTables.EntryForms(), { _id });
            const entryForm = entryForms[0];
            delete entryForm._id;
            delete entryForm.hasPaid;
            delete entryForm.isComplete;
            entryForm.createDate = DateHelper.yyyymmddToday();
            const copiedEntryForm = await insert(DataTables.EntryForms(), entryForm);
            if (copiedEntryForm) {
                load(customerId, setEntryForms, setEvents, setCustomer, setSuperintendents, setIsLoaded);
            }
        };

        copy();
    };

    const rows = entryForms.map(entryForm => {
        return {
            _id: entryForm._id,
            callName: entryForm.dogObj.callName,
            dogName: entryForm.dogObj.dogName,
            breed: entryForm.dogObj.breed,
            isComplete: !!entryForm.isComplete,
            events: events.filter(({ entryFormId }) => entryFormId === entryForm._id).map((event) => {
                return {
                    name: event.name,
                    showDate: event.showDate,
                    superintendent: (superintendents.find(({ _id }) => _id === event.superintendentId) || {}).name,
                    entryFees: event.entryFees,
                    isComplete: event.isComplete
                }
            })
        };
    }).filter(row => !!row.isComplete === (submitted === 'submitted'));

    schemas.find(({ columnName }) => columnName === 'Select').action = onSelect;
    schemas.find(({ columnName }) => columnName === 'Copy').action = onCopy;

    const filteredRows = filterRows(schemas, rows, filterText);

    return (
        <div style={entryFormContainerStyle}>
            <div>
                <IsLoading isLoaded={isLoaded}>
                    <ReadOnlyFilterWithOptionsHeader
                        title={`Previous Entry Forms for ${customer.firstName || ''} ${customer.lastName || ''} (${filteredRows.length})`}
                        schemas={schemas}
                        optionTitle='Showing Entry Forms:'
                        defaultOption={submitted}
                        labelValueObjs={submittedFields}
                        filterTextOnChange={setFilterText}
                        optionOnChange={setSubmitted} />
                    <ReadOnlyTable
                        rows={filteredRows}
                        schemas={schemas} />
                </IsLoading>
            </div>
            <div className='button-container'>
                <button onClick={() => newEntryForm(customerId, history)}>New Entry Form</button>
            </div>
        </div>
    );
}