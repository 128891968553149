import React, { useState, useEffect } from 'react';
import States from './states';
import { find, insert, update, remove } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';

export default function DataStates() {
    const [states, setStates] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        find(DataTables.States(), {}).then((states = []) => {
            setStates(states);
            setIsLoaded(true);
        });
    }, []);

    const onAdd = (newSuper) => {
        const save = async () => {
            const state = await insert(DataTables.States(), newSuper);
            setStates(states.concat([state]));
        }

        save();
    }

    const onUpdate = (state) => {
        const save = async () => {
            const updatedBreed = await update(DataTables.States(), { _id: state._id }, state);
            let index = states.findIndex(({ _id }) => _id === updatedBreed._id);
            states[index] = state;
            setStates(states);
        }

        save();
    }

    const onDelete = ({ _id }) => {
        const removeRecord = async () => {
            await remove(DataTables.States(), { _id });
            setStates(states.filter((stateObj) => stateObj._id !== _id));
        }

        removeRecord();
    }

    states.sort((a, b) => {
        let aState = (a.state || 'zzzzzzzz').toLowerCase();
        let bState = (b.state || 'zzzzzzzz').toLowerCase();

        if (aState < bState) {
            return -1;
        }

        if (aState > bState) {
            return 1;
        }

        return 0;
    });

    return (
        <IsLoading isLoaded={isLoaded}>
            <States states={states} onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} />
        </IsLoading>
    )
}