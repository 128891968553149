import React, { useState } from "react";
import FilterHeader from "./common/FilterHeader";

const CustomerTile = ({ _id, firstName, lastName, onSelect, onEntryForm, onNewEntryForm }) => {
    return (
        <div className='tile' style={{ width: '440px' }}>
            <h4>{firstName} {lastName}</h4>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <span className='link' onClick={() => onSelect(_id)}>Edit</span>
                <span className='link' onClick={() => onNewEntryForm(_id)}>New Entry Form</span>
                <span className='link' onClick={() => onEntryForm(_id)}>Existing Entry Forms</span>
            </div>
        </div>
    );
}

const Customers = ({ customers, onSelect, onEntryForm, onNewEntryForm }) => {
    return customers.map((customer, index) => {
        return (<CustomerTile key={index} _id={customer._id} firstName={customer.firstName} lastName={customer.lastName} onSelect={onSelect} onEntryForm={onEntryForm} onNewEntryForm={onNewEntryForm} />)
    });
}

const filterCustomers = (customers, filterText) => {
    const maxCount = 100;
    const filteredCustomers = [];

    for (let index = 0; index < customers.length; index++) {
        const customer = customers[index];

        if (filterText === '' || `${customer.firstName} ${customer.lastName}`.toLowerCase().indexOf(filterText) > -1) {
            filteredCustomers.push(customer);
        }

        if (filteredCustomers.length === maxCount) {
            break;
        }
    }

    return filteredCustomers;
}

export default function CustomerSelector({ customers, onAdd, onSelect, onEntryForm, onNewEntryForm }) {
    const [filterText, setFilterText] = useState('');
    const filteredCustomers = filterCustomers(customers, filterText);

    return (
        <div>
            <FilterHeader
                title='Customers'
                setFilterText={setFilterText}
                numerator={filteredCustomers.length}
                denominator={customers.length} />
            <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                <div className='tile' style={{ width: '440px' }}>
                    <h4>Add Customer</h4>
                    <div className='button-container'>
                        <span className="link" onClick={() => onAdd()}>Add</span>
                    </div>
                </div>

                <Customers customers={filteredCustomers} onSelect={onSelect} onEntryForm={onEntryForm} onNewEntryForm={onNewEntryForm} />
            </div>
        </div>
    );
}