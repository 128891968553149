import React from 'react';
import PropTypes from 'prop-types';
import FormTable from '../../components/common/FormTable';

const schemas = [{
  sortOrder: 0,
  columnName: 'Show Location',
  propName: 'name',
  type: 'text',
  isAddOk: true,
  style: {width: '80%'}
}];

export default function ShowLocations({showLocations, onAdd, onUpdate, onDelete}) {
  const defaultShowLocation = {
    name: ''
  };

  const containerStyle = {
    minWidth: '300px',
    maxWidth: '500px',
    margin: 'auto'
  };

  return ( 
      <FormTable keyPropName='_id'
        tableTitle='ShowLocations'
        defaultValueObj={defaultShowLocation}
        values={showLocations}
        schemas={schemas}
        onAdd={onAdd}
        onUpdate={onUpdate}
        onDelete={onDelete}
        containerStyle={containerStyle}
        maxDisplayCount={300} />
  );
}

ShowLocations.propTpes = {
  showLocations: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}