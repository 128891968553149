import React, { useState, useEffect } from "react";
import { find } from "../api/data";
import { DataTables } from "../api/dataTables";
import FormTitleContainer from "./forms/formTitleContainer";
import TypeAheadField from "./forms/fields/typeAheadField";
import DateHelper from "../lib/common/dateHelper";

const EventRows = ({ disabled, defaultEvents, onDefaultEventsSelected }) => {

    return defaultEvents.map((defaultEvent) => {
        const { _id, location, showName, showDate, closingDate, regularFee, nonRegularFee, superName } = defaultEvent;

        return (
            <tr key={_id}>
                <td><button disabled={disabled} onClick={() => onDefaultEventsSelected([defaultEvent])}>Select</button></td>
                <td>{location}</td>
                <td>{showName}</td>
                <td>{DateHelper.numberToFormattedDate(showDate)}</td>
                <td>{DateHelper.numberToFormattedDate(closingDate)}</td>
                <td>{superName}</td>
                <td style={{textAlign: 'right'}}>${regularFee}</td>
                <td style={{textAlign: 'right'}}>${nonRegularFee}</td>
            </tr>
        );
    });
}

const DefaultEvents = ({ disabled, defaultEvents, onDefaultEventsSelected }) => {
    if (defaultEvents && defaultEvents.length > 0) {
        return (
            <table className='readyonly-table'>
                <thead>
                    <tr>
                        <th><button disabled={disabled} onClick={() => onDefaultEventsSelected(defaultEvents)}>Select All</button></th>
                        <th>Location</th>
                        <th>Show Name</th>
                        <th>Show Date</th>
                        <th>Closing Date</th>
                        <th>Superintendent</th>
                        <th style={{textAlign: 'right'}}>Regular Fee</th>
                        <th style={{textAlign: 'right'}}>Non Regular Fee</th>
                    </tr>
                </thead>
                <tbody>
                    <EventRows disabled={disabled} defaultEvents={defaultEvents} onDefaultEventsSelected={onDefaultEventsSelected} />
                </tbody>
            </table>
        );
    }

    return null;
}

export default function DefaultEventPicker({ onDefaultEventsSelected, disabled }) {
    const [currentShowLocation, setCurrentShowLocation] = useState('');
    const [showLocationObjs, setShowLocationObjs] = useState([]);
    const [defaultEvents, setDefaultEvents] = useState([]);
    const [superintendents, setSuperintendents] = useState([]);
    const [loadEventsDisabled, setLoadEventsDisabled] = useState(false);

    useEffect(() => {
        const load = async () => {
            const showLocationObjs = await find(DataTables.ShowLocations());
            const superintendents = await find(DataTables.Superintendents());

            if (showLocationObjs.length > 0) {
                showLocationObjs.sort((a, b) => (a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
                setShowLocationObjs(showLocationObjs);
            }

            setSuperintendents(superintendents);
        }

        load();
    }, []);

    const loadEvents = async () => {
        setLoadEventsDisabled(true);

        const showLocationObj = showLocationObjs.find(({ name }) => name === currentShowLocation);
        if (showLocationObj) {
            const defaultEvents = await find(DataTables.DefaultEvents(), { showLocationId: showLocationObj._id, closingDate: { $gte: DateHelper.yyyymmddToday() } });
            defaultEvents.sort((a, b) => (a.showDate - b.showDate));

            setDefaultEvents(defaultEvents.map((defaultEvent) => ({
                ...defaultEvent,
                location: currentShowLocation,
                superName: superintendents.find(({ _id }) => _id === defaultEvent.superintendentId).name
            })));
        }

        setLoadEventsDisabled(false);
    }

    const loadButtonId = 'eventPickerLoadButton';

    return (
        <FormTitleContainer title='Available Events'>
            <div style={{ display: 'flex' }}>
                <TypeAheadField
                    style={{ width: '350px' }}
                    fieldName='Pick a Show Location (City, State)'
                    fieldProperty='eventPickerShowLocation'
                    defaultValue={currentShowLocation}
                    nextInputElementId={loadButtonId}
                    options={showLocationObjs.map(({ name }) => name)}
                    onChange={(_, showLocation) => setCurrentShowLocation(showLocation)}
                    onBlur={() => loadEvents()} />
                <button id={loadButtonId} disabled={loadEventsDisabled} onClick={() => loadEvents()}>Load Events</button>
            </div>
            <DefaultEvents disabled={disabled} defaultEvents={defaultEvents} onDefaultEventsSelected={onDefaultEventsSelected} />
        </FormTitleContainer>
    );
}
