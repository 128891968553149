import React from 'react';
import RadioField from './fields/radioField';
import TextField from './fields/textField';
import DateField from './fields/dateField';
import DropDownField from './fields/dropDownField';
import FormTitleContainer from './formTitleContainer';

const sexFields = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' }
];

const registrationTypeFields = [
    { label: 'AKC', value: 'akc' },
    { label: 'AKC Litter', value: 'akcLitter' },
    { label: 'ILP', value: 'ilp' },
    { label: 'Foreign', value: 'foreign' }
];

const placeOfBirthFields = [
    { label: 'United States', value: 'usa' },
    { label: 'Canada', value: 'canada' },
    { label: 'Foreign', value: 'foreign' }
];

const ForeignCountryField = ({ dogObj, countriesLabelValues, onChange, onBlur }) => {
    if (dogObj.placeOfBirth === 'foreign') {
        return <DropDownField
            onChange={onChange}
            fieldName='Foreign Country'
            fieldProperty='foreignCountry-dog'
            labelValueObjs={countriesLabelValues}
            defaultValue={dogObj.foreignCountry}
            onBlur={onBlur} />
    }

    return null;
}

export default function DogForm({ onDogObjChange, dogObj, breeds, countries, onBlur, disabled }) {
    const onChange = (fieldProperty, value) => {
        const propertyName = fieldProperty.split('-')[0];
        const newDogObj = {
            ...dogObj
        };
        newDogObj[propertyName] = value;

        onDogObjChange(newDogObj);
    }

    const breedLabelValues = [{ label: 'Choose a Breed', value: null }].concat(breeds.map(breed => ({ label: breed, value: breed })));
    const countriesLabelValues = [{ label: 'Choose a Country', value: null }].concat(countries.map(country => ({ label: country, value: country })));

    return (
        <FormTitleContainer title='Dog Information'>
            <form onSubmit={(e) => e.preventDefault()}>
                <fieldset disabled={disabled}>
                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Registration Number'
                        fieldProperty='registrationNumber-dog'
                        defaultValue={dogObj.registrationNumber} />
                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Call Name'
                        fieldProperty='callName-dog'
                        defaultValue={dogObj.callName} />

                    <DropDownField
                        onChange={onChange}
                        fieldName='Breed'
                        fieldProperty='breed-dog'
                        labelValueObjs={breedLabelValues}
                        defaultValue={dogObj.breed}
                        onBlur={onBlur} />

                    <RadioField
                        onChange={onChange}
                        onBlur={onBlur}
                        name={dogObj._id}
                        fieldName='Sex'
                        fieldProperty='sex-dog'
                        labelValueObjs={sexFields}
                        defaultValue={dogObj.sex} />

                    <TextField
                        style={{ width: '350px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Dog Name'
                        fieldProperty='dogName-dog'
                        defaultValue={dogObj.dogName} />

                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Variety'
                        fieldProperty='variety-dog'
                        defaultValue={dogObj.variety} />

                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Class/Division'
                        fieldProperty='classDivision-dog'
                        defaultValue={dogObj.classDivision} />

                    <RadioField
                        onChange={onChange}
                        onBlur={onBlur}
                        name={dogObj._id}
                        fieldName='Registration Type'
                        fieldProperty='registrationType-dog'
                        labelValueObjs={registrationTypeFields}
                        defaultValue={dogObj.registrationType} />

                    <DateField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Birth Date'
                        fieldProperty='whelpDate-dog'
                        defaultValue={dogObj.whelpDate} />

                    <RadioField
                        onChange={onChange}
                        onBlur={onBlur}
                        name={dogObj._id}
                        fieldName='Place of Birth'
                        fieldProperty='placeOfBirth-dog'
                        labelValueObjs={placeOfBirthFields}
                        defaultValue={dogObj.placeOfBirth} />

                    <ForeignCountryField 
                        onChange={onChange}
                        onBlur={onBlur}
                        countriesLabelValues={countriesLabelValues}
                        dogObj={dogObj} />

                    <TextField
                        style={{ width: '350px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Breeders'
                        fieldProperty='breeders-dog'
                        defaultValue={dogObj.breeders} />

                    <TextField
                        style={{ width: '350px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Sire'
                        fieldProperty='sire-dog'
                        defaultValue={dogObj.sire} />

                    <TextField
                        style={{ width: '350px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Dam'
                        fieldProperty='dam-dog'
                        defaultValue={dogObj.dam} />
                </fieldset>
            </form>
        </FormTitleContainer>
    )
}