import React, {useRef} from 'react';

const validValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'backspace', 'tab', 'arrowleft', 'arrowright'];

export default function NumberField({onChange, onBlur, fieldName, name, fieldProperty, defaultValue, value, style, maxLength=500}) {
    const inputRef = useRef(null);

    return (
        <div className='container input-container'>
            <label htmlFor={fieldProperty}>{fieldName}</label>
            <input 
                style={style} 
                id={fieldProperty} 
                type='text'
                name={name}
                ref={inputRef}
                defaultValue={defaultValue}
                value={value}
                maxLength={maxLength}
                onKeyDown={(e) => {
                    const key = e.key.toLowerCase();
                    if (validValues.findIndex(validValue => validValue === key) === -1) {
                        e.preventDefault();
                    } else if (key === '.' && inputRef.current.value.indexOf('.') > -1) {
                        e.preventDefault();
                    }
                    
                }}
                onBlur={() => {
                    if (onBlur) {
                        onBlur();
                    }
                }}
                onChange={(e) => onChange(fieldProperty, e.target.value) }/>
        </div>
    )
}

