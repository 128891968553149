import React from 'react';

export default function RadioField({ name, onChange, onBlur, fieldName, fieldProperty, labelValueObjs, defaultValue }) {
    return (
        <div className='container input-container'>
            <label>{fieldName}</label>
            <div className='radio-container'>
                {labelValueObjs.map((labelValueObj, index) => {
                    let id = `${name}-${fieldProperty}-${index}`;

                    return (
                        <div key={index} className='radio-option-container'>
                            <input type='radio' id={id} name={`${name}-${fieldProperty}`}
                                onChange={(e) => onChange && onChange(fieldProperty, e.target.value)}
                                onBlur={() => onBlur && onBlur()}
                                value={labelValueObj.value}
                                checked={defaultValue === labelValueObj.value} />
                            <label htmlFor={id}>{labelValueObj.label}</label>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
