import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import IsLoading from '../../components/common/isLoading';
import { find, updateIsComplete, email } from '../../api/data';
import { loadEntriesData } from '../../api/dataHelper';
import { DataTables } from '../../api/dataTables';
import ReadOnlyTable from '../../components/common/readOnlyTable/readOnlyTable';
import ReadOnlyFilterHeader from '../../components/common/readOnlyTable/readOnlyFilterHeader';
import { filterRows } from '../../components/common/readOnlyTable/readOnlyFilterHelper';
import { reactToPdf } from '../../api/reactToPdf';
import EntriesReport from './entriesReport';
import RadioField from '../../components/forms/fields/radioField';
import DateHelper from '../../lib/common/dateHelper';

const schemas = [{
    sortOrder: 10,
    columnName: 'Superintendent',
    propName: 'superName',
    type: 'text',
    style: { width: '25%' }
}, {
    sortOrder: 20,
    columnName: 'Show',
    propName: 'showName',
    type: 'text',
    style: { width: '30%' }
}, {
    sortOrder: 30,
    columnName: 'Number of Entries',
    propName: 'count',
    type: 'number',
    style: { width: '15%' }
}, {
    sortOrder: 40,
    columnName: 'Total',
    propName: 'sum',
    type: 'currency',
    style: { width: '10%' }
}, {
    sortOrder: 50,
    columnName: 'Email',
    propName: 'email',
    type: 'action',
    render: () => 'Email',
    style: { width: '10%' }
}, {
    sortOrder: 60,
    columnName: 'Print',
    propName: 'print',
    type: 'action',
    render: () => 'Print',
    style: { width: '10%' }
}];

const containerStyle = {
    minWidth: '800px',
    maxWidth: '1400px',
    margin: '0 auto 16px auto',
};

const getEventNameKey = (name) => name.toLowerCase().split(' ').join('');

const sendEmail = async (_id, showNames, isComplete, e, loadCounter, setLoadCounter) => {
    e.target.disabled = true;

    const superintendentId = _id.split('-')[0];
    const { events, entryForms, handlers, telephoneEntry, superintendent } = await loadEntriesData(superintendentId, showNames.join('~'), isComplete);
    const pdfName = `${telephoneEntry.companyName.replaceAll(' ', '_')}_${DateHelper.yyyymmddToday()}.pdf`;
    const pdfHref = await reactToPdf(<EntriesReport events={events} entryForms={entryForms} handlers={handlers} telephoneEntry={telephoneEntry} superintendent={superintendent} pageStyle={{ margin: '0', height: '7.5in' }} />, pdfName, true);
    const totalAmount = events.reduce(((acc, event) => {
        acc += event.amount;
        return acc;
    }), 0);
    const emailResponse = await email({
        from: 'noreply@gigaflops.io',
        to: superintendent.email,
        cc: telephoneEntry.email,
        subject: `Submitting ${entryForms.length} Entries for ${showNames.join(', ')}`,
        text: `Hello ${superintendent.name},\n\nThe link below contains the entries collected for ${showNames.join(', ')}: \n\n${pdfHref}\n\nPlease charge the amount of $${totalAmount.toFixed(2).toLocaleString()} from my account.\n\nThanks!\n${telephoneEntry.companyName}\n${telephoneEntry.phone}\n${telephoneEntry.email}`
    });

    if (emailResponse && emailResponse.messageId) {
        await updateIsComplete(superintendentId, showNames);
    }

    setLoadCounter(loadCounter + 1);
    e.target.disabled = false;
}

const SelectSuperintendent = ({onChange, selectedSuper, supers, onEmailClick, onPrintClick}) => {
    const superLabelValues = [{label: 'None', value: '-1'}].concat(supers);
    const isDisabled = selectedSuper === '-1';
    const superName = isDisabled ? '' : (supers.find(({value}) => value === selectedSuper) || {}).label;

    return (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <RadioField
                onChange={onChange}
                name='super'
                fieldName='Select all of this Superintendent'
                labelValueObjs={superLabelValues}
                defaultValue={selectedSuper} />
            <div>
                <button style={{marginRight: '8px'}} disabled={isDisabled} onClick={(e) => onEmailClick(e, selectedSuper)}>Email {superName}</button>
                <button disabled={isDisabled} onClick={() => onPrintClick(selectedSuper)}>Print {superName}</button>
            </div>
        </div>
    );

}

export default function Submission() {
    const [filterText, setFilterText] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [superEvents, setSuperEvents] = useState([]);
    const [supers, setSupers] = useState([]);
    const [selectedSuper, setSelectedSuper] = useState('-1');
    const [loadCounter, setLoadCounter] = useState(0);
    const history = useHistory();

    useEffect(() => {
        const load = async () => {
            const superObjs = await find(DataTables.Superintendents(), {}, { name: 1 });
            const entryForms = await find(DataTables.EntryForms(), { isComplete: { $exists: false } }, { _id: 1 });
            const entryFormIds = entryForms.map(({ _id }) => _id);
            const events = await find(DataTables.Events(), { isComplete: { $exists: false }, entryFormId: { $in: entryFormIds } });

            const superMap = superObjs.reduce(((acc, superObj) => {
                acc[superObj._id] = superObj.name;
                return acc;
            }), {});
            const superEventMap = events.reduce(((acc, eventObj) => {
                if (!acc[eventObj.superintendentId]) {
                    acc[eventObj.superintendentId] = { name: superMap[eventObj.superintendentId], eventObj: {} };
                }

                if (!acc[eventObj.superintendentId]['eventObj'][getEventNameKey(eventObj.name)]) {
                    acc[eventObj.superintendentId]['eventObj'][getEventNameKey(eventObj.name)] = [];
                }

                acc[eventObj.superintendentId]['eventObj'][getEventNameKey(eventObj.name)].push(eventObj);
                return acc;
            }), {});

            const superEvents = Object.keys(superEventMap).reduce(((acc, superId) => {
                acc = acc.concat(Object.keys(superEventMap[superId].eventObj).reduce(((accEvent, eventKey) => {
                    const events = superEventMap[superId].eventObj[eventKey];
                    const sum = events.reduce(((accSum, eventObj) => {
                        accSum += typeof eventObj.amount === "string" ? 0 : eventObj.amount;
                        return accSum;
                    }), 0);
                    accEvent = accEvent.concat([{ _id: `${superId}-${eventKey}`, superName: superEventMap[superId].name, showName: events[0].name, count: events.length, sum }]);
                    return accEvent;
                }), []));
                return acc;
            }), []);

            const superTotalCountsObj = superEvents.reduce(((acc, superEvent) => {
                const [_id] = superEvent._id.split('-');
                if (!acc[_id]) {
                    acc[_id] = {name: superEvent.superName, count: 0};
                }

                acc[_id].count += superEvent.count;

                return acc;
            }), {});

            setSupers(Object.keys(superTotalCountsObj).map(_id => ({value: _id, label: `${superTotalCountsObj[_id].name} (${superTotalCountsObj[_id].count})`})));
            setSuperEvents(superEvents);
            setIsLoaded(true);
        }

        load();
    }, [loadCounter]);

    useMemo(() => {
        schemas.find(({ columnName }) => columnName === 'Email').action = (row, e) => {
            const isComplete = false;
            const { _id, showName } = row;
            sendEmail(_id, [showName], isComplete, e, loadCounter, setLoadCounter);
        };
        schemas.find(({ columnName }) => columnName === 'Print').action = ({ _id, showName }) => {
            const superintendentId = _id.split('-')[0];
            history.push(`/reports/submission/${superintendentId}/${showName}?isComplete=${false}`);
            setTimeout(() => updateIsComplete(superintendentId, [showName]), 2000);
        };
    }, [history, loadCounter]);

    const onSuperChange = (_, _id) => {
        setSelectedSuper(_id);
    }

    const onEmailClick = (e, superintendentId) => {
        const isComplete = false;
        let showNames = superEvents.filter(superEvent => superEvent._id.indexOf(superintendentId) === 0)
            .map(superEvent => (superEvent.showName));

        sendEmail(superintendentId, showNames, isComplete, e, loadCounter, setLoadCounter);
    };

    const onPrintClick = (superintendentId) => {
        let showNames = superEvents.filter(superEvent => superEvent._id.indexOf(superintendentId) === 0)
            .map(superEvent => (superEvent.showName));

        history.push(`/reports/submission/${superintendentId}/${showNames.join('~')}?isComplete=${false}`);
        setTimeout(() => updateIsComplete(superintendentId, showNames), 2000);
    }

    const filteredRows = filterRows(schemas, superEvents, filterText);

    return (
        <IsLoading isLoaded={isLoaded}>
            <div style={containerStyle}>
                <ReadOnlyFilterHeader
                    title={`Entry Forms ready for submission (${filteredRows.length})`}
                    schemas={schemas}
                    filterTextOnChange={setFilterText} />
                <SelectSuperintendent 
                    onChange={onSuperChange} 
                    supers={supers} 
                    selectedSuper={selectedSuper}
                    onEmailClick={onEmailClick}
                    onPrintClick={onPrintClick} />
                <ReadOnlyTable
                    rows={filteredRows}
                    schemas={schemas} />
            </div>
        </IsLoading>
    );
}