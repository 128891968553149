import React from 'react';

export default function FormTitleContainer({title, children}) {
    return (
        <div>
            <h3>{title}</h3>
            <div className='container input-grouping-container'>
                {children}
            </div>
        </div>
    );
}