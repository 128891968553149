import React from 'react';
import TextField from '../../forms/fields/textField';


export default function ReadOnlyFilterHeader({ title, filterTextOnChange }) {
    
        return (
            <div style={{ marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
                <h1>{title}</h1>
            <div>
                <TextField
                    onChange={(_, value) => filterTextOnChange(value.toLowerCase())}
                    fieldName='Filter:'
                    fieldProperty='filter'
                    defaultValue='' />
            </div>

        </div>
    );
}