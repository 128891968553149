import React from 'react';
import PropTypes from 'prop-types';
import ClubEntryReportCheckBox from './clubEntryReportCheckBox';

export default function ClubEntryReportChangeSection({isOwnerChange, isAddressChange}) {
  const containerStyle = {display: 'flex', marginTop: '2px'};
  const labelStyle = {display: 'block', marginLeft: '8px', fontSize: '9px'};

  return (
    <div>
      <div style={{verticalAlign: 'sub', fontSize: '10px'}}>Please Check if</div>
      <ClubEntryReportCheckBox label='OWNERSHIP CHANGE' isChecked={isOwnerChange} containerStyle={containerStyle} labelStyle={labelStyle} />
      <ClubEntryReportCheckBox label='ADDRESS CHANGE' isChecked={isAddressChange} containerStyle={containerStyle} labelStyle={labelStyle} />
    </div>
  );
}

ClubEntryReportChangeSection.propTypes = {
  isOwnerChange: PropTypes.bool,
  isAddressChange: PropTypes.bool
}