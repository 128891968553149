import React from 'react';

export default function SignaturePicker({ clientId }) {
    if (clientId === '6115928957bc2a0ad45ee4e5') {
        let style = { fill: '#FFFFFF' };

        return (
            <svg viewBox="115 240 400 90" style={{ height: '50px', enableBackground: 'new 115 240 400 90' }}>
                <g>
                    <g>
                        <path style={style} d="M102,341c0-35.33,0-70.67,0-106c123.33,0,246.67,0,370,0c0,35.33,0,70.67,0,106
                        C348.67,341,225.33,341,102,341z M166.69,316.28c-1.89,3.51-3.75,7.02-5.67,10.48c-1.04,1.87-2.37,3.61-3.22,5.56
                        c-0.38,0.86-0.18,2.32,0.35,3.1c0.27,0.4,2.12,0.26,2.8-0.25c2.68-2,4.31-4.77,5.08-8.06c0.22-0.95,0.47-1.97,1-2.76
                        c2.21-3.28,4.58-6.45,6.8-9.72c0.9-1.32,1.98-2.01,3.3-0.97c3.07,2.43,6.61,1.86,10.06,1.76c2.96-0.09,4.63-1.98,4.83-4.94
                        c0.1-1.4,0.24-2.82,0.58-4.17c0.98-3.86,2.09-7.69,3.13-11.53c0.43-1.58,0-2.92-1.59-3.45c-1.68-0.56-2.31,0.71-2.87,2.03
                        c-0.44,1.05-0.81,2.52-1.64,2.95c-3.84,1.96-7.02,4.61-9.72,7.93c-0.37,0.46-1.02,0.7-1.6,1.08c-0.92-2.21-1.83-4.14-2.51-6.15
                        c-0.42-1.22-0.8-2.6-0.64-3.84c0.3-2.39-0.7-3.52-3.06-3.06c-0.85,0.16-1.84,0.32-2.62,0.06c-3.5-1.2-6.96-2.55-10.42-3.87
                        c-0.2-0.08-0.33-0.34-0.51-0.53c0.06-0.26,0.03-0.58,0.17-0.72c3.76-3.83,6.35-8.43,9.22-12.92c3.73-5.84,7.03-11.83,9.55-18.22
                        c1.4-3.56,1.08-7.71-2.12-8c-2.83-0.26-6.28-2.55-8.4,1.58c-0.24,0.46-0.96,0.65-1.38,1.05c-1.09,1.02-2.39,1.92-3.16,3.15
                        c-2.67,4.26-5.95,8.02-7.98,12.82c-2.76,6.51-6.9,12.42-10.15,18.74c-1.14,2.21-2.5,2.81-4.72,2.59
                        c-2.26-0.22-3.74-1.36-4.39-3.47c-0.66-2.14-1-4.37-1.76-6.47c-0.41-1.14-1.4-2.06-2.13-3.08c-0.78,1.16-1.6,2.29-2.31,3.49
                        c-0.18,0.3-0.11,0.86,0.03,1.22c1.88,4.83,2.4,10.52,8.19,12.89c1.55,0.64,1.75,1.65,0.87,3.19c-3.19,5.63-6.23,11.36-9.37,17.02
                        c-0.97,1.74-0.96,3.3,0.86,4.27c1.92,1.03,2.9-0.49,3.69-1.91c1.44-2.59,2.83-5.2,4.2-7.82c2.01-3.82,4.15-7.58,5.9-11.51
                        c0.91-2.04,2.11-3.11,4.25-3.16c2.83-0.07,5.69-0.33,8.48-0.03c3.91,0.42,7.62,1.66,10.41,4.69c0.45,0.49,1.05,1.5,0.85,1.83
                        c-1.28,2.08-2.63,4.15-4.21,6c-1.17,1.36-2.63,2.53-4.15,3.5c-0.86,0.55-2.11,0.81-3.13,0.69c-1.38-0.16-2.61-0.25-3.07,1.27
                        c-0.47,1.54,0.09,3.1,1.68,3.38c1.68,0.3,3.48-0.12,5.23-0.2c1.06-0.05,2.25-0.36,3.15,0
                        C164.15,314.28,165.28,315.32,166.69,316.28z M284.68,288.74c-1.27-2.75-2.23-5.02-3.34-7.22c-3.03-6.01-6.35-11.81-13.21-14.23
                        c-3.86-1.36-7.91-1.79-10.98,1.44c-3.2,3.36-3.9,7.8-2.49,12.1c2.65,8.08,8.8,12.63,16.65,15.16c2.05,0.66,4.12,1.29,6.17,1.96
                        c1.52,0.5,1.65,1.5,0.89,2.73c-0.83,1.34-1.75,2.63-2.57,3.98c-0.93,1.55-2.45,3.44-0.29,4.78c2.18,1.35,3.19-0.93,4.17-2.39
                        c1.35-2,2.48-4.14,3.85-6.12c0.47-0.68,1.65-1.65,2.01-1.48c0.77,0.37,1.61,1.35,1.74,2.19c0.72,4.46,4.03,7.15,6.78,10.16
                        c2.23,2.44,4.4,2.22,6.14-0.54c4.56-7.28,6.86-15.49,9.12-23.65c2.61-9.39,5.27-18.7,10.65-26.97c0.72-1.11,1.49-2.26,1.89-3.51
                        c0.26-0.8-0.11-1.81-0.2-2.72c-0.96,0.21-2.26,0.11-2.81,0.7c-1.56,1.71-3.11,3.55-4.14,5.6c-3.79,7.6-6.71,15.54-9.11,23.69
                        c-2.13,7.23-4.64,14.35-7.07,21.48c-0.26,0.75-1.11,1.74-1.76,1.8c-0.65,0.05-1.58-0.8-2.03-1.49c-1.08-1.66-1.95-3.47-2.92-5.21
                        c-0.61-1.1-0.33-1.72,0.92-1.93c0.97-0.17,1.93-0.39,2.89-0.58c0.03-0.35,0.06-0.7,0.08-1.04c-1.48-0.47-2.94-1.12-4.45-1.36
                        c-3.04-0.47-3.56-1-2.44-3.88c0.77-1.99,2.08-3.76,2.94-5.72c0.43-0.98,0.39-2.17,0.57-3.26c-1.17,0.26-2.61,0.18-3.45,0.85
                        C287.42,285.19,286.35,286.8,284.68,288.74z M400.82,296.32c0.21,0.13,0.42,0.26,0.63,0.38c-0.25,0.64-0.65,1.27-0.7,1.92
                        c-0.07,0.95,0.11,1.92,0.19,2.88c1-0.33,2.5-0.36,2.9-1.04c2.25-3.77,4.47-7.59,6.23-11.61c1.13-2.6,3.01-3.69,5.42-4.17
                        c2.08-0.41,2.84,0.59,2.04,2.58c-0.87,2.16-1.9,4.25-2.91,6.35c-2.8,5.84-1.36,10.7,6.09,10.99c1.5,0.06,3,0,4.5-0.01
                        c7.97-0.04,15.65-1.48,22.34-5.99c2.76-1.86,5.05-4.54,7.2-7.14c1.36-1.64,1.08-3.86-0.22-5.6c-1.36-1.83-3.34-1.63-5.24-1.03
                        c-0.56,0.18-0.99,0.76-1.48,1.15c0.48,0.46,0.89,1.1,1.47,1.36c2.2,1.02,2.62,2,1.42,4.04c-0.46,0.77-1.02,1.6-1.75,2.08
                        c-2.78,1.8-5.46,3.97-8.52,5.07c-6.35,2.27-12.94,3.74-19.81,2.62c-4.14-0.68-4.83-2.23-2.93-6.01c1.48-2.96,2.8-6.03,3.96-9.13
                        c1.03-2.73-0.89-5.05-3.82-4.85c-1.33,0.09-2.65,0.26-3.97,0.44c-3.24,0.45-3.61-0.1-2.48-3.22c2.49-6.88,4.9-13.79,7.3-20.7
                        c0.27-0.77,0.47-1.67,0.32-2.43c-0.14-0.72-0.65-1.68-1.24-1.93c-0.59-0.25-1.77,0-2.2,0.48c-1.04,1.14-2.18,2.43-2.59,3.86
                        c-1.6,5.49-2.75,11.11-4.4,16.59c-1.92,6.37-4.11,12.65-8.61,17.83c-3.93,4.52-8.39,8.08-14.47,9.13
                        c-2.64,0.46-5.57-0.53-5.72-2.35c-0.38-4.54-0.79-9.28,2.9-12.85c0.98-0.95,2.67-1.15,4.04-1.69c-0.12,1.21-0.4,2.44-0.3,3.63
                        c0.09,1.05,0.33,2.38,1.02,2.97c0.47,0.4,2.08,0.02,2.77-0.52c1.08-0.85,1.96-2.05,2.64-3.27c0.93-1.64,0.51-3.03-0.96-4.4
                        c-2.49-2.31-5.16-2.33-8.04-1.23c-3.61,1.38-6.89,3.33-7.61,7.49c-0.51,2.93-0.67,5.97-0.55,8.95c0.18,4.13,4.8,7.54,8.85,6.76
                        c3.87-0.74,7.56-1.91,10.72-4.44C397,298.85,398.95,297.63,400.82,296.32z M368.75,287.44c-0.39-2.45-0.51-4.22-1.01-5.87
                        c-0.22-0.71-1.14-1.41-1.89-1.68c-0.37-0.13-1.16,0.7-1.64,1.2c-0.39,0.41-0.65,0.97-0.9,1.49c-2.83,6.03-5.6,12.15-11.42,15.98
                        c-2.38,1.56-5.09,2.63-7.71,3.79c-0.45,0.2-1.64-0.17-1.7-0.46c-0.14-0.6,0.05-1.48,0.45-1.96c0.94-1.16,2.13-2.11,3.12-3.23
                        c4.65-5.27,4.27-9.5-1.2-12.42c-1.21-0.65-2.68-0.88-4.05-1.14c-2.08-0.39-3.61,0.59-3.82,2.61c-0.35,3.43-1.65,6.32-3.56,9.15
                        c-3.39,5.05-7.81,8.52-13.76,9.83c-0.81,0.18-2.07-0.19-2.61-0.78c-0.38-0.41-0.18-1.8,0.23-2.44c1.8-2.79,3.86-5.4,5.65-8.2
                        c0.91-1.43,1.64-3.05,2.04-4.68c0.16-0.66-0.75-1.59-1.18-2.4c-0.67,0.49-1.48,0.87-1.99,1.5c-0.98,1.22-1.73,2.62-2.66,3.88
                        c-1.63,2.18-3.22,4.41-5.03,6.44c-1.34,1.51-2.98,2.75-4.51,4.08c-0.56,0.48-1.51,0.77-1.72,1.34c-0.35,0.97-0.26,2.11-0.35,3.17
                        c0.94-0.07,2.07,0.18,2.78-0.26c1.24-0.77,1.97-0.54,3.08,0.26c1.29,0.93,2.89,1.99,4.36,1.99c6.31,0.02,11.44-3.12,15.24-7.73
                        c2.63-3.18,4.19-7.32,5.85-11.2c1.17-2.72,2.04-3.29,4.49-1.7c2.08,1.34,2.2,3.87,0.24,5.99c-1.18,1.28-2.48,2.46-3.67,3.73
                        c-1.96,2.1-2.53,4.55-1.39,6.32c1.46,2.27,3.79,2.94,6.13,2.17c2.55-0.84,5-2.13,7.29-3.56c1.46-0.92,2.41-1.11,3.66,0.19
                        c1.2,1.25,2.49,2.5,3.95,3.4c2.53,1.56,4.75,0.85,5.47-1.99C366.51,298.49,367.63,292.63,368.75,287.44z M224.27,309.51
                        c-0.58,0-1.19,0.1-1.74-0.02c-1.14-0.26-2.25-0.64-3.37-0.96c0.52-1.16,0.93-2.4,1.6-3.47c0.74-1.19,2.02-2.11,2.5-3.36
                        c0.3-0.77-0.31-2.73-0.75-2.81c-2.97-0.53-2.45-2.32-1.91-4.27c0.27-0.96,0.48-1.95,0.59-2.94c0.23-2.06-0.95-3.42-2.92-2.87
                        c-2.1,0.59-4.33,1.45-5.94,2.85c-4.7,4.06-9.24,8.33-13.6,12.76c-1.4,1.42-3.23,3.16-2.22,5.69c0.92,2.3,3.08,2.52,5.12,2.97
                        c3.3,0.72,6.27-0.3,9.13-1.73c1.44-0.72,2.42-0.79,3.65,0.6c1.64,1.84,3.82,2.99,6.47,2.49c7.13-1.35,14.57-0.78,21.4-4.2
                        c3.24-1.62,6.03-3.44,8.19-6.24c1.09-1.41,0.36-3.63-1.35-4.44c-1.67-0.8-2.68,0.26-3.05,1.58c-0.67,2.39-2.46,3.42-4.43,4.4
                        C236.17,308.24,230.23,308.91,224.27,309.51z M334.51,259.24c0.19,0.29,0.49,1.28,1.07,1.49c0.73,0.26,1.89,0.15,2.51-0.29
                        c1.19-0.85,2.31-1.93,3.1-3.15c0.33-0.51,0.05-2.06-0.43-2.37C338.76,253.65,334.49,256.35,334.51,259.24z M196.02,269.3
                        c-1.32-1.24-2.07-2.53-2.98-2.66c-1.9-0.28-2.49,1.23-2.48,2.92c0.01,1.65,0.62,3.32,2.38,3.08
                        C193.93,272.51,194.7,270.81,196.02,269.3z"/>
                        <path d="M166.69,316.28c-1.41-0.96-2.54-2-3.87-2.54c-0.9-0.37-2.09-0.05-3.15,0c-1.75,0.08-3.55,0.5-5.23,0.2
                        c-1.59-0.28-2.15-1.83-1.68-3.38c0.46-1.52,1.69-1.42,3.07-1.27c1.02,0.11,2.27-0.14,3.13-0.69c1.51-0.97,2.98-2.14,4.15-3.5
                        c1.58-1.85,2.93-3.92,4.21-6c0.2-0.33-0.4-1.34-0.85-1.83c-2.79-3.03-6.5-4.26-10.41-4.69c-2.79-0.3-5.65-0.04-8.48,0.03
                        c-2.13,0.05-3.34,1.12-4.25,3.16c-1.75,3.93-3.9,7.69-5.9,11.51c-1.38,2.62-2.76,5.24-4.2,7.82c-0.79,1.42-1.76,2.93-3.69,1.91
                        c-1.82-0.97-1.83-2.53-0.86-4.27c3.15-5.66,6.18-11.39,9.37-17.02c0.88-1.54,0.68-2.56-0.87-3.19c-5.79-2.37-6.3-8.06-8.19-12.89
                        c-0.14-0.37-0.21-0.92-0.03-1.22c0.71-1.2,1.53-2.33,2.31-3.49c0.73,1.02,1.72,1.95,2.13,3.08c0.76,2.09,1.11,4.33,1.76,6.47
                        c0.65,2.11,2.13,3.25,4.39,3.47c2.22,0.22,3.58-0.38,4.72-2.59c3.25-6.32,7.39-12.24,10.15-18.74c2.04-4.79,5.32-8.56,7.98-12.82
                        c0.77-1.23,2.07-2.13,3.16-3.15c0.42-0.39,1.14-0.59,1.38-1.05c2.12-4.13,5.57-1.84,8.4-1.58c3.21,0.29,3.53,4.44,2.12,8
                        c-2.52,6.39-5.82,12.39-9.55,18.22c-2.87,4.49-5.46,9.09-9.22,12.92c-0.14,0.15-0.11,0.46-0.17,0.72
                        c0.18,0.19,0.31,0.46,0.51,0.53c3.46,1.32,6.92,2.67,10.42,3.87c0.78,0.27,1.77,0.11,2.62-0.06c2.36-0.45,3.36,0.67,3.06,3.06
                        c-0.15,1.24,0.23,2.62,0.64,3.84c0.68,2.01,1.59,3.94,2.51,6.15c0.58-0.38,1.23-0.62,1.6-1.08c2.7-3.32,5.88-5.97,9.72-7.93
                        c0.84-0.43,1.2-1.9,1.64-2.95c0.56-1.32,1.19-2.59,2.87-2.03c1.6,0.53,2.02,1.88,1.59,3.45c-1.04,3.85-2.15,7.67-3.13,11.53
                        c-0.34,1.35-0.49,2.77-0.58,4.17c-0.2,2.96-1.88,4.86-4.83,4.94c-3.44,0.1-6.98,0.67-10.06-1.76c-1.32-1.04-2.4-0.35-3.3,0.97
                        c-2.22,3.27-4.59,6.44-6.8,9.72c-0.53,0.79-0.78,1.81-1,2.76c-0.77,3.28-2.4,6.05-5.08,8.06c-0.68,0.51-2.53,0.65-2.8,0.25
                        c-0.53-0.78-0.73-2.23-0.35-3.1c0.85-1.95,2.18-3.68,3.22-5.56C162.94,323.29,164.8,319.79,166.69,316.28z M172.77,252.7
                        c-3.9,1.03-6.9,2.89-8.7,6.1c-4.78,8.54-9.39,17.18-14,25.81c-0.37,0.69-0.25,1.63-0.35,2.46c0.94-0.01,2.22,0.37,2.75-0.11
                        c2.47-2.18,5.09-4.34,6.97-6.99c2.47-3.48,4.29-7.42,6.43-11.14c1.56-2.72,3.27-5.36,4.78-8.11
                        C171.94,258.35,173.01,255.87,172.77,252.7z M188.53,300.12c-2.76,3-4.97,5.42-7.19,7.82c-1.69,1.82-1.51,2.46,0.93,3.09
                        c3.52,0.91,4.35,0.34,4.96-3.29c0.22-1.31,0.57-2.6,0.81-3.91C188.21,302.85,188.3,301.84,188.53,300.12z M172.22,304.36
                        c-0.27-0.23-0.55-0.47-0.82-0.7c-1.42,1.68-2.89,3.33-4.24,5.06c-0.28,0.36-0.3,1.1-0.14,1.55c0.08,0.22,0.86,0.32,1.31,0.27
                        C171.66,310.24,172.94,308.17,172.22,304.36z"/>
                        <path d="M284.68,288.74c1.68-1.94,2.75-3.55,4.19-4.7c0.84-0.67,2.28-0.59,3.45-0.85c-0.17,1.09-0.14,2.28-0.57,3.26
                        c-0.86,1.96-2.16,3.74-2.94,5.72c-1.12,2.88-0.6,3.42,2.44,3.88c1.52,0.23,2.97,0.89,4.45,1.36c-0.03,0.35-0.06,0.7-0.08,1.04
                        c-0.96,0.2-1.92,0.42-2.89,0.58c-1.24,0.22-1.53,0.83-0.92,1.93c0.97,1.74,1.84,3.54,2.92,5.21c0.45,0.69,1.38,1.54,2.03,1.49
                        c0.65-0.05,1.5-1.04,1.76-1.8c2.43-7.13,4.94-14.25,7.07-21.48c2.41-8.16,5.32-16.1,9.11-23.69c1.02-2.05,2.58-3.89,4.14-5.6
                        c0.54-0.59,1.85-0.49,2.81-0.7c0.09,0.91,0.46,1.92,0.2,2.72c-0.4,1.24-1.16,2.39-1.89,3.51c-5.38,8.27-8.04,17.59-10.65,26.97
                        c-2.26,8.16-4.56,16.37-9.12,23.65c-1.73,2.77-3.9,2.98-6.14,0.54c-2.75-3.01-6.06-5.71-6.78-10.16
                        c-0.13-0.84-0.97-1.82-1.74-2.19c-0.36-0.18-1.54,0.8-2.01,1.48c-1.37,1.98-2.5,4.12-3.85,6.12c-0.98,1.46-1.99,3.74-4.17,2.39
                        c-2.16-1.34-0.65-3.23,0.29-4.78c0.81-1.35,1.73-2.64,2.57-3.98c0.76-1.23,0.63-2.23-0.89-2.73c-2.05-0.67-4.11-1.3-6.17-1.96
                        c-7.85-2.52-14-7.08-16.65-15.16c-1.41-4.3-0.71-8.74,2.49-12.1c3.07-3.23,7.12-2.79,10.98-1.44c6.86,2.42,10.19,8.21,13.21,14.23
                        C282.44,283.72,283.41,285.99,284.68,288.74z M258.17,277.07c0.39,1.59,0.55,3.28,1.21,4.74c2.34,5.13,6.59,8.23,11.71,10.15
                        c2.86,1.07,5.84,1.92,8.84,2.5c2.19,0.42,2.73-0.47,1.9-2.55c-1.24-3.08-2.34-6.25-3.92-9.16c-2.47-4.56-4.68-9.34-10.13-11.46
                        C262.54,269.25,257.92,270.81,258.17,277.07z"/>
                        <path d="M400.82,296.32c-1.87,1.31-3.81,2.53-5.59,3.95c-3.16,2.53-6.85,3.7-10.72,4.44c-4.05,0.78-8.67-2.63-8.85-6.76
                        c-0.13-2.97,0.04-6.02,0.55-8.95c0.72-4.16,4-6.11,7.61-7.49c2.88-1.1,5.55-1.08,8.04,1.23c1.48,1.37,1.89,2.76,0.96,4.4
                        c-0.68,1.21-1.57,2.41-2.64,3.27c-0.69,0.55-2.3,0.92-2.77,0.52c-0.7-0.59-0.94-1.93-1.02-2.97c-0.1-1.19,0.18-2.42,0.3-3.63
                        c-1.36,0.54-3.06,0.75-4.04,1.69c-3.69,3.57-3.28,8.31-2.9,12.85c0.15,1.82,3.08,2.81,5.72,2.35c6.08-1.05,10.54-4.61,14.47-9.13
                        c4.5-5.18,6.7-11.46,8.61-17.83c1.65-5.47,2.8-11.09,4.4-16.59c0.42-1.43,1.55-2.72,2.59-3.86c0.43-0.48,1.62-0.73,2.2-0.48
                        c0.6,0.25,1.1,1.21,1.24,1.93c0.15,0.77-0.06,1.67-0.32,2.43c-2.4,6.91-4.81,13.82-7.3,20.7c-1.13,3.12-0.76,3.67,2.48,3.22
                        c1.32-0.18,2.64-0.35,3.97-0.44c2.93-0.2,4.85,2.12,3.82,4.85c-1.17,3.1-2.48,6.17-3.96,9.13c-1.89,3.78-1.2,5.33,2.93,6.01
                        c6.87,1.12,13.45-0.35,19.81-2.62c3.06-1.09,5.74-3.27,8.52-5.07c0.73-0.47,1.3-1.3,1.75-2.08c1.2-2.04,0.78-3.02-1.42-4.04
                        c-0.58-0.27-0.98-0.9-1.47-1.36c0.49-0.4,0.92-0.97,1.48-1.15c1.9-0.61,3.89-0.81,5.24,1.03c1.29,1.75,1.58,3.96,0.22,5.6
                        c-2.15,2.6-4.44,5.28-7.2,7.14c-6.69,4.5-14.36,5.95-22.34,5.99c-1.5,0.01-3,0.07-4.5,0.01c-7.45-0.29-8.89-5.15-6.09-10.99
                        c1.01-2.1,2.04-4.19,2.91-6.35c0.8-1.99,0.04-3-2.04-2.58c-2.42,0.48-4.29,1.57-5.42,4.17c-1.75,4.02-3.97,7.84-6.23,11.61
                        c-0.41,0.68-1.91,0.71-2.9,1.04c-0.08-0.96-0.26-1.93-0.19-2.88c0.05-0.65,0.46-1.28,0.7-1.92
                        C401.24,296.58,401.03,296.45,400.82,296.32z"/>
                        <path d="M368.75,287.44c-1.13,5.19-2.25,11.05-3.72,16.81c-0.72,2.84-2.93,3.55-5.47,1.99c-1.46-0.9-2.75-2.15-3.95-3.4
                        c-1.25-1.3-2.2-1.11-3.66-0.19c-2.28,1.43-4.74,2.72-7.29,3.56c-2.35,0.77-4.67,0.09-6.13-2.17c-1.14-1.77-0.57-4.22,1.39-6.32
                        c1.19-1.27,2.49-2.45,3.67-3.73c1.96-2.12,1.83-4.65-0.24-5.99c-2.45-1.59-3.32-1.02-4.49,1.7c-1.67,3.87-3.23,8.01-5.85,11.2
                        c-3.8,4.6-8.93,7.75-15.24,7.73c-1.47,0-3.07-1.07-4.36-1.99c-1.12-0.8-1.84-1.03-3.08-0.26c-0.71,0.44-1.84,0.2-2.78,0.26
                        c0.09-1.07,0-2.2,0.35-3.17c0.21-0.58,1.16-0.86,1.72-1.34c1.53-1.33,3.17-2.58,4.51-4.08c1.8-2.03,3.4-4.25,5.03-6.44
                        c0.94-1.26,1.69-2.66,2.66-3.88c0.5-0.63,1.31-1.01,1.99-1.5c0.42,0.81,1.34,1.74,1.18,2.4c-0.4,1.63-1.13,3.26-2.04,4.68
                        c-1.79,2.8-3.85,5.41-5.65,8.2c-0.42,0.64-0.62,2.03-0.23,2.44c0.54,0.59,1.8,0.96,2.61,0.78c5.96-1.32,10.37-4.79,13.76-9.83
                        c1.9-2.83,3.21-5.72,3.56-9.15c0.21-2.01,1.74-3,3.82-2.61c1.38,0.26,2.84,0.49,4.05,1.14c5.47,2.92,5.85,7.15,1.2,12.42
                        c-0.99,1.12-2.18,2.07-3.12,3.23c-0.39,0.49-0.59,1.36-0.45,1.96c0.07,0.29,1.26,0.65,1.7,0.46c2.62-1.16,5.33-2.23,7.71-3.79
                        c5.82-3.83,8.59-9.95,11.42-15.98c0.25-0.53,0.51-1.08,0.9-1.49c0.48-0.5,1.27-1.33,1.64-1.2c0.76,0.26,1.68,0.97,1.89,1.68
                        C368.25,283.22,368.37,284.99,368.75,287.44z M364.61,288.55c-0.77,0.25-0.99,0.25-1.06,0.35c-2,3.13-4.17,6.18-5.83,9.49
                        c-0.35,0.69,1.1,2.38,1.92,3.44c0.12,0.15,1.97-0.48,2.1-1C362.8,296.9,363.62,292.9,364.61,288.55z"/>
                        <path d="M224.27,309.51c5.96-0.6,11.9-1.27,17.38-3.98c1.97-0.97,3.76-2.01,4.43-4.4c0.37-1.32,1.37-2.38,3.05-1.58
                        c1.7,0.81,2.43,3.03,1.35,4.44c-2.16,2.8-4.95,4.62-8.19,6.24c-6.83,3.42-14.27,2.86-21.4,4.2c-2.65,0.5-4.83-0.65-6.47-2.49
                        c-1.23-1.38-2.21-1.32-3.65-0.6c-2.86,1.44-5.83,2.45-9.13,1.73c-2.04-0.45-4.21-0.67-5.12-2.97c-1.01-2.54,0.82-4.28,2.22-5.69
                        c4.36-4.43,8.9-8.7,13.6-12.76c1.61-1.39,3.85-2.26,5.94-2.85c1.97-0.55,3.15,0.81,2.92,2.87c-0.11,0.99-0.33,1.97-0.59,2.94
                        c-0.54,1.96-1.06,3.75,1.91,4.27c0.44,0.08,1.05,2.04,0.75,2.81c-0.48,1.25-1.76,2.18-2.5,3.36c-0.67,1.07-1.07,2.31-1.6,3.47
                        c1.12,0.33,2.23,0.71,3.37,0.96C223.08,309.61,223.68,309.51,224.27,309.51z M201.49,308.19c0.07,0.29,0.14,0.58,0.21,0.86
                        c2.6-0.42,5.34-0.46,7.74-1.38c2.01-0.77,3.86-2.35,5.31-3.99c0.59-0.67-0.11-2.43-0.09-3.7c0.01-0.64,0.38-1.27,0.46-1.91
                        c0.08-0.65,0.02-1.32,0.02-2.26C210.35,300.16,205.92,304.17,201.49,308.19z"/>
                        <path d="M334.51,259.24c-0.02-2.89,4.25-5.59,6.25-4.32c0.49,0.31,0.77,1.85,0.43,2.37c-0.79,1.22-1.91,2.3-3.1,3.15
                        c-0.62,0.44-1.78,0.55-2.51,0.29C335,260.52,334.7,259.53,334.51,259.24z"/>
                        <path d="M196.02,269.3c-1.31,1.5-2.09,3.2-3.08,3.34c-1.76,0.24-2.37-1.43-2.38-3.08c-0.01-1.69,0.58-3.2,2.48-2.92
                        C193.95,266.77,194.69,268.07,196.02,269.3z"/>
                        <path style={style} d="M172.77,252.7c0.24,3.18-0.83,5.66-2.12,8.02c-1.51,2.75-3.21,5.39-4.78,8.11
                        c-2.14,3.72-3.96,7.66-6.43,11.14c-1.89,2.65-4.5,4.81-6.97,6.99c-0.53,0.47-1.82,0.09-2.75,0.11c0.11-0.83-0.01-1.77,0.35-2.46
                        c4.61-8.63,9.22-17.27,14-25.81C165.87,255.58,168.87,253.72,172.77,252.7z"/>
                        <path style={style} d="M188.53,300.12c-0.22,1.71-0.31,2.72-0.49,3.72c-0.24,1.31-0.59,2.6-0.81,3.91
                        c-0.61,3.63-1.44,4.21-4.96,3.29c-2.44-0.63-2.62-1.27-0.93-3.09C183.56,305.54,185.77,303.13,188.53,300.12z"/>
                        <path style={style} d="M172.22,304.36c0.73,3.81-0.56,5.88-3.89,6.19c-0.45,0.04-1.23-0.05-1.31-0.27
                        c-0.16-0.45-0.14-1.19,0.14-1.55c1.35-1.73,2.82-3.38,4.24-5.06C171.67,303.89,171.94,304.12,172.22,304.36z"/>
                        <path style={style} d="M258.17,277.07c-0.25-6.26,4.37-7.82,9.62-5.78c5.45,2.12,7.66,6.9,10.13,11.46
                        c1.57,2.91,2.68,6.08,3.92,9.16c0.83,2.08,0.29,2.96-1.9,2.55c-3-0.57-5.98-1.42-8.84-2.5c-5.12-1.92-9.37-5.02-11.71-10.15
                        C258.72,280.35,258.56,278.66,258.17,277.07z"/>
                        <path style={style} d="M364.61,288.55c-0.98,4.35-1.81,8.35-2.86,12.29c-0.14,0.51-1.99,1.15-2.1,1c-0.83-1.07-2.27-2.76-1.92-3.44
                        c1.66-3.3,3.83-6.35,5.83-9.49C363.61,288.8,363.84,288.8,364.61,288.55z"/>
                        <path style={style} d="M201.49,308.19c4.43-4.01,8.86-8.03,13.65-12.38c0,0.94,0.07,1.61-0.02,2.26c-0.08,0.65-0.45,1.27-0.46,1.91
                        c-0.02,1.26,0.68,3.03,0.09,3.7c-1.45,1.64-3.3,3.23-5.31,3.99c-2.41,0.92-5.15,0.96-7.74,1.38
                        C201.63,308.76,201.56,308.47,201.49,308.19z"/>
                    </g>
                </g>
            </svg>
        );
    }

    return null;
}