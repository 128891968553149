import React, { useState } from 'react';
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import Home from './routes/home';
import Footer from './components/footer';
import Header from './components/header';
import UserSession from './components/common/userSession';
import FourOFour from './routes/fourOFour';
import Setup from './routes/setup';
import EntryForm from './routes/entryForm';
import IfAuth from './components/security/ifAuth';
import IfAdmin from './components/security/ifAdmin';
import theme from './lib/common/theme';
import './app.scss';
import Customer from './routes/customer';
import CustomerEntries from './routes/customerEntries';
import EntryForms from './routes/entryForms';
import Reports from './routes/reports';
import Entries from './reports/submission/entries';
import useScript from './lib/common/hooks/useScript';
import NotLoggedIn from './routes/notLoggedIn';
import DataClients from './routes/setup/dataClients';
import { deleteAllCache, login } from './api/data';
import { loadSessionStorage } from './api/dataHelper';

function App() {
  let cookieUser = UserSession.getUser();
  const [currentUser, setUser] = useState(cookieUser);
  const [paymentToken, setPaymentToken] = useState(sessionStorage.paymentToken);

  let src = 'https://secure.safewebservices.com/token/Collect.js';
  let scriptObj = {};
  scriptObj['data-tokenization-key'] = paymentToken;
  useScript(src, scriptObj, paymentToken);

  if (!sessionStorage.token && currentUser && currentUser.accessToken) {
    sessionStorage.token = currentUser.accessToken;
  }

  if (sessionStorage.token && !sessionStorage.breeds) {
    loadSessionStorage();
  }

  const onLogin = loggedInUser => {
    if (loggedInUser && loggedInUser.email && loggedInUser.accessToken) {
      const load = async () => {
        const userMeta = await login(loggedInUser.accessToken);

        if (userMeta) {
          loggedInUser.isAdmin = userMeta.isAdmin;
          UserSession.setUser(loggedInUser);
          setUser(loggedInUser);
          sessionStorage.paymentToken = userMeta.paymentToken;
          sessionStorage.defaultFee = userMeta.defaultFee;

          if (userMeta.clientId) {
            sessionStorage.clientId = userMeta.clientId;
          }

          setPaymentToken(userMeta.paymentToken);
        } else {
          UserSession.setUser(null);
          setUser(null);
          setPaymentToken(null);
          delete sessionStorage.paymentToken;
          delete sessionStorage.clientId;
          delete sessionStorage.defaultFee;
        }
      }

      load();
    }
  };

  const onLogout = () => {
    UserSession.setUser(null);
    setUser(null);
    deleteAllCache();
    delete window.CollectJS;

    return (<Redirect to="/notloggedin" />);
  }

  const onSelectClient = (client) => {
    const newUser = {
      ...currentUser,
      clientId: client._id,
      name: client.name
    };

    sessionStorage.clientId = client._id;
    setUser(newUser);
  }

  const FooterWithRouter = withRouter(props => <Footer {...props} userObj={currentUser} />);

  const appStyle = {
    backgroundColor: theme.secondaryBackground,
    display: 'grid',
    gridTemplateRows: `${theme.headerHeight} auto ${theme.largest}`,
    height: '100vh',
    overflow: 'hidden',
    fontFamily: 'Comfortaa, sans-serif'
  };

  const setupRoutes = [
    "/setup",
    "/setup/breeds",
    "/setup/classes",
    "/setup/countries",
    "/setup/jrclasses",
    "/setup/obedienceclasses",
    "/setup/shows",
    "/setup/showlocations",
    "/setup/states",
    "/setup/superintendents",
    "/setup/users",
    "/setup/telephoneentry",
    "/setup/import"
  ];

  const reportRoutes = [
    "/reports",
    "/reports/submissions",
    "/reports/submission/:superintendentid/:showname",
    "/reports/havenotpaid",
    "/reports/previouslysubmitted",
    "/reports/counts"
  ];

  const routesWithinApp = [
    "/",
    "/notloggedin",
    "/customer/:customerid",
    "/customer/entries/:customerid",
    "/entryforms/:customerid",
    "/entryforms/:customerid/:entryformid",
  ].concat(setupRoutes).concat(reportRoutes);

  return (
    <Switch>
      <Route exact path="/reports/submission/:superintendentid/:showname" render={(props) => {
        const query = new URLSearchParams(props.location.search);
        return (
          <IfAuth userObj={currentUser}>
            <Entries
              superintendentId={props.match.params.superintendentid}
              showName={props.match.params.showname}
              isComplete={query.get('isComplete') === 'true'} />
          </IfAuth>
        )
      }} />
      <Route path={routesWithinApp.concat(setupRoutes)}>
        <div style={appStyle}>
          <Header onLogin={onLogin} onLogout={onLogout} currentUser={currentUser} />
          <div className='main'>
            <Switch>
              <Route exact path="/notloggedin" render={() => <NotLoggedIn currentUser={currentUser} />} />
              <Route exact path="/" render={(props) => {
                if (currentUser && currentUser.email === process.env.REACT_APP_ADMIN_EMAIL && !currentUser.clientId) {
                  return (
                    <IfAuth userObj={currentUser}>
                      <DataClients {...props} onSelectClient={onSelectClient} />
                    </IfAuth>
                  )
                } else {
                  return (
                    <IfAuth userObj={currentUser}>
                      <Home {...props} />
                    </IfAuth>
                  )
                }
              }} />
              <Route exact path="/customer/:customerid" render={(props) => {
                return (
                  <IfAuth userObj={currentUser}>
                    <Customer {...props} />
                  </IfAuth>
                )
              }} />
              <Route exact path="/customer/entries/:customerid" render={(props) => {
                return (
                  <IfAuth userObj={currentUser}>
                    <CustomerEntries {...props} />
                  </IfAuth>
                )
              }} />
              <Route exact path="/entryforms/:customerid" render={(props) => {
                return (
                  <IfAuth userObj={currentUser}>
                    <EntryForms {...props} />
                  </IfAuth>
                )
              }} />
              <Route exact path="/entryforms/:customerid/:entryformid" render={(props) => {

                return (
                  <IfAuth userObj={currentUser}>
                    <EntryForm {...props} />
                  </IfAuth>
                )
              }} />
              <Route exact path={setupRoutes} render={(props) => {
                return (
                  <IfAdmin userObj={currentUser}>
                    <Setup {...props} />
                  </IfAdmin>
                )
              }} />
              <Route exact path={reportRoutes} render={(props) => {
                return (
                  <IfAuth userObj={currentUser}>
                    <Reports {...props} />
                  </IfAuth>
                )
              }} />
              <Route component={FourOFour} />
            </Switch>
          </div>
          <FooterWithRouter />
        </div>
      </Route>
    </Switch>
  );
}

export default App;