import React, { useState, useEffect } from 'react';
import DefaultEvents from './defaultEvents';
import { find, insert, remove, update } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';
import DateHelper from '../../lib/common/dateHelper';

export default function DataDefaultEvents({ showLocationId, superLabelValueObjs, showNames, onAddShowName, showOldDefaultEvents }) {
    const [defaultEvents, setDefaultEvents] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const filterDefaultEvents = defaultEvents.filter(({ showDate }) => showOldDefaultEvents || showDate >= DateHelper.yyyymmddToday());
    filterDefaultEvents.sort((a, b) => a.showDate - b.showDate);

    useEffect(() => {
        const load = async () => {
            const defaultEvents = (await find(DataTables.DefaultEvents(), { showLocationId })) || [];
            setDefaultEvents(defaultEvents);
            setIsLoaded(true);
        }
        load();
    }, [showLocationId]);

    const onAdd = (newDefaultEvent) => {
        const save = async () => {
            const defaultEvent = await insert(DataTables.DefaultEvents(), { ...newDefaultEvent, showLocationId });
            setDefaultEvents(defaultEvents.concat([defaultEvent]));
        }

        save();
    }

    const onUpdate = (defaultEvent) => {
        update(DataTables.DefaultEvents(), { _id: defaultEvent._id }, defaultEvent);
        setDefaultEvents(defaultEvents.map((defaultEventObj) => {
            if (defaultEventObj._id === defaultEvent._id) {
                return defaultEvent;
            }

            return defaultEventObj;
        }));
    }

    const onDelete = (defaultEventIndex) => {
        const { _id } = filterDefaultEvents[defaultEventIndex];
        remove(DataTables.DefaultEvents(), { _id });
        setDefaultEvents(defaultEvents.filter(defaultEvent => defaultEvent._id !== _id));
    }

    const onCopyAndAdd = (defaultEventIndex) => {
        const copyEvent = {...filterDefaultEvents[defaultEventIndex], _id: undefined};
        onAdd(copyEvent);
    }

    return (
        <IsLoading isLoaded={isLoaded}>
            <DefaultEvents
                defaultEvents={filterDefaultEvents}
                superLabelValueObjs={superLabelValueObjs}
                showNames={showNames}
                onAdd={onAdd}
                onUpdate={onUpdate}
                onDelete={onDelete}
                onAddShowName={onAddShowName}
                showOldDefaultEvents={showOldDefaultEvents}
                onCopyAndAdd={onCopyAndAdd} />
        </IsLoading>
    );
}