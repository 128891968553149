import React, { useState, useEffect } from 'react';
import Countries from './countries';
import { find, insert, update, remove } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';

export default function DataCountries() {
    const [countries, setCountries] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        find(DataTables.Countries(), {}).then((countries = []) => {
            setCountries(countries);
            setIsLoaded(true);
        });
    }, []);

    const onAdd = (newSuper) => {
        const save = async () => {
            const country = await insert(DataTables.Countries(), newSuper);
            setCountries(countries.concat([country]));
        }

        save();
    }

    const onUpdate = (country) => {
        const save = async () => {
            const updatedCountry = await update(DataTables.Countries(), { _id: country._id }, country);
            let index = countries.findIndex(({ _id }) => _id === updatedCountry._id);
            countries[index] = country;
            setCountries(countries);
        }

        save();
    }

    const onDelete = ({ _id }) => {
        const removeRecord = async () => {
            await remove(DataTables.Countries(), { _id });
            setCountries(countries.filter((country) => country._id !== _id));
        }

        removeRecord();
    }

    countries.sort((a, b) => {
        let aCountry = (a.country || 'zzzzzzzz').toLowerCase();
        let bCountry = (b.country || 'zzzzzzzz').toLowerCase();

        if (aCountry < bCountry) {
            return -1;
        }

        if (aCountry > bCountry) {
            return 1;
        }

        return 0;
    });

    return (
        <IsLoading isLoaded={isLoaded}>
            <Countries countries={countries} onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} />
        </IsLoading>
    )
}