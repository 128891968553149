import axios from 'axios';
import { Helper } from '../lib/common/helper';
import { DataTables } from './dataTables';
import UserSession from '../components/common/userSession';

const cacheOffset = 1000 * 60; //one minute
const getCacheKey = (type, query, projection) => {
    const key = type + JSON.stringify(query) + JSON.stringify(projection);
    return Helper.getHashCode(key);
}

const getCache = (tableName, cacheKey) => {
    if (!!sessionStorage[tableName]) {
        const cacheObjByCacheKey = JSON.parse(sessionStorage[tableName]);
        const cacheObj = cacheObjByCacheKey[cacheKey];
        if (cacheObj && cacheObj.cacheTime > new Date().getTime()) {
            return { hasCache: true, data: cacheObj.data };
        }
    }

    return { hasCache: false };
}

const updateCache = (tableName, cacheKey, data) => {
    let cacheObjByCacheKey = sessionStorage[tableName];
    let newCacheObj = {};

    if (cacheObjByCacheKey) {
        newCacheObj = JSON.parse(cacheObjByCacheKey);
    }

    newCacheObj[cacheKey] = {
        cacheTime: new Date().getTime() + cacheOffset,
        data
    }

    sessionStorage[tableName] = JSON.stringify(newCacheObj);
}

const deleteCache = (tableName) => {
    if (sessionStorage[tableName]) {
        delete sessionStorage[tableName];
    }
}

const deleteAllCache = () => {
    Object.keys(sessionStorage).forEach(key => delete sessionStorage[key]);
}

const logError = (action, error) => {
    if (error && error.response && error.response.status === 403) {
        alert('Your session has timed out, attempting to log you back in.');
        UserSession.setUser(null);
        deleteAllCache();
        delete window.CollectJS;
        window.location.href = '/';
    } else {
        console.error(`${action}: ${error}`);
    }
}

async function getDog(registrationNumber) {
    const cacheKey = getCacheKey('getDog', registrationNumber);
    const cacheObj = getCache('getDog', cacheKey);

    if (cacheObj.hasCache) {
        return new Promise((resolve) => {
            resolve(cacheObj.data);
        });
    }

    try {
        const url = `${process.env.REACT_APP_BE_URL}dog/${registrationNumber}`;
        const response = await axios.get(url, {
            headers: {
                'Authorization': sessionStorage.token
            }
        });

        updateCache('getDog', cacheKey, response.data);

        return response.data;
    } catch (error) {
        logError('getDog', error);
    }
}

async function updateIsComplete(superintendentId, showNames) {
    deleteCache(DataTables.Events());
    deleteCache(DataTables.EntryForms());

    try {
        const url = `${process.env.REACT_APP_BE_URL}updateiscomplete`;
        const response = await axios.post(url, {
            superintendentId,
            showNames,
            clientId: sessionStorage.clientId
        }, {
            headers: {
                'Authorization': sessionStorage.token
            }
        });

        return response.data;
    } catch (error) {
        logError('updateIsComplet', error);
    }
}

async function email(body) {
    try {
        const url = `${process.env.REACT_APP_BE_URL}email`;
        const response = await axios.post(url, body, {
            headers: {
                'Authorization': sessionStorage.token
            }
        });

        return response.data;
    } catch (error) {
        logError('insert', error);
    }
}

async function find(tableName, query = {}, projection = {}) {
    const cacheKey = getCacheKey('find', query, projection);
    const cacheObj = getCache(tableName, cacheKey);

    if (cacheObj.hasCache) {
        return new Promise((resolve) => {
            resolve(cacheObj.data);
        });
    }

    try {
        const url = `${process.env.REACT_APP_BE_URL}find`;
        const response = await axios.post(url, {
            tableName,
            query,
            projection,
            clientId: sessionStorage.clientId
        }, {
            headers: {
                'Authorization': sessionStorage.token
            }
        });

        updateCache(tableName, cacheKey, response.data);

        return response.data;
    } catch (error) {
        logError('find', error);
    }
}

async function insert(tableName, obj) {
    try {
        const url = `${process.env.REACT_APP_BE_URL}insert`;
        const response = await axios.post(url, {
            tableName,
            obj,
            clientId: sessionStorage.clientId
        }, {
            headers: {
                'Authorization': sessionStorage.token
            }
        });

        deleteCache(tableName);

        return response.data;
    } catch (error) {
        logError('insert', error);
    }
}


async function remove(tableName, query) {
    try {
        const url = `${process.env.REACT_APP_BE_URL}remove`;
        const response = await axios.post(url, {
            tableName,
            query,
            clientId: sessionStorage.clientId
        }, {
            headers: {
                'Authorization': sessionStorage.token
            }
        });

        deleteCache(tableName);

        return response.data;
    } catch (error) {
        logError('remove', error);
    }
}

async function update(tableName, query, updateObj) {
    try {
        const url = `${process.env.REACT_APP_BE_URL}update`;
        const response = await axios.post(url, {
            tableName,
            query,
            updateObj,
            clientId: sessionStorage.clientId
        }, {
            headers: {
                'Authorization': sessionStorage.token
            }
        });

        deleteCache(tableName);

        return response.data;
    } catch (error) {
        logError('update', error);
    }
}

async function importData(tableName, rows, deleteFirst) {
    try {
        const url = `${process.env.REACT_APP_BE_URL}importdata`;
        const response = await axios.post(url, {
            tableName,
            rows,
            clientId: sessionStorage.clientId,
            deleteFirst
        }, {
            headers: {
                'Authorization': sessionStorage.token
            }
        });

        deleteCache(tableName);

        return response.data;
    } catch (error) {
        logError('insert', error);
    }
}

async function login(token) {
    sessionStorage.token = null;

    try {
        const url = `${process.env.REACT_APP_BE_URL}login`;
        const response = await axios.get(url, {
            headers: {
                'Authorization': token
            }
        });

        if (response.status === 200) {
            sessionStorage.token = token;
            
            return response.data;
        }
    } catch {
        sessionStorage.token = null;
    }

    return;
}

async function hello(token) {
    const url = `${process.env.REACT_APP_BE_URL}hello`;
    const response = await axios.get(url, {
        headers: {
            'Authorization': sessionStorage.token
        }
    });

    return response.data;
}

export { hello, login, email, find, insert, importData, remove, update, getDog, updateIsComplete, deleteAllCache };