import React, { useState } from 'react';
import Tabs from '../components/common/tabs';
import DataShows from './setup/dataShows';
import DataBreeds from './setup/dataBreeds';
import DataClasses from './setup/dataClasses';
import DataCountries from './setup/dataCountries';
import DataJrClasses from './setup/dataJrClasses';
import DataObedienceClasses from './setup/dataObedienceClasses';
import DataShowLocations from './setup/dataShowLocations';
import DataStates from './setup/dataStates';
import DataSuperintendents from './setup/dataSuperintendents';
import DataTelephoneEntry from './setup/dataTelephoneEntry';
import DataUsers from './setup/dataUsers';
import Import from './setup/import';

const tabs = [{
    name: 'Shows',
    key: 'shows'
}, {
    name: 'Breeds',
    key: 'breeds'
},{
    name: 'Classes',
    key: 'classes'
},{
    name: 'Countries',
    key: 'countries'
},{
    name: 'Jr Classes',
    key: 'jrclasses'
},{
    name: 'Obedience Classes',
    key: 'obedienceclasses'
},{
    name: 'Show Locations',
    key: 'showlocations'
},{
    name: 'States',
    key: 'states'
},{
    name: 'Superintendents',
    key: 'superintendents'
}, {
    name: 'Users',
    key: 'users'
}, {
    name: 'Telephone Entry',
    key: 'telephoneentry'
}, {
    name: 'Import',
    key: 'import'
}];

export default function Setup(props) {
    const { history } = props;
    const getActiveTab = () => {
        const tabIndex = tabs.findIndex(tab => history.location.pathname.indexOf(tab.key) > -1);
        return tabIndex === -1 ? 'shows' : tabs[tabIndex].key;
    };

    const [activeTab, setActiveTab] = useState(getActiveTab());

    const tabOnChange = (key) => {
        setActiveTab(key);
        history.push(`/setup/${key}`);
    }

    const renderPage = () => {
        switch (activeTab) {
            case 'shows':
                return <DataShows />
            case 'breeds':
                return <DataBreeds />
            case 'classes':
                return <DataClasses />
            case 'countries':
                return <DataCountries />
            case 'jrclasses':
                return <DataJrClasses />
            case 'obedienceclasses':
                return <DataObedienceClasses />
            case 'showlocations':
                return <DataShowLocations />
            case 'states':
                return <DataStates />
            case 'superintendents':
                return <DataSuperintendents />
            case 'users':
                return <DataUsers />
            case 'telephoneentry':
                return <DataTelephoneEntry />
            case 'import':
                return <Import />
            default:
                return null;
        }
    }

    return (
        <div>
            <Tabs tabs={tabs} activeTab={activeTab} onChange={tabOnChange} />
            {renderPage()}
        </div>

    );
}