import React from 'react';
import PropTypes from 'prop-types';
import DateHelper from '../../lib/common/dateHelper';

export default function ClubEntryReportEvents({events = []}) {
    let style = events.length > 12 ? {
        width: '162px', 
        marginRight: '4px', 
        textOverflow: 'ellipsis', 
        overflow: 'hidden', 
        whiteSpace: 'nowrap'
    } : {};

    let renderEventsBlock = (showObjs, index) => (
        <div key={index} style={{fontSize: '12px'}}>
            {
                showObjs.map(({name, showDate}, index) => (
                    <div key={index} style={style}>
                        <span style={{marginRight: '8px'}}>{DateHelper.numberToFormattedDate(showDate)}</span>
                        <span>{name}</span>
                    </div>
                ))
            }
        </div>
    );

    let numberOfEventsPerCol = 6;
    let showParts = events.reduce(((acc, showObj, index) => {
        let position = Math.floor(index / numberOfEventsPerCol);

        if(acc[position] === undefined) {
            acc[position] = [];
        }

        acc[position].push(showObj);
        return acc;
    }), []);


    return (
        <>
            {showParts.map((showPartObjs, index) => renderEventsBlock(showPartObjs, index))}
        </>
    );
}

ClubEntryReportEvents.propTypes = {
  events: PropTypes.array
}