import React from 'react';
import FormTitleContainer from './formTitleContainer';
import TextField from './fields/textField';

export default function CustomerForm({ customerObj, onCustomerChange, onCustomerBlur }) {
    const onChange = (fieldProperty, value) => {
        const propertyName = fieldProperty.split('-')[0];
        customerObj[propertyName] = value;
        onCustomerChange(customerObj);
    }

    return (
        <FormTitleContainer title='Customer Information'>
            <form className='form-container' onSubmit={(e) => e.preventDefault()}>
                <TextField
                    style={{ width: '350px' }}
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='First Name'
                    fieldProperty='firstName-customer'
                    defaultValue={customerObj.firstName} />

                <TextField
                    style={{ width: '350px' }}
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='Last Name'
                    fieldProperty='lastName-customer'
                    defaultValue={customerObj.lastName} />

                <TextField
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='Cell'
                    fieldProperty='cell-customer'
                    defaultValue={customerObj.cell} />

                <TextField
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='Home'
                    fieldProperty='home-customer'
                    defaultValue={customerObj.home} />

                <TextField
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='Work'
                    fieldProperty='work-customer'
                    defaultValue={customerObj.work} />

                <TextField
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='Fax'
                    fieldProperty='fax-customer'
                    defaultValue={customerObj.fax} />

                <TextField
                    style={{ width: '350px' }}
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='Email'
                    fieldProperty='email-customer'
                    defaultValue={customerObj.email} />

                <TextField
                    style={{ width: '350px' }}
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='Address'
                    fieldProperty='address-customer'
                    defaultValue={customerObj.address} />

                <TextField
                    style={{ width: '350px' }}
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='City'
                    fieldProperty='city-customer'
                    defaultValue={customerObj.city} />

                <TextField
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='State'
                    fieldProperty='state-customer'
                    defaultValue={customerObj.state} />

                <TextField
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='Zip+4'
                    fieldProperty='zip-customer'
                    defaultValue={customerObj.zip} />

                <TextField
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='Handler'
                    fieldProperty='handler-customer'
                    defaultValue={customerObj.handler} />

                <TextField
                    style={{width: '700px'}}
                    onChange={onChange}
                    onBlur={onCustomerBlur}
                    fieldName='Notes'
                    fieldProperty='note-customer'
                    defaultValue={customerObj.note} />
            </form>
        </FormTitleContainer>
    )
}