import React from 'react';
import PropTypes from 'prop-types';

export default function ClubEntryReportHorizontalLabelTextBox({label, value, width, borderStyle}) {
  let height = '26px';
  let style = {
    padding: '2px',
    height: height, 
    marginRight: '4px',
    width: width,
    ...borderStyle
  }

  return (
    <div style={style}>
      <div style={{fontSize: '7px', marginBottom: '4px', verticalAlign: 'sub'}}>
        {label}
      </div>
      <div style={{display: 'flex'}}>
        <span style={{fontSize: '12px', alignSelf: 'flex-end'}}>{value}</span> 
      </div>
    </div>
  );
}

ClubEntryReportHorizontalLabelTextBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  borderStyle: PropTypes.object
}