export class DataTables {
    static Breeds() {
        return 'breeds';
    }
    static Classes() {
        return 'classes';
    }
    static Countries() {
        return 'countries';
    }
    static Customers() {
        return 'customers';
    }
    static Dogs() {
        return 'dogs';
    }
    static EntryForms() {
        return 'entryforms';
    }
    static Events() {
        return 'events';
    }
    static JrClasses() {
        return 'jrclasses';
    }
    static ObedienceClasses() {
        return 'obedienceclasses';
    }
    static Payments() {
        return 'payments';
    }
    static ShowLocations() {
        return 'showlocations';
    }
    static ShowNames() {
        return 'shownames';
    }
    static DefaultEvents() {
        return 'defaultevents';
    }
    static States() {
        return 'states';
    }
    static Superintendents() {
        return 'superintendents';
    }
    static TelephoneEntries() {
        return 'telephoneentries';
    }
    static Users() {
        return 'users';
    }
}