export class Helper {
  static getHashCode(value) {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
      let character = value.charCodeAt(i);
      hash = ((hash << 5) - hash) + character;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  static objectAreEqual(obj1 = {}, obj2 = {}) {
    let obj1Props = Object.keys(obj1);
    let obj2Props = Object.keys(obj2);

    if (obj1Props.length !== obj2Props.length) {
      return false;
    }

    obj1Props.sort();
    obj2Props.sort();

    for (let index = 0; index < obj1Props.length; index++) {
      let prop1 = obj1Props[index];
      let prop2 = obj2Props[index];

      if (prop1 !== prop2 || obj1[prop1] !== obj2[prop2]) {
        return false;
      }
    }

    return true;
  }

  static arrayAreEqual(array1 = [], array2 = []) {
    let isEqual = true;

    if (array2.length !== array1.length) {
      isEqual = false;
    } else {
      array1.sort();
      array2.sort();
      for (let index = 0; index < array2.length; index++) {
        if (array2[index] !== array1[index]) {
          isEqual = false;
        }
      }
    }

    return isEqual;
  }

  static sortObjectsBy(values, property) {
    (values || []).sort((a, b) => {
      let aProperty = (a[property] || 'zzzzzzzz').toLowerCase();
      let bProperty = (b[property] || 'zzzzzzzz').toLowerCase();

      if (aProperty < bProperty) {
        return -1;
      }

      if (aProperty > bProperty) {
        return 1;
      }

      return 0;
    });
  }

  static getDistinct(values) {
    return Array.from(new Set(values)).filter((value) => value !== undefined);
  }

  static getDefaultClassFeePerEvent(showObj, classes, eventObj = { regularFee: 0, nonRegularFee: 0 }) {
    const getClass = (className) => classes.find(({ name }) => name === className);
    const getClassFee = (classObj) => !classObj ? 0 : classObj.isRegular ? eventObj.regularFee : eventObj.nonRegularFee;
    
    const { showClass, additionalClasses, rallyTrialClass, obedienceTrialClass, jrShowmanClass } = showObj || {};
    
    const additionalClassesFee = getClassFee(getClass(additionalClasses), eventObj);
    const jrShowmanClassFee = !!jrShowmanClass ? getClassFee({}, eventObj) : 0;
    const obedienceTrialClassFee = !!obedienceTrialClass ? getClassFee({}, eventObj) : 0;
    const rallyTrialClassFee = !!rallyTrialClass ? getClassFee({}, eventObj) : 0;
    const showClassFee = getClassFee(getClass(showClass), eventObj);

    return additionalClassesFee + jrShowmanClassFee + obedienceTrialClassFee + rallyTrialClassFee + showClassFee;
  }

  static getDefaultNumber(number, defaultNumber) {
    return Number(isNaN(number) ? defaultNumber : number);
  }

  static getTotalAmount(events, defaultClassFee = 0) {
    return events.reduce(((acc, event) => {
      acc += this.getDefaultNumber(event.entryFees, defaultClassFee) + this.getDefaultNumber(event.feeAmount, 0) +
        this.getDefaultNumber(event.rvFee, 0) + this.getDefaultNumber(event.groomingFee, 0) + this.getDefaultNumber(event.trackingFee, 0);
      return acc;
    }), 0);
  }

  static getEntryAmount(events, defaultClassFee = 0) {
    return events.reduce(((acc, event) => {
      acc += this.getDefaultNumber(event.entryFees, defaultClassFee) + this.getDefaultNumber(event.rvFee, 0) +
        this.getDefaultNumber(event.groomingFee, 0) + this.getDefaultNumber(event.trackingFee, 0);
      return acc;
    }), 0);
  }
}