import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";

export default function IfAuth({ userObj, children }) {
  if (userObj) {
    return (children);
  }

  return (<Redirect to="/notloggedin" />);
}

IfAuth.propTypes = {
  userObj: PropTypes.object
}