import React from 'react';
import PropTypes from 'prop-types';
import Auth from './security/auth';
import theme from '../lib/common/theme';
import Logo from './common/logo';


export default function Header({ onLogin, onLogout, currentUser }) {  
  const titleStyle = {
    color: theme.secondaryTextHighlight,
    fontSize: theme.largest
  };

  return (
    <div className='header no-print'>
      <div style={{display: 'flex'}}>
        <Logo />
      </div>

      <div style={titleStyle}>Dog Show Helper</div>

      <Auth 
        onLogin={onLogin} 
        onLogout={onLogout}
        currentUser={currentUser}
        />
    </div>
  );
}

Header.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  currentUser: PropTypes.object
}