import React, { useState, useEffect } from 'react';
import Superintendents from './superintendents';
import { find, insert, update, remove } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';

export default function DataSuperintendents() {
    const [superintendents, setSuperintendents] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        find(DataTables.Superintendents(), {}).then((superintendents = []) => {
            setSuperintendents(superintendents);
            setIsLoaded(true);
        });
    }, []);

    const onAdd = (newSuper) => {
        const save = async () => {
            const superintendent = await insert(DataTables.Superintendents(), newSuper);
            setSuperintendents(superintendents.concat([superintendent]));
        }

        save();
    }

    const onUpdate = (superintendent) => {
        const save = async () => {
            const updatedSuperintendent = await update(DataTables.Superintendents(), { _id: superintendent._id }, superintendent);
            let index = superintendents.findIndex(({ _id }) => _id === updatedSuperintendent._id);
            superintendents[index] = superintendent;
            setSuperintendents(superintendents);
        }

        save();
    }

    const onDelete = ({ _id }) => {
        const removeRecord = async () => {
            await remove(DataTables.Superintendents(), { _id });
            setSuperintendents(superintendents.filter((superintendent) => superintendent._id !== _id));
        }

        removeRecord();
    }

    superintendents.sort((a, b) => {
        let aName = (a.name || 'zzzzzzzz').toLowerCase();
        let bName = (b.name || 'zzzzzzzz').toLowerCase();

        if (aName < bName) {
            return -1;
        }

        if (aName > bName) {
            return 1;
        }

        return 0;
    });

    return (
        <IsLoading isLoaded={isLoaded}>
            <Superintendents superintendents={superintendents} onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} />
        </IsLoading>
    )
}