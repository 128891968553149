import axios from 'axios';
import { renderToString } from 'react-dom/server';

async function reactToPdf(reactComponent, fileName, landscape) {
  try {
    let html = renderToString(reactComponent);
    const url = `${process.env.REACT_APP_PDF_URL}`;
    const response = await axios.post(url, {
      html,
      inlinePdf: true,
      fileName,
      options: {
        landscape,
        printBackground: false
      }
    }, { headers: { Authorization: `${process.env.REACT_APP_API2PDF_KEY}` } });
    return response.data.pdf;
  } catch (error) {
    console.log(error);
    alert(error);
  }
}

export { reactToPdf };