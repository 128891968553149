import React from 'react';

export default function UploadField({onChange, buttonText, fieldProperty, isDisabled}) {
    let style = { margin: '4px' };

    if (isDisabled) {
        return (
            <div style={style}>
                <label className='button button-disabled' htmlFor={fieldProperty}>{buttonText}</label>
            </div>
        )
    }

    return (
        <div style={style}>
            <label className='button' htmlFor={fieldProperty}>{buttonText}</label>
            <input 
                id={fieldProperty} 
                type='file' 
                hidden
                onChange={(e) => onChange(fieldProperty, e.target) }/>
        </div>
    )
}