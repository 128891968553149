import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTables } from '../api/dataTables';
import { find, remove } from '../api/data';
import { filterRows } from '../components/common/readOnlyTable/readOnlyFilterHelper';
import ReadOnlyTable from '../components/common/readOnlyTable/readOnlyTable';
import ReadOnlyFilterHeader from '../components/common/readOnlyTable/readOnlyFilterHeader';
import DateHelper from '../lib/common/dateHelper';

const schemas = [{
    sortOrder: 10,
    columnName: 'Customer Name',
    propName: 'customerName',
    type: 'text',
    style: { width: '10%' },
}, {
    sortOrder: 20,
    columnName: 'Customer Phone',
    propName: 'customerPhone',
    type: 'text',
    style: { width: '10%' },
}, {
    sortOrder: 30,
    columnName: 'Customer Email',
    propName: 'customerEmail',
    type: 'text',
    style: { width: '10%' },
}, {
    sortOrder: 40,
    columnName: 'Registration Number',
    propName: 'registrationNumber',
    type: 'text',
    style: { width: '10%' },
}, {
    sortOrder: 50,
    columnName: 'Dog Name',
    propName: 'dogName',
    type: 'text',
    style: { width: '20%' },
}, {
    sortOrder: 60,
    columnName: 'Events',
    propName: 'events',
    type: 'array',
    render: ({ name, showDate, superintendent, entryFees }) => {
        return `${name} - ${DateHelper.numberToFormattedDate(showDate)} - ${superintendent} - $${(entryFees || 0).toFixed(2).toLocaleString()}`
    },
    style: { width: '30%' }
}, {
    sortOrder: 70,
    columnName: 'Select',
    propName: 'select',
    type: 'action',
    render: () => 'Select'
}, {
    sortOrder: 80,
    columnName: 'Delete',
    propName: 'delete',
    type: 'action',
    render: () => 'Delete'
}];

const containerStyle = {
    minWidth: '1200px',
    maxWidth: '1800px',
    margin: '0 auto 16px auto',
};

export default function HaveNotPaid({ entryForms }) {
    const [rows, setRows] = useState([]);
    const [filterText, setFilterText] = useState('');
    const history = useHistory();

    useEffect(() => {
        const load = async () => {
            const customerIds = entryForms.map(({ customerId }) => customerId).filter(id => !!id);
            const entryFormIds = entryForms.map(({ _id }) => _id).filter(id => !!id);
            const customers = await find(DataTables.Customers(), { _id: { $in: customerIds } }) || [];
            const events = await find(DataTables.Events(), { entryFormId: { $in: entryFormIds } }) || [];
            const superIds = events.map(({ superintendentId }) => superintendentId);
            const supers = await find(DataTables.Superintendents(), { _id: { $in: superIds } }, { _id: 1, name: 1 });

            setRows(
                entryForms.filter(({ customerId }) => customers.findIndex(({ _id }) => _id === customerId) > -1)
                    .map((entryForm) => {
                        const customer = customers.find(({ _id }) => _id === entryForm.customerId);
                        return {
                            customerId: customer._id,
                            entryFormId: entryForm._id,
                            customerName: `${customer.firstName} ${customer.lastName}`,
                            customerPhone: customer.home || customer.cell || customer.work,
                            customerEmail: customer.email,
                            registrationNumber: entryForm.dogObj.registrationNumber,
                            dogName: entryForm.dogObj.dogName,
                            events: events.filter(({ entryFormId }) => entryFormId === entryForm._id).map(event => {
                                return {
                                    ...event,
                                    superintendent: supers.find(({ _id }) => _id === event.superintendentId).name
                                }
                            })
                        }
                    })
            );
        }

        load();

    }, [entryForms]);

    schemas.find(({ propName }) => propName === 'select').action = (row) => history.push(`/entryforms/${row.customerId}/${row.entryFormId}`);
    schemas.find(({ propName }) => propName === 'delete').action = (row) => {
        const response = window.confirm(`Are you sure you want to permanently delete ${row.customerName}'s entry for dog ${row.registrationNumber}`);
        if (response) {
            const removeValues = async () => {
                await remove(DataTables.Events(), { entryFormId: row.entryFormId });
                await remove(DataTables.EntryForms(), { _id: row.entryFormId });
            };
            removeValues();

            setRows(
                rows.filter(({ entryFormId }) => entryFormId !== row.entryFormId)
            );
        }
    }

    const filteredRows = filterRows(schemas, rows, filterText);

    return (
        <div style={containerStyle}>
            <ReadOnlyFilterHeader
                title={`Entry Forms that have not been paid (${filteredRows.length})`}
                schemas={schemas}
                filterTextOnChange={setFilterText} />
            <ReadOnlyTable
                rows={filteredRows}
                schemas={schemas} />
        </div>
    );
}
