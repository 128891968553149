import React, { useState, useEffect } from 'react';
import Breeds from './breeds';
import { find, insert, remove, update } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';

export default function DataBreeds() {
    const [breeds, setBreeds] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        find(DataTables.Breeds(), {}).then((breeds = []) => {
            setBreeds(breeds);
            setIsLoaded(true);
        });
    }, []);

    const onAdd = (newSuper) => {
        const save = async () => {
            const breed = await insert(DataTables.Breeds(), newSuper);
            setBreeds(breeds.concat([breed]));
        }

        save();
    }

    const onUpdate = (breed) => {
        const save = async () => {
            const updatedBreed = await update(DataTables.Breeds(), { _id: breed._id }, breed);
            let index = breeds.findIndex(({ _id }) => _id === updatedBreed._id);
            breeds[index] = breed;
            setBreeds(breeds);
        }

        save();
    }

    const onDelete = ({ _id }) => {
        const removeRecord = async () => {
            await remove(DataTables.Breeds(), { _id });
            setBreeds(breeds.filter((breed) => breed._id !== _id));
        }

        removeRecord();
    }

    breeds.sort((a, b) => {
        let aBreed = (a.breed || 'zzzzzzzz').toLowerCase();
        let bBreed = (b.breed || 'zzzzzzzz').toLowerCase();

        if (aBreed < bBreed) {
            return -1;
        }

        if (aBreed > bBreed) {
            return 1;
        }

        return 0;
    });

    return (
        <IsLoading isLoaded={isLoaded}>
            <Breeds breeds={breeds} onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} />
        </IsLoading>
    )
}