import React from 'react';
import PropTypes from 'prop-types';
import ClubEntryReportCheckBox from './clubEntryReportCheckBox';

export default function ClubEntryReportDogId({dogObj}) {
  const height = '64px';
  const isAkc = dogObj.registrationType === 'akc';
  const isAkcLitter = dogObj.registrationType === 'akcLitter';
  const isIlp = dogObj.registrationType === 'ilp';
  const isForeign = dogObj.registrationType === 'foreign';
  const checkboxLabelStyle = {marginLeft: '8px', fontSize: '10px'};
  const foreignCountry = dogObj.placeOfBirth === 'foreign' ? dogObj.foreignCountry : '';

  return (
    <div style={{fontSize: '10px', height: height, width: '50%', borderLeft: 'solid 1px black'}}>
      <ClubEntryReportCheckBox label='AKC REG. NO.' isChecked={isAkc} labelStyle={checkboxLabelStyle}/>

      <div style={{display: 'flex'}}>
        <ClubEntryReportCheckBox label='AKC LITTER NO.' isChecked={isAkcLitter} labelStyle={checkboxLabelStyle}/>

        <div style={{fontSize: '12px', marginLeft: '24px'}}>{dogObj.registrationNumber}</div>
      </div>
      
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <ClubEntryReportCheckBox label='ILP NO.' isChecked={isIlp} labelStyle={checkboxLabelStyle}/>
        <div style={{fontSize: '12px', margin: '2px 4px 0 0'}}>{foreignCountry}</div>
      </div>
      
      <ClubEntryReportCheckBox label='FOREIGN REG. NO. COUNTRY' isChecked={isForeign} labelStyle={checkboxLabelStyle}/>
    </div>
  );
}

ClubEntryReportDogId.propTypes = {
  dogObj: PropTypes.object
}