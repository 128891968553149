import { find, insert } from "./data";
import { DataTables } from "./dataTables";
import DateHelper from "../lib/common/dateHelper";

const loadEntriesData = async (superintendentId, showName, isComplete) => {
    let eventWhere = { superintendentId };

    if (showName.indexOf('~') > -1) {
        eventWhere.name = { $in: showName.split('~') };
    } else {
        eventWhere.name = showName;
    }

    if (isComplete) {
        eventWhere.isComplete = isComplete;
    } else {
        eventWhere.isComplete = { $exists: false };
    }

    const events = await find(DataTables.Events(), eventWhere);
    const entryFormIds = events.map(({ entryFormId }) => entryFormId);
    const entryForms = await find(DataTables.EntryForms(), { _id: { $in: entryFormIds } });
    const customerIds = entryForms.map(({ customerId }) => (customerId));
    const handlers = await find(DataTables.Customers(), { _id: { $in: customerIds } }, { handler: true });
    const telephoneEntries = await find(DataTables.TelephoneEntries()) || [];
    const superintendents = await find(DataTables.Superintendents(), { _id: superintendentId }) || [];

    return {
        events,
        entryForms,
        handlers,
        telephoneEntry: telephoneEntries.length > 0 ? telephoneEntries[0] : {},
        superintendent: superintendents.length > 0 ? superintendents[0] : {}
    }
}

const loadSessionStorage = async () => {
    const breeds = (await find(DataTables.Breeds(), {}, { _id: 0, breed: 1 })).map(({ breed }) => (breed));
    const classes = (await find(DataTables.Classes(), {}, { _id: 0, name: 1, isRegular: 1 }));
    const obedienceClasses = (await find(DataTables.ObedienceClasses(), {}, { _id: 0, name: 1 })).map(({ name }) => (name));
    const jrClasses = (await find(DataTables.JrClasses(), {}, { _id: 0, name: 1 })).map(({ name }) => (name));
    const countries = (await find(DataTables.Countries(), {}, { _id: 0, country: 1 })).map(({ country }) => (country));
    const states = await find(DataTables.States(), {}, { _id: 0, state: 1, abbr: 1 });

    breeds.sort((a, b) => (a.toLowerCase().localeCompare(b.toLowerCase())));
    classes.sort((a, b) => (a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
    obedienceClasses.sort((a, b) => (a.toLowerCase().localeCompare(b.toLowerCase())));
    jrClasses.sort((a, b) => (a.toLowerCase().localeCompare(b.toLowerCase())));
    countries.sort((a, b) => (a.toLowerCase().localeCompare(b.toLowerCase())));
    states.sort((a, b) => (a.state.toLowerCase().localeCompare(b.state.toLowerCase())));

    sessionStorage.breeds = JSON.stringify(breeds);
    sessionStorage.classes = JSON.stringify(classes);
    sessionStorage.obedienceClasses = JSON.stringify(obedienceClasses);
    sessionStorage.jrClasses = JSON.stringify(jrClasses);
    sessionStorage.countries = JSON.stringify(countries);
    sessionStorage.states = JSON.stringify(states);
}

const hasSessionStorage = () => {
    return !!(sessionStorage.breeds && sessionStorage.classes && sessionStorage.obedienceClasses &&
        sessionStorage.jrClasses && sessionStorage.countries && sessionStorage.states);
}

const newEntryForm = async (customerId, history) => {
    const newEntryForm = { customerId, dogObj: {}, showObj: {}, ownerObj: {}, juniorObj: {}, createDate: DateHelper.yyyymmddToday() };
    const { _id } = await insert(DataTables.EntryForms(), newEntryForm);
    history.push(`/entryforms/${customerId}/${_id}`);
}

export { loadEntriesData, loadSessionStorage, hasSessionStorage, newEntryForm }