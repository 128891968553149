import React from 'react';
import TextField from './fields/textField';
import DropDownField from './fields/dropDownField';
import CheckBoxField from './fields/checkBoxField';
import CheckBoxGrouping from './fields/checkBoxGrouping';
import FormTitleContainer from './formTitleContainer';

const StateField = ({ ownerObj, stateLabelValues, onChange, onBlur, country = 'United States' }) => {

    if (country === 'United States') {
        return <DropDownField
            onChange={onChange}
            fieldName='State'
            fieldProperty='state-owner'
            labelValueObjs={stateLabelValues}
            defaultValue={ownerObj.state}
            onBlur={onBlur} />
    }

    return <TextField
        onChange={onChange}
        onBlur={onBlur}
        fieldName='Foreign State'
        fieldProperty='foreignState-owner'
        defaultValue={ownerObj.foreignState} />
}

export default function OwnerForm({ onOwnerObjChange, ownerObj, countries, states, onBlur, disabled }) {
    const onChange = (fieldProperty, value) => {
        const propertyName = fieldProperty.split('-')[0];
        ownerObj[propertyName] = value;
        onOwnerObjChange(ownerObj);
    }

    const countriesLabelValues = [{ label: 'Choose a Country', value: null }].concat(countries.map(country => ({ label: country, value: country })));
    const stateLabelValues = [{ label: 'Choose a State', value: null }].concat(states.map(stateObj => ({ label: stateObj.state, value: stateObj.abbr })));

    return (
        <FormTitleContainer title='Owner Information'>
            <form onSubmit={(e) => e.preventDefault()}>
                <fieldset disabled={disabled}>
                    <TextField
                        style={{ width: '350px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Name of Owners (use comma for multiple)'
                        fieldProperty='name-owner'
                        defaultValue={ownerObj.name} />

                    <TextField
                        style={{ width: '350px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Address'
                        fieldProperty='address-owner'
                        defaultValue={ownerObj.address} />

                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='City'
                        fieldProperty='city-owner'
                        defaultValue={ownerObj.city} />

                    <DropDownField
                        onChange={onChange}
                        fieldName='Country'
                        fieldProperty='country-owner'
                        labelValueObjs={countriesLabelValues}
                        defaultValue={ownerObj.country || 'United States'}
                        onBlur={onBlur} />

                    <StateField
                        country={ownerObj.country}
                        ownerObj={ownerObj}
                        stateLabelValues={stateLabelValues}
                        onChange={onChange}
                        onBlur={onBlur} />

                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Zip Code'
                        fieldProperty='zip-owner'
                        defaultValue={ownerObj.zip} />

                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Agent Name'
                        fieldProperty='agentName-owner'
                        defaultValue={ownerObj.agentName} />

                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Phone 1'
                        fieldProperty='phone1-owner'
                        defaultValue={ownerObj.phone1} />

                    <TextField
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Phone 2'
                        fieldProperty='phone2-owner'
                        defaultValue={ownerObj.phone2} />

                    <TextField
                        style={{ width: '350px' }}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldName='Email Address'
                        fieldProperty='email-owner'
                        defaultValue={ownerObj.email} />

                    <CheckBoxGrouping groupingName='Has Owner/Address Changed'>
                        <CheckBoxField
                            onChange={onChange}
                            onBlur={onBlur}
                            fieldName='Owner'
                            fieldProperty='isOwnerChange-owner'
                            defaultValue={ownerObj.isOwnerChange} />

                        <CheckBoxField
                            onChange={onChange}
                            onBlur={onBlur}
                            fieldName='Address'
                            fieldProperty='isAddressChange-owner'
                            defaultValue={ownerObj.isAddressChange} />
                    </CheckBoxGrouping>
                </fieldset>
            </form>
        </FormTitleContainer>
    )
}