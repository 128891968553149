import React, { useState, useEffect } from 'react';
import Users from './users';
import { find, insert, update } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';
import { Helper } from '../../lib/common/helper';

export default function DataUsers() {
    const [users, setUsers] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const load = async () => {
            const users = await find(DataTables.Users(), {});
            setUsers(users);
            setIsLoaded(true);
        }
        load();
    }, []);

    const onAdd = (newUser) => {
        const save = async () => {
            const user = await insert(DataTables.Users(), newUser);
            setUsers(users.concat([user]));
        }

        save();
    }

    const onUpdate = (modifiedUser) => {
        const save = async () => {
            const updateUser = await update(DataTables.Users(), { _id: modifiedUser._id }, modifiedUser);
            let index = users.findIndex(({ _id }) => _id === updateUser._id);
            users[index] = updateUser;
            setUsers(users);
        }

        save();
    }

    Helper.sortObjectsBy(users, 'name');

    return (
        <IsLoading isLoaded={isLoaded}>
            <Users users={users} onAdd={onAdd} onUpdate={onUpdate} />
        </IsLoading>
    );
}