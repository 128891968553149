import React from 'react';
import { NavLink } from "react-router-dom";
import theme from '../lib/common/theme';
import IfAdmin from './security/ifAdmin';

export default function Footer({ location, userObj }) {
  const { pathname } = location;
  const homePath = '/';
  const setupPath = '/setup';
  const reportsPath = '/reports';

  const footerStyle = {
    backgroundColor: theme.barrierColor,
    padding: `${theme.xsmall} 0`,
    overflow: 'hidden'
  };

  const navStyle = {
    display: 'grid',
    gridTemplateColumns: userObj && userObj.isAdmin ? '1fr 1fr 1fr' : '1fr 1fr',
    gridColumnGap: theme.xsmall,
    backgroundColor: theme.barrierColor,
    overflow: 'hidden'
  };

  const linkStyle = {
    color: theme.secondaryText,
    textDecoration: 'none',
    display: 'block',
    width: '100%',
    textAlign: 'center',
    fontSize: theme.medium,
    lineHeight: theme.largest,
    backgroundColor: theme.primaryBackground
  };

  const activeLinkStyle = {
    color: theme.secondaryTextHighlight,
    fontWeight: '800'
  };

  return (
    <div className='no-print' style={footerStyle}>
      <div style={navStyle}>
        <NavLink style={pathname === homePath ? { ...linkStyle, ...activeLinkStyle } : linkStyle} exact={true} to={homePath}>Home</NavLink>
        <IfAdmin userObj={userObj}>
          <NavLink style={pathname.indexOf('/setup') === 0 ? { ...linkStyle, ...activeLinkStyle } : linkStyle} to={setupPath}>Setup</NavLink>
        </IfAdmin>
        <NavLink style={pathname === reportsPath ? { ...linkStyle, ...activeLinkStyle } : linkStyle} to={reportsPath}>Reports</NavLink>
      </div>
    </div>
  );
}
