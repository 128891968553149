import React from 'react';
import Summary from './summary';
import ClubEntryReport from '../clubEntryReport/clubEntryReport';

const ClubEntry = ({ entryForm, events, handler, pageStyle, defaultClassFee }) => {
    const filteredEvents = events.filter(({ entryFormId }) => entryForm._id === entryFormId);
    return <ClubEntryReport entryForm={entryForm} events={filteredEvents} handler={handler} triggerPrint={true} pageStyle={pageStyle} defaultClassFee={defaultClassFee} />
}

export default function EntriesReport({ events, entryForms, handlers, telephoneEntry, superintendent, defaultClassFee, pageStyle = {} }) {

    return (
        <>
            <Summary telephoneEntry={telephoneEntry} superintendent={superintendent} events={events} defaultClassFee={defaultClassFee} entryForms={entryForms} />
            {entryForms.map(entryForm => {
                const handler = (handlers.find(({ customerId }) => customerId === entryForm.customerId) || { handler: '' }).handler || '';

                return (
                    <ClubEntry key={entryForm._id} entryForm={entryForm} events={events} handler={handler} pageStyle={pageStyle} defaultClassFee={defaultClassFee} />
                );
            })}
        </>
    );
}