import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { GoogleLogout } from 'react-google-login';
import PropTypes from 'prop-types';
import './auth.scss';

export default function Auth({ onLogin, onLogout, currentUser }) {

  if (currentUser) {
    if (currentUser.loginType === 'google') {
      return (
        <div>
          <GoogleLogout
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={({ onClick }) => (<button onClick={onClick}>Logout {currentUser.name}</button>)}
            onLogoutSuccess={onLogout} />
        </div>
      );
    } else if (currentUser.loginType === 'facebook') {
      return (
        <button className='facebookLogout' onClick={onLogout}>Logout {currentUser.name}</button>
      );
    }
  } else {
    return (
      <div>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          isSignedIn={true}
          render={({ onClick }) => (<button onClick={onClick}>Login</button>)}
          onSuccess={response => {
            onLogin({
              name: response.profileObj.name,
              email: response.profileObj.email,
              accessToken: response.tokenObj.id_token,
              loginType: 'google'
            }
            );
          }}
          onFailure={() => alert('Failed to log with Google.')}
          cookiePolicy={'single_host_origin'}
        />
      </div>
    );
  }
}


Auth.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  currentUser: PropTypes.object
}