import React from 'react';
import PropTypes from 'prop-types';

export default function ClubEntryReportCheckBox({label, isChecked=false, containerStyle, labelStyle}) {
  const checkboxStyle = {
    margin: '1px'
  };
  const key = (label || '').split(' ').join('').toLowerCase()

  return (
    <div style={containerStyle}>
      <input key={key} style={checkboxStyle} type='checkbox' checked={isChecked} readOnly={true} />
      <span style={labelStyle}>{label}</span>
    </div>
  );
}

ClubEntryReportCheckBox.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  containerStyle: PropTypes.object,
  labelStyle: PropTypes.object
}