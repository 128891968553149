import moment from 'moment-es6';

export default class DateHelper {
  static yyyymmddToday() {
    return Number(moment(new Date()).format('YYYYMMDD'));
  }

  static numberToDate(yyyymmdd) {
    return moment(yyyymmdd, 'YYYYMMDD').toDate();
  }

  static DateToyyyymmdd(date) {
    return Number(moment(date).format('YYYYMMDD'));
  }

  static numberToFormattedDate(yyyymmdd) {
    return !!yyyymmdd ? moment(yyyymmdd, 'YYYYMMDD').format('MM/DD/YYYY') : '';
  }

  static numberToMonthDay(yyyymmdd) {
    return moment(yyyymmdd, 'YYYYMMDD').format('MMM-DD');
  }

  static numberToFullDate(yyyymmdd) {
    return moment(yyyymmdd, 'YYYYMMDD').format('dddd, MMMM Do YYYY');
  }

  static addyyyymmddFromToday(value, key) {
    return Number(moment(new Date()).add(value, key).format('YYYYMMDD'));
  }
}