import React from 'react';
import PropTypes from 'prop-types';
import FormTable from '../../components/common/FormTable';

const schemas = [{
  sortOrder: 0,
  columnName: 'Company Name',
  propName: 'companyName',
  type: 'text',
  isAddOk: true,
  style: { width: '30%' }
}, {
  sortOrder: 1,
  columnName: 'Payment Token',
  propName: 'paymentToken',
  type: 'text',
  isAddOk: true,
  style: { width: '60%' }
}, {
  sortOrder: 8,
  columnName: 'isArchived',
  propName: 'isArchived',
  type: 'checkbox',
  isAddOk: false,
  style: { width: '10%' }
}];

export default function Clients({ clients, onAdd, onUpdate, onSelect }) {
  const defaultClient = {
    companyName: '',
    paymentToken: '',
    isArchive: false
  };

  const containerStyle = {
    maxWidth: '800px',
    margin: 'auto'
  };

  return (
    <div>
      <FormTable tableTitle='Clients' defaultValueObj={defaultClient} values={clients} schemas={schemas} onAdd={onAdd} onUpdate={onUpdate} onSelect={onSelect} containerStyle={containerStyle} />
    </div>
  );
}

Clients.propTpes = {
  clients: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
}