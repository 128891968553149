import React, { useState, useEffect } from 'react';
import Classes from './classes';
import { find, insert, update, remove } from '../../api/data';
import { DataTables } from '../../api/dataTables';
import IsLoading from '../../components/common/isLoading';

export default function DataClasses() {
    const [classes, setClasses] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        find(DataTables.Classes(), {}).then((classes = []) => {
            setClasses(classes);
            setIsLoaded(true);
        });
    }, []);

    const onAdd = (newClassObj) => {
        const save = async () => {
            const classObj = await insert(DataTables.Classes(), newClassObj);
            setClasses(classes.concat([classObj]));
        }

        save();
    }

    const onUpdate = (classObj) => {
        const save = async () => {
            const updatedClass = await update(DataTables.Classes(), { _id: classObj._id }, classObj);
            let index = classes.findIndex(({ _id }) => _id === updatedClass._id);
            classes[index] = classObj;
            setClasses(classes);
        }

        save();
    }

    const onDelete = ({ _id }) => {
        const removeRecord = async () => {
            await remove(DataTables.Classes(), { _id });
            setClasses(classes.filter((classObj) => classObj._id !== _id));
        }

        removeRecord();
    }

    classes.sort((a, b) => {
        let aName = (a.name || 'zzzzzzzz').toLowerCase();
        let bName = (b.name || 'zzzzzzzz').toLowerCase();

        if (aName < bName) {
            return -1;
        }

        if (aName > bName) {
            return 1;
        }

        return 0;
    });

    return (
        <IsLoading isLoaded={isLoaded}>
            <Classes classes={classes} onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} />
        </IsLoading>
    )
}