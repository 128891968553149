import { useEffect, useState } from "react";
import { find, email } from "../api/data";
import { DataTables } from "../api/dataTables";
import DateHelper from "../lib/common/dateHelper";
import CustomerEntriesReport from '../reports/customerEntries';
import { reactToPdf } from '../api/reactToPdf';

const RenderPdf = async (telephoneEntry, customer, entryForms, events, locations, payments) => {
    const pdfHref = await reactToPdf(<CustomerEntriesReport entryForms={entryForms} events={events} locations={locations} customer={customer} />);
    const customerName = `${customer.firstName} ${customer.lastName}`;
    const paymentListString = payments.filter(({amount}) => amount > 0).map(({amount, paymentDate}) => (`${DateHelper.numberToFormattedDate(paymentDate)}: $${amount}`)).join('\n');
    const paymentString = paymentListString.length > 0 ? `Below are a list of credit card charges:\n\n${paymentListString}\n\n` : '';

    await email({
        from: 'noreply@gigaflops.io',
        to: customer.email,
        cc: telephoneEntry.email,
        subject: `Customer Entries Report for ${customerName} (${DateHelper.numberToFormattedDate(DateHelper.yyyymmddToday())})`,
        text: `Hello ${customerName},\n\nThe link below contains the Customer Entries Report as of ${DateHelper.numberToFormattedDate(DateHelper.yyyymmddToday())}: \n\n${pdfHref}\n\n${paymentString}Thanks!\n${telephoneEntry.companyName}\n${telephoneEntry.phone}\n${telephoneEntry.email}`
    });

    alert(`Successfully emailed ${customer.email}`);
}


export default function CustomerEntries(props) {
    const [entryForms, setEntryForms] = useState([]);
    const [events, setEvents] = useState([]);
    const [locations, setLocations] = useState([]);
    const [customer, setCustomer] = useState({});
    const [payments, setPayments] = useState([]);
    const [telephoneEntry, setTelephoneEntry] = useState({});
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const { customerid } = props.match.params;

    useEffect(() => {
        const load = async () => {
            const today = DateHelper.yyyymmddToday();
            const telephoneEntries = await find(DataTables.TelephoneEntries()) || [];
            const customers = await find(DataTables.Customers(), { _id: customerid });
            const entryForms = await find(DataTables.EntryForms(), { customerId: customerid, hasPaid: true });
            const entryFormIds = entryForms.map(({ _id }) => (_id));
            const events = await find(DataTables.Events(), { entryFormId: { $in: entryFormIds }, date: { $gte: today } });
            const payments = await find(DataTables.Payments(), { customerId: customerid, entryFormId: { $in: entryFormIds } });

            const locationMap = events.reduce(((acc, eventObj) => {
                acc[eventObj.location] = 1;
                return acc;
            }), {});
            const locations = Object.keys(locationMap);
            locations.sort();

            setTelephoneEntry(telephoneEntries[0]);
            setCustomer(customers[0]);
            setEntryForms(entryForms);
            setEvents(events);
            setLocations(locations);
            setPayments(payments);
        }

        load();
    }, [customerid]);

    const sendEmail = async () => {
        setIsSendingEmail(true);
        await RenderPdf(telephoneEntry, customer, entryForms, events, locations, payments);
        setIsSendingEmail(false);
    }

    const emailTitle = !!customer.email ? `Email ${customer.email}` : `No Email for ${customer.firstName} ${customer.lastName}`;

    return (
        <div>
            <div style={{ marginBottom: '16px' }}>
                <button disabled={!customer.email || isSendingEmail} onClick={() => sendEmail()}>{emailTitle}</button>
            </div>

            <CustomerEntriesReport entryForms={entryForms} events={events} locations={locations} customer={customer} />
        </div>
    );
}