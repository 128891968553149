import React from 'react';
import TextField from './fields/textField';
import NumberField from './fields/numberField';
import FormTitleContainer from './formTitleContainer';

export default function TelephoneEntryForm({ onTelephoneEntryObjChange, telephoneEntryObj, onBlur }) {
    const onChange = (fieldProperty, value) => {
        telephoneEntryObj[fieldProperty] = value;
        onTelephoneEntryObjChange(telephoneEntryObj);
    }

    return (
        <FormTitleContainer title='Telephone Entry Information'>
            <form className='form-container' onSubmit={(e) => e.preventDefault()}>
                <TextField
                    style={{ width: '350px' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldName='Company Name'
                    fieldProperty='companyName'
                    defaultValue={telephoneEntryObj.companyName} />

                <TextField
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldName='Phone'
                    fieldProperty='phone'
                    defaultValue={telephoneEntryObj.phone} />

                <TextField
                    style={{ width: '350px' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldName='Email'
                    fieldProperty='email'
                    defaultValue={telephoneEntryObj.email} />

                <TextField
                    style={{ width: '350px' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldName='Address1'
                    fieldProperty='address1'
                    defaultValue={telephoneEntryObj.address1} />

                <TextField
                    style={{ width: '350px' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldName='Address2'
                    fieldProperty='address2'
                    defaultValue={telephoneEntryObj.address2} />

                <TextField
                    style={{ width: '350px' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldName='City'
                    fieldProperty='city'
                    defaultValue={telephoneEntryObj.city} />

                <TextField
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldName='State'
                    fieldProperty='state'
                    defaultValue={telephoneEntryObj.state} />

                <TextField
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldName='Zip+4'
                    fieldProperty='zip'
                    defaultValue={telephoneEntryObj.zip} />

                <TextField
                    style={{ width: '350px' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldName='Payment Token'
                    fieldProperty='paymentToken'
                    defaultValue={telephoneEntryObj.paymentToken} />

                <NumberField
                    style={{ width: '150px' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldName='Default Fee'
                    fieldProperty='defaultFee'
                    defaultValue={telephoneEntryObj.defaultFee} /> 
            </form>
        </FormTitleContainer>
    )
}