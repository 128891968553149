import React from 'react';
import PropTypes from 'prop-types';
import FormTable from '../../components/common/FormTable';

const schemas = [{
  sortOrder: 0,
  columnName: 'State',
  propName: 'state',
  type: 'text',
  isAddOk: true,
  style: { width: '50%' }
}, {
  sortOrder: 0,
  columnName: 'Abbreviation',
  propName: 'abbr',
  type: 'text',
  isAddOk: true,
  style: { width: '30%' }
}];

export default function States({ states, onAdd, onUpdate, onDelete }) {
  const defaultState = {
    state: '',
    abbr: ''
  };

  const containerStyle = {
    minWidth: '300px',
    maxWidth: '500px',
    margin: 'auto'
  };

  return (
    <FormTable keyPropName='_id'
      tableTitle='States'
      defaultValueObj={defaultState}
      values={states}
      schemas={schemas}
      onAdd={onAdd}
      onUpdate={onUpdate}
      onDelete={onDelete}
      containerStyle={containerStyle}
      maxDisplayCount={300} />
  );
}

States.propTpes = {
  states: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}