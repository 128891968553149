import React from 'react';
import PropTypes from 'prop-types';
import FormTable from '../../components/common/FormTable';

const schemas = [{
  sortOrder: 10,
  columnName: 'Name',
  propName: 'name',
  type: 'text',
  isAddOk: true,
  style: { width: '30%' }
}, {
  sortOrder: 20,
  columnName: 'Email',
  propName: 'email',
  type: 'text',
  isAddOk: true,
  style: { width: '45%' }
}, {
  sortOrder: 30,
  columnName: 'isAdmin',
  propName: 'isAdmin',
  type: 'checkbox',
  isAddOk: true,
  style: { width: '10%' }
}, {
  sortOrder: 40,
  columnName: 'isArchived',
  propName: 'isArchived',
  type: 'checkbox',
  isAddOk: false,
  style: { width: '10%' }
}];

export default function Users({ users, onAdd, onUpdate }) {
  const defaultUser = {
    name: '',
    email: '',
    phone: ''
  };

  const containerStyle = {
    maxWidth: '800px',
    margin: 'auto'
  };

  return (
    <div>
      <FormTable keyPropName='_id' tableTitle='Users' defaultValueObj={defaultUser} values={users} schemas={schemas} onAdd={onAdd} onUpdate={onUpdate} containerStyle={containerStyle} />
    </div>
  );
}

Users.propTpes = {
  users: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
}