import React, { useState, useEffect } from 'react';
import ClubEntryForm from '../components/forms/clubEntryForm';
import ClubEntryReport from '../reports/clubEntryReport/clubEntryReport';
import { DataTables } from '../api/dataTables';
import { Helper } from '../lib/common/helper';
import { hasSessionStorage, loadSessionStorage } from '../api/dataHelper';
import { find, insert, update, remove } from '../api/data';
import IsLoading from '../components/common/isLoading';
import Pay from '../components/forms/pay';
import FormTitleContainer from '../components/forms/formTitleContainer';


let eventIndexIdMap = {};

export default function EntryForm(props) {
    const [customer, setCustomer] = useState({});
    const [availableDogObjs, setAvailableDogObjs] = useState([]);
    const [entryForm, setEntryForm] = useState({ dogObj: {}, showObj: {}, ownerObj: {}, juniorObj: {} });
    const [savedEntryForm, setSavedEntryForm] = useState({});
    const [events, setEvents] = useState([]);
    const [superintendents, setSuperintendents] = useState([]);
    const [amount, setAmount] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [newEventCount, setNewEventCount] = useState(0);
    const customerId = props.match.params.customerid;
    const entryFormId = props.match.params.entryformid;
    const classes = JSON.parse(sessionStorage.classes);
    const eventObj = !!events && events.length > 0 ? events[0] : { regularFee: 0, nonRegularFee: 0 };
    const defaultClassFeePerEvent = Helper.getDefaultClassFeePerEvent(entryForm.showObj, classes, eventObj);

    useEffect(() => {
        const load = async () => {
            const customers = await find(DataTables.Customers(), { _id: customerId }) || [{}];
            const superintendents = await find(DataTables.Superintendents(), {});
            const entryForms = await find(DataTables.EntryForms(), { _id: entryFormId }) || [{}];
            const events = entryForms.length > 0 ? await find(DataTables.Events(), { entryFormId: entryForms[0]._id }) : [];
            let dogObjs = [];

            if (customers[0].dogIds) {
                dogObjs = await find(DataTables.Dogs(), { _id: { $in: customers[0].dogIds } });
                dogObjs.sort((a, b) => (a.registrationNumber.toLowerCase().localeCompare(b.registrationNumber.toLowerCase())));
            }

            eventIndexIdMap = events.reduce(((acc, eventObj, index) => {
                acc[index] = eventObj._id;
                return acc;
            }), {});

            Helper.sortObjectsBy(superintendents, 'name');
            setCustomer(customers[0]);
            setSuperintendents(superintendents);

            if (!!entryForms[0]) {
                setEntryForm(entryForms[0]);
                setSavedEntryForm(JSON.parse(JSON.stringify(entryForms[0])));
            }

            setEvents(events);
            setAvailableDogObjs(dogObjs);
            setIsLoaded(true);
        }
        load();

    }, [customerId, entryFormId]);

    useEffect(() => {
        const newAmount = Helper.getTotalAmount(events, defaultClassFeePerEvent);
        setAmount(newAmount);
    }, [events, defaultClassFeePerEvent])

    useEffect(() => {
        const load = async () => {
            const events = await find(DataTables.Events(), { entryFormId });
            eventIndexIdMap = events.reduce(((acc, eventObj, index) => {
                acc[index] = eventObj._id;
                return acc;
            }), {});

            const newAmount = Helper.getTotalAmount(events, defaultClassFeePerEvent);
            setEvents(events);
            setAmount(newAmount);
            setIsLoaded(true);
        }
        load();
    }, [customerId, entryFormId, newEventCount, defaultClassFeePerEvent]);

    useEffect(() => {
        const load = async () => await loadSessionStorage();

        if (!hasSessionStorage()) {
            load();
        }
    }, []);

    const onChangeEntryForm = (entryForm) => {
        setEntryForm(entryForm);
    }

    const onAmountChange = (newAmount) => setAmount(newAmount);
    const onHasPaidChange = async (hasPaid) => {
        for (let index = 0; index < events.length; index++) {
            let eventObj = events[index];

            if (!eventObj.entryFees) {
                await update(DataTables.Events(), { _id: eventObj._id }, {
                    $set: { entryFees: defaultClassFeePerEvent }
                });
            }
        }

        setEntryForm({ ...entryForm, hasPaid });
    };

    const onChangeEvents = (events) => {
        const newAmount = Helper.getTotalAmount(events, defaultClassFeePerEvent);
        setAmount(newAmount);
        setEvents(events);
    }

    const onSave = (newEntryForm = entryForm) => {
        if (JSON.stringify(newEntryForm) !== JSON.stringify(savedEntryForm)) {
            update(DataTables.EntryForms(), { _id: newEntryForm._id }, newEntryForm);
            setSavedEntryForm(JSON.parse(JSON.stringify(newEntryForm)));
            setEntryForm(newEntryForm);
        }
    }

    const onSaveEvents = (eventObj, index) => {
        if (!eventObj._id && eventObj.showDate === 0 && eventObj.name === '') {
            return;
        } else if (index > -1 && JSON.stringify(eventObj) === JSON.stringify(events[index])) {
            return;
        }

        const newEventObj = {
            ...eventObj,
            _id: eventIndexIdMap[index],
            entryFormId: entryForm._id
        };

        if (newEventObj._id) {
            update(DataTables.Events(), { _id: newEventObj._id }, newEventObj);
        } else {
            const insertEvent = async () => {
                const response = await insert(DataTables.Events(), newEventObj);
                eventIndexIdMap[index] = response._id;
            }
            insertEvent();
        }
    }

    const onDeleteEvent = (eventId) => {
        remove(DataTables.Events(), { _id: eventId });
        const newEvents = events.filter(({ _id }) => _id !== eventId);
        setEvents(newEvents);
        const newAmount = Helper.getTotalAmount(newEvents, defaultClassFeePerEvent);
        setAmount(newAmount);
    }

    const onAddDefaultEvents = async (newEvents) => {
        for (let index = 0; index < newEvents.length; index++) {
            const newEvent = newEvents[index];
            await insert(DataTables.Events(), { ...newEvent, entryFormId });
        }

        setNewEventCount(newEventCount + 1);
    }

    const onCustomerChange = (fieldProperty, value) => {
        customer[fieldProperty] = value;
        setCustomer(customer);
    }

    return (
        <div>
            <h1 className='no-print'>Entry form for {customer.firstName} {customer.lastName}</h1>

            <IsLoading isLoaded={isLoaded && hasSessionStorage()}>
                <div className='no-print' style={{ opacity: entryForm.hasPaid ? '0.9' : '1' }}>
                    <ClubEntryForm
                        availableDogObjs={availableDogObjs}
                        entryForm={entryForm}
                        events={events}
                        defaultClassFee={defaultClassFeePerEvent}
                        onChangeEntryForm={onChangeEntryForm}
                        onEventsChange={onChangeEvents}
                        onDeleteEvent={onDeleteEvent}
                        superintendents={superintendents}
                        breeds={JSON.parse(sessionStorage.breeds)}
                        classes={classes}
                        obedienceClasses={JSON.parse(sessionStorage.obedienceClasses)}
                        jrClasses={JSON.parse(sessionStorage.jrClasses)}
                        countries={JSON.parse(sessionStorage.countries)}
                        states={JSON.parse(sessionStorage.states)}
                        onBlur={onSave}
                        onBlurEvents={onSaveEvents}
                        onAddDefaultEvents={onAddDefaultEvents}
                        hasPaid={entryForm.hasPaid} />

                    <FormTitleContainer title='Payment Information'>
                        <Pay customer={customer} entryFormId={entryFormId} amount={amount} hasPaid={entryForm.hasPaid} onAmountChange={onAmountChange} onHasPaidChange={onHasPaidChange} onCustomerChange={onCustomerChange} />
                    </FormTitleContainer>
                </div>

                <ClubEntryReport entryForm={entryForm} events={events} defaultClassFee={defaultClassFeePerEvent} handler={customer.handler} />
            </IsLoading>
        </div>
    );
}