import DateHelper from "../lib/common/dateHelper";


const getClasses = ({ showObj }) => {
    const { showClass, obedienceTrialClass, rallyTrialClass } = showObj;
    return [showClass, obedienceTrialClass, rallyTrialClass].filter(classObj => !!classObj).join(', ');
}

const DogRows = ({ events, entryForms }) => {
    return (
        <tbody>
            {entryForms.map((entryForm, index) => {
                const filterEvents = events.filter(({ entryFormId }) => entryFormId === entryForm._id);
                return (
                    <tr key={index}>
                        <td style={{ border: 'solid 1px black' }}>{entryForm.dogObj.dogName}</td>
                        {filterEvents.map(({ _id }) => <td key={_id} style={{ border: 'solid 1px black', textAlign: 'center' }}>{getClasses(entryForm)}</td>)}
                    </tr>
                );
            })}
        </tbody>
    );
}

const EventHeader = ({ events }) => {
    const distinctEvents = events.reduce(((acc, eventObj) => {
        const key = `${eventObj.date}-${eventObj.name.replaceAll(' ', '').toLowerCase()}`
        acc[key] = eventObj;
        return acc;
    }), {});

    return (
        <thead>
            <tr>
                <th />
                {Object.keys(distinctEvents).map((key) => <th style={{ color: 'black', border: 'solid 1px black' }} key={key}>{distinctEvents[key].name}</th>)}
            </tr>
            <tr>
                <th style={{ color: 'black', textAlign: 'left', width: '300px' }}>Dog Name</th>
                {Object.keys(distinctEvents).map((key) => <th style={{ color: 'black', border: 'solid 1px black' }} key={key}>{DateHelper.numberToFormattedDate(distinctEvents[key].date)}</th>)}
            </tr>
        </thead>
    );
}

const LocationContainer = ({ location, events, entryForms }) => {
    return (
        <div>
            <div style={{ textAlign: 'center', fontSize: '16px' }}>{location}</div>
            <table style={{ borderCollapse: 'collapse', marginBottom: '16px' }}>
                <EventHeader events={events} />
                <DogRows events={events} entryForms={entryForms} />
            </table>
        </div>
    );
}

const Locations = ({ locations, events, entryForms }) => {
    return locations.map((location, index) => {
        const eventByLocations = events.filter(eventObj => eventObj.location === location);
        const entryFormByEvents = entryForms.filter(({ _id }) => eventByLocations.findIndex(({ entryFormId }) => entryFormId === _id) > -1);

        return (
            <LocationContainer key={index}
                location={location}
                events={eventByLocations}
                entryForms={entryFormByEvents} />
        );
    });
}

export default function CustomerEntries({entryForms, events, locations, customer}) {

    return (
        <div style={{ backgroundColor: 'white', width: '11in', height: '8in' }}>
            <div style={{ textAlign: 'center', fontSize: '18px', marginBottom: '24px' }}>
                Customer Entries Report for {`${customer.firstName} ${customer.lastName}`} ({DateHelper.numberToFormattedDate(DateHelper.yyyymmddToday())})
            </div>
            <Locations
                locations={locations}
                events={events}
                entryForms={entryForms} />
        </div>
    );
}