import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import IsLoading from '../components/common/isLoading';
import { find, email } from '../api/data';
import { loadEntriesData } from '../api/dataHelper';
import { DataTables } from '../api/dataTables';
import ReadOnlyTable from '../components/common/readOnlyTable/readOnlyTable';
import ReadOnlyFilterWithOptionsHeader from '../components/common/readOnlyTable/readOnlyFilterWithOptionsHeader';
import { filterRows } from '../components/common/readOnlyTable/readOnlyFilterHelper';
import { reactToPdf } from '../api/reactToPdf';
import EntriesReport from '../reports/submission/entriesReport';
import DateHelper from '../lib/common/dateHelper';

const schemas = [{
    sortOrder: 5,
    columnName: 'Submit Date',
    propName: 'submitDate',
    type: 'text',
    render: (value) => `${DateHelper.numberToFormattedDate(value)}`,
    style: { width: '10%' }
}, {
    sortOrder: 10,
    columnName: 'Superintendent',
    propName: 'superName',
    type: 'text',
    style: { width: '20%' }
}, {
    sortOrder: 20,
    columnName: 'Show',
    propName: 'showName',
    type: 'text',
    style: { width: '25%' }
}, {
    sortOrder: 30,
    columnName: 'Number of Entries',
    propName: 'count',
    type: 'number',
    style: { width: '15%' }
}, {
    sortOrder: 40,
    columnName: 'Total',
    propName: 'sum',
    type: 'currency',
    style: { width: '10%' }
}, {
    sortOrder: 50,
    columnName: 'Email',
    propName: 'email',
    type: 'action',
    render: () => 'Email',
    style: { width: '10%' }
}, {
    sortOrder: 60,
    columnName: 'Print',
    propName: 'print',
    type: 'action',
    render: () => 'Print',
    style: { width: '10%' }
}];

const lastDateFields = [
    { label: 'Last 7 Days', value: 'lastseven' },
    { label: 'Last Month', value: 'lastmonth' },
    { label: 'All', value: 'all' }
];

const getLastDateValue = (lastDate) => {
    switch (lastDate) {
        case 'lastseven':
            return DateHelper.addyyyymmddFromToday(-7, 'days');
        case 'lastmonth':
            return DateHelper.addyyyymmddFromToday(-1, 'months');
        default:
            return;
    }
};

const containerStyle = {
    minWidth: '800px',
    maxWidth: '1400px',
    margin: '0 auto 16px auto',
};

const getEventNameKey = (name) => name.toLowerCase().split(' ').join('');

export default function PreviouslySubmitted() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [superEvents, setSuperEvents] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [lastDate, setLastDate] = useState('lastseven');
    const history = useHistory();

    useEffect(() => {
        const load = async () => {
            const superObjs = await find(DataTables.Superintendents(), {}, { name: 1 });
            
            const minDate = getLastDateValue(lastDate);
            let eventsQuery = {isComplete: true};
        
            if (!!minDate) {
                eventsQuery = {
                    ...eventsQuery,
                    submitDate: { $gte: minDate }
                }
            }
        
            const events = await find(DataTables.Events(), eventsQuery);
        
            const superMap = superObjs.reduce(((acc, superObj) => {
                acc[superObj._id] = superObj.name;
                return acc;
            }), {});
        
            const superEventMap = events.reduce(((acc, eventObj) => {
                if (!acc[eventObj.superintendentId]) {
                    acc[eventObj.superintendentId] = { name: superMap[eventObj.superintendentId], eventObj: {} };
                }
        
                if (!acc[eventObj.superintendentId]['eventObj'][getEventNameKey(eventObj.name)]) {
                    acc[eventObj.superintendentId]['eventObj'][getEventNameKey(eventObj.name)] = [];
                }
        
                acc[eventObj.superintendentId]['eventObj'][getEventNameKey(eventObj.name)].push(eventObj);
                return acc;
            }), {});
        
            const superEvents = Object.keys(superEventMap).reduce(((acc, superId) => {
                acc = acc.concat(Object.keys(superEventMap[superId].eventObj).reduce(((accEvent, eventKey) => {
                    const events = superEventMap[superId].eventObj[eventKey];
                    const sum = events.reduce(((accSum, eventObj) => {
                        accSum += eventObj.amount;
                        return accSum;
                    }), 0);
                    accEvent = accEvent.concat([{ _id: `${superId}-${eventKey}`, superName: superEventMap[superId].name, showName: events[0].name, count: events.length, sum, submitDate: events[0].submitDate }]);
                    return accEvent;
                }), []));
                return acc;
            }), []);
        
            superEvents.sort((a, b) => {
                if (a.submitDate !== b.submitDate) {
                    return b.submitDate - a.submitDate
                }
        
                if (a.superName !== b.superName) {
                    return (a.superName > b.superName) - (a.superName < b.superName);
                }
        
                return (a.showName > b.showName) - (a.showName < b.showName);
            });
            setSuperEvents(superEvents);
            setIsLoaded(true);
        }

        load();
    }, [lastDate]);

    useMemo(() => {
        schemas.find(({ columnName }) => columnName === 'Email').action = (row, e) => {
            const { _id, showName } = row;
            e.target.disabled = true;

            const sendEmail = async () => {
                const superintendentId = _id.split('-')[0];
                const { events, entryForms, handlers, telephoneEntry, superintendent } = await loadEntriesData(superintendentId, showName, true);
                const pdfHref = await reactToPdf(<EntriesReport events={events} entryForms={entryForms} handlers={handlers} telephoneEntry={telephoneEntry} superintendent={superintendent} pageStyle={{ margin: '0', height: '7.5in' }} />, `${showName.pdf}`, true);
                const totalAmount = events.reduce(((acc, event) => {
                    acc += event.amount;
                    return acc;
                }), 0);
                await email({
                    from: 'noreply@gigaflops.io',
                    to: superintendent.email,
                    cc: telephoneEntry.email,
                    subject: `Submitting ${entryForms.length} Entries for ${showName}`,
                    text: `Hello ${superintendent.name},\n\nThe link below contains the entries collected for the ${showName}: \n\n${pdfHref}\n\nPlease charge the amount of $${totalAmount.toFixed(2).toLocaleString()} from my account.\n\nThanks!\n${telephoneEntry.companyName}\n${telephoneEntry.phone}\n${telephoneEntry.email}`
                });

                e.target.disabled = false;
            }
            sendEmail();
        };
        schemas.find(({ columnName }) => columnName === 'Print').action = ({ _id, showName }) => {
            const superintendentId = _id.split('-')[0];
            history.push(`/reports/submission/${superintendentId}/${showName}?isComplete=true`);
        };
    }, [history]);

    const filteredRows = filterRows(schemas, superEvents, filterText);

    return (
        <IsLoading isLoaded={isLoaded}>
            <div style={containerStyle}>
                <ReadOnlyFilterWithOptionsHeader
                    title={`Entry Forms ready for submission (${filteredRows.length})`}
                    schemas={schemas}
                    optionTitle='Showing submitted entries from the past:'
                    defaultOption={lastDate}
                    labelValueObjs={lastDateFields}
                    filterTextOnChange={setFilterText}
                    optionOnChange={setLastDate} />
                <ReadOnlyTable
                    rows={filteredRows}
                    schemas={schemas} />
            </div>
        </IsLoading>
    );
}