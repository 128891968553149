import React from 'react';
import PropTypes from 'prop-types';
import FormTable from '../../components/common/FormTable';

const schemas = [{
  sortOrder: 0,
  columnName: 'Country',
  propName: 'country',
  type: 'text',
  isAddOk: true,
  style: { width: '80%' }
}];

export default function Countries({ countries, onAdd, onUpdate, onDelete }) {
  const defaultCountry = {
    country: ''
  };

  const containerStyle = {
    minWidth: '300px',
    maxWidth: '500px',
    margin: 'auto'
  };

  return (
    <FormTable keyPropName='_id'
      tableTitle='Countries'
      defaultValueObj={defaultCountry}
      values={countries}
      schemas={schemas}
      onAdd={onAdd}
      onUpdate={onUpdate}
      onDelete={onDelete}
      containerStyle={containerStyle}
      maxDisplayCount={300} />
  );
}

Countries.propTpes = {
  countries: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}