let UserSession = (function() {

  let getUser = function() {
    let currentUser = localStorage.getItem('currentUser');
    currentUser = !!currentUser ? JSON.parse(currentUser) : null;
    return currentUser;
  };

  let setUser = function(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  };

  return {
    getUser,
    setUser
  }

})();

export default UserSession;